import React, {useCallback, useMemo} from 'react'
import {ArrowUpOutlined, EditFilled, MoreOutlined, StopOutlined} from '@ant-design/icons'
import {Button, Dropdown} from 'antd'
import {useApp} from '@store/app'
import {PanelsKeys} from '@libs/panels/panels.t'
import _ from 'lodash'
import {Task} from '@shared/interfaces'
import {useQueryClient} from '@tanstack/react-query'
import {invalidateGetChecklistQuery, useUpdateTasksStatusMutation} from '@queries/tasks'
import {useTMContext} from '@store/task-management/context'
import {Constants, TaskActions} from './tm.t'

interface ActionMenuProps {
  task: Task
}

const ActionMenu: React.FC<ActionMenuProps> = ({task}) => {
  const {Panels, ability} = useApp()

  const updateTasksStatusMutation = useUpdateTasksStatusMutation()

  const {project} = useTMContext()

  const queryClient = useQueryClient()

  const actionMenu = useMemo(() => {
    const items = []

    if (ability?.can('crud.update', task)) {
      items.push({
        key: 'edit',
        label: 'Modifier',
        icon: <EditFilled />
      })

      items.push({
        key: 'toggle',
        label: task?.status?.disabled?.checked ? 'Activer' : 'Désactiver',
        icon: task?.status?.disabled?.checked ? <ArrowUpOutlined /> : <StopOutlined />
      })
    }

    return items
  }, [ability, task])

  const openEditPanel = useCallback(() => {
    Panels.show(PanelsKeys.FORM_EDIT, {
      formKey: 'projectTaskEdit',
      domain: 'task',
      data: _.omit(task, ['status']),
      successMessage: 'Tâche modifiée',
      onSave: async () => {
        invalidateGetChecklistQuery(queryClient, project?._id)
      }
    })
  }, [task, queryClient, project?._id, Panels])

  const onToggleTaskAction = useCallback(() => {
    updateTasksStatusMutation.mutateAsync(
      {
        tasks: [task._id],
        status: 'disabled',
        value: !task?.status?.disabled?.checked,
        tabId: Constants.TAB_ID
      },
      {
        onSuccess() {
          invalidateGetChecklistQuery(queryClient, project?._id)
        }
      }
    )
  }, [queryClient, project?._id, task, updateTasksStatusMutation])

  const onMenuClick = useCallback(
    (e: {key: string}) => {
      const actionMapper: TaskActions = {
        edit: openEditPanel,
        toggle: onToggleTaskAction
      }

      return actionMapper[e.key as keyof TaskActions]?.()
    },
    [openEditPanel, onToggleTaskAction]
  )

  if (!actionMenu.length) return null

  return (
    <Dropdown menu={{items: actionMenu, onClick: onMenuClick}} placement='bottomLeft' arrow>
      <Button className='tm-taks-cta-button' type='text' shape='circle' icon={<MoreOutlined />} />
    </Dropdown>
  )
}

export default ActionMenu
