import {getHash, selectColor} from '@libs/utils'
import {Task} from '@shared/interfaces'
import moment from 'moment-timezone'
import {
  CheckedState,
  LinkLevel,
  Progress,
  ProgressStats,
  TaskDetailsView,
  TimelineDate
} from './tm.t'
import _ from 'lodash'
import {TFunction} from 'next-i18next'

/**
 * @class TaskManagementHelper
 */
export default class TaskManagementHelper {
  /**
   *
   * @param {Number} index
   * @param {TFunction} t
   * @returns {string}
   */
  public static getMonthName(index: number, t: TFunction) {
    const months = [
      'months.january',
      'months.february',
      'months.march',
      'months.april',
      'months.may',
      'months.june',
      'months.july',
      'months.august',
      'months.september',
      'months.october',
      'months.november',
      'months.december'
    ]

    return t(months[index])
  }

  /**
   *
   * @param {Number} index
   * @param {TFunction} t
   * @returns {string}
   */
  public static getDayName(index: number, t: TFunction) {
    const days = [
      'days.monday',
      'days.tuesday',
      'days.wednesday',
      'days.thursday',
      'days.friday',
      'days.saturday',
      'days.sunday'
    ]

    return t(days[index - 1])
  }

  /**
   * Formats task payload for details view
   *
   * @static
   *
   * @param {Task} task
   * @param {TFunction} t
   * @returns {TaskDetailsView}
   */
  public static normalizeTask(task: Task, t: TFunction): TaskDetailsView {
    const {name: taskName, comment, owners, deadline, status, __type} = task
    return {
      name: taskName,
      comment,
      status,
      deadline:
        deadline &&
        `${moment(deadline).date()} ${TaskManagementHelper.getMonthName(
          moment(deadline).month(),
          t
        )}`,
      owners: (owners || [])?.map(({team, profile}) => ({
        team: team
          ? {
              name: profile
                ? `${profile.firstname.charAt(0).toUpperCase()}${profile.lastname
                    .charAt(0)
                    .toUpperCase()}`
                : `${team?.name.charAt(0).toUpperCase()}`,
              tooltip: profile ? `${profile.name}` : team?.name,
              color: team.color ?? selectColor(getHash(team.name) as number)
            }
          : undefined,
        profile: profile
          ? `${profile.firstname} ${profile.lastname.charAt(0).toUpperCase()}.`
          : undefined
      })),
      __type
    }
  }

  /**
   * Resolve on toggle subTasks checked state
   *
   * @static
   *
   * @param {Array<Task>} tasks
   * @param {boolean} linkChecked
   * @returns {CheckedState}
   */
  public static toggleSubTasks(tasks: Task[], linkChecked: boolean): CheckedState {
    return {
      link: linkChecked,
      ...tasks.reduce<{[key: string]: boolean}>(
        (acc, current) => ({
          ...acc,
          [current._id]: linkChecked
        }),
        {}
      )
    }
  }

  /**
   * On toggle task checkbox success
   *
   * @static
   *
   * @param {CheckedState} checked
   * @param {taskId} string
   * @param {boolean} value
   * @returns {CheckedState}
   */
  public static onToggleTaskCheckboxSuccess(
    checked: CheckedState,
    taskId: string,
    value: boolean
  ): CheckedState {
    return {
      ...checked,
      link: !_.chain({
        ...checked,
        [taskId]: value
      })
        .omit('link')
        .values()
        .some((it) => it === false)
        .value(),
      [taskId]: value
    }
  }

  /**
   * Compute progress bar for a subCategory
   * returns an object with 2 keys: done and total
   *
   * done: number of tasks done in the subCategory
   * total: total of tasks in the subCategory
   *
   * @static
   *
   * @param {ProgressStats} progressStats
   * @returns {Progress}
   */
  public static computeProgress(progressStats: ProgressStats) {
    return Object.values(progressStats).reduce<Progress>(
      (acc, current) => ({
        done: acc.done + current.done,
        total: acc.total + current.total
      }),
      {done: 0, total: 0}
    )
  }

  /**
   * Compute initial progress stats for a subCategory
   *
   * @static
   *
   * @param {LinkLevel[]} subSections
   * @returns {Map<string, Progress>}
   */
  public static getInitialProgressStats(subSections: LinkLevel[]): ProgressStats {
    return (subSections || []).reduce<ProgressStats>(
      (acc: ProgressStats, subSection: LinkLevel) => {
        const subSectionProgress: Progress = subSection.tasks.reduce<Progress>(
          (acc: Progress, task: Task) => {
            return {
              done: task.status.done.checked ? acc.done + 1 : acc.done,
              total: acc.total + 1
            }
          },
          {done: 0, total: 0}
        )
        return {...acc, [subSection._id]: subSectionProgress}
      },
      {}
    )
  }

  /**
   * Generates a unique component id
   *
   * @static
   *
   * @param {string} id
   * @param {string} section
   * @returns {string}
   */
  public static getKey(id: string, section?: string, addTime = false): string {
    return `${id}-${section}-${addTime ? moment().format('HH:ss') : ''}`
  }

  /**
   * Returns initial checked state
   *
   * @static
   *
   * @param {Array<Task>} tasks
   * @returns {CheckedState}
   */
  public static resetCheckedState(tasks: Task[]): CheckedState {
    return {
      link: !tasks.some(({status}) => !status.done.checked),
      ...tasks.reduce<{[key: string]: boolean}>(
        (acc, current) => ({
          ...acc,
          [current._id]: current.status.done.checked
        }),
        {}
      )
    }
  }

  /**
   * Returns Formatted date 'dayOfWeek day Month'
   *
   * @param {TimelineDate} date
   * @param {TFunction} t
   * @returns {string}
   */
  public static getFormattedTimelineDate(date: TimelineDate, t: TFunction) {
    if (date.span === 'nd')
      return t('components.forms.fields.widgets.tm.timeline.notDefined', {field: 'Date'})
    return `${TaskManagementHelper.getDayName(date.dayOfWeek, t)} ${
      date.date
    } ${TaskManagementHelper.getMonthName(date.month, t)}`
  }
}
