import {MarkerType, DefaultEdgeOptions} from 'react-flow-renderer/nocss'
import {NodeType} from '../nodes/node-config'

export const initialState = {
  nodes: [
    {
      id: '1',
      type: NodeType.start,
      data: {},
      position: {x: 0, y: 0}
    },
    {
      id: '2',
      type: NodeType.drop,
      data: {},
      position: {x: 0, y: 0}
    }
  ],
  edges: [
    {
      id: '1->2',
      source: '1',
      target: '2'
    }
  ]
}

export const defaultEdgeOptions: DefaultEdgeOptions = {
  type: 'default',
  labelBgPadding: [8, 4],
  labelBgBorderRadius: 4,
  labelBgStyle: {fill: '#FFCC00', fillOpacity: 0.7},
  markerEnd: {
    type: MarkerType.ArrowClosed
  }
}
