import React, {useMemo, useState} from 'react'

import Editor from '@monaco-editor/react'
import {editor as MonacoEditor, languages as MonacoLanguages} from 'monaco-editor'
import {useDebouncedCallback} from 'use-debounce'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCompressWide,
  faExclamationTriangle,
  faExpandWide,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons'

interface CodeEditorProps {
  name: string
  language?: string
  parsedValue: string
  disabled?: boolean
  onChange?: (value: string) => void
  maxHeight?: number
}

// Errors list https://github.com/microsoft/TypeScript/blob/main/src/compiler/diagnosticMessages.json
const warningCodes = ['6133', '2570', '2568', '7044', '7043', '7047', '2339', '18004']

const CodeEditor: React.FC<CodeEditorProps> = ({
  name,
  language,
  onChange,
  parsedValue,
  disabled = false,
  maxHeight = 500
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const [errors, setErrors] = useState<MonacoEditor.IMarker[]>([])
  const [warnings, setWarnings] = useState<MonacoEditor.IMarker[]>([])

  const editorLanguage = useMemo(() => {
    if (!language || language === 'js' || language === 'javascript') return 'typescript'
    return language
  }, [language])

  const editorHeight = useMemo(() => {
    const lines = Math.max((parsedValue || '').split('\n').length, 1)
    return `${Math.min(lines * 18, maxHeight) + 6}px`
  }, [parsedValue, maxHeight])

  const toggleEditorFullscreen = () => {
    setIsFullscreen(!isFullscreen)
  }

  const debouncedOnChange = useDebouncedCallback((v) => {
    onChange?.(v)
  }, 400)

  const [options] = useState<MonacoEditor.IEditorOptions & MonacoLanguages.FormattingOptions>({
    readOnly: disabled || false,

    lineNumbers: 'on',
    tabSize: 2,
    insertSpaces: true,
    scrollBeyondLastLine: false,

    minimap: {
      enabled: false
    },

    scrollbar: {
      horizontal: 'auto',
      vertical: 'auto',
      horizontalScrollbarSize: 5,
      verticalScrollbarSize: 5
    },

    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: 'on',
    quickSuggestionsDelay: 250,

    renderValidationDecorations: 'on',

    // improve performance
    accessibilitySupport: 'off',
    codeLens: false,

    // Improve responsiveness
    automaticLayout: true
  })

  return (
    <div className={`code-editor-container ${isFullscreen ? 'is-fullscreen' : ''}`}>
      <Editor
        height={isFullscreen ? 'calc(100vh - 32px)' : editorHeight}
        path={name}
        language={editorLanguage}
        value={parsedValue}
        options={options}
        onChange={(v) => {
          debouncedOnChange(v || '')
        }}
        onValidate={(v) => {
          setErrors([])
          setWarnings([])

          if (v && v.length) {
            const errors = v.filter((error) => !warningCodes.includes(error.code as string))
            const warnings = v.filter((warning) => warningCodes.includes(warning.code as string))

            if (errors.length) {
              setErrors(errors)
            }
            if (warnings.length) {
              setWarnings(warnings)
            }
          }
        }}
      />
      <div className='status-bar'>
        <div className='status-bar-group'>
          <span className={`status-bar-item code-status ${errors.length ? 'error' : ''}`}>
            <FontAwesomeIcon icon={faTimesCircle} />
            <span>{errors.length}</span>
          </span>

          <span className={`status-bar-item code-status ${warnings.length ? 'warning' : ''}`}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{warnings.length}</span>
          </span>
        </div>

        <div className='status-bar-group'>
          <span className='status-bar-item fullscreen-btn'>
            {isFullscreen && (
              <FontAwesomeIcon icon={faCompressWide} onClick={toggleEditorFullscreen} />
            )}
            {!isFullscreen && (
              <FontAwesomeIcon icon={faExpandWide} onClick={toggleEditorFullscreen} />
            )}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CodeEditor
