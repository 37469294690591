import {Collapse} from 'antd'
import React, {useMemo, useState} from 'react'
import {Progress, ProgressStats, SectorAndSupplierLevel} from './tm.t'
import TaskManagementSubSectionItem from './tm-subsection'
import CollapsePanelHeader from './tm-category-header'
import TaskManagementHelper from './tm-helper'
import {useTasksListContext} from './tasks-list'
import {CaretDownFilled, CaretRightFilled} from '@ant-design/icons'

interface TaskManagementCategoryItemProps extends Omit<SectorAndSupplierLevel, '_id' | '__type'> {
  categoryId: string
}

const TaskManagementCategoryItem: React.FC<TaskManagementCategoryItemProps> = ({
  categoryId,
  name,
  subSections
}) => {
  const {old, section, collapseActiveKeys, setCollapseActiveKeys} = useTasksListContext()

  const [progressStats, setProgressStats] = useState<ProgressStats>(
    TaskManagementHelper.getInitialProgressStats(subSections)
  )

  const progress: Progress = useMemo<Progress>(
    () => (!old ? TaskManagementHelper.computeProgress(progressStats) : {done: 0, total: 0}),
    [progressStats, old]
  )

  const subCategoryItems = useMemo(() => {
    return subSections.map((subSection) => {
      const uid: string = TaskManagementHelper.getKey(subSection._id, section, false)
      return (
        <TaskManagementSubSectionItem
          key={TaskManagementHelper.getKey(subSection._id, section)}
          setProgressStats={setProgressStats}
          uid={uid}
          linkId={subSection._id}
          {...subSection}
        />
      )
    })
  }, [subSections, setProgressStats, section])

  return (
    <Collapse
      activeKey={collapseActiveKeys}
      onChange={(key) => {
        setCollapseActiveKeys?.(key)
      }}
      expandIcon={({isActive}) => (isActive ? <CaretDownFilled /> : <CaretRightFilled />)}>
      <Collapse.Panel
        header={<CollapsePanelHeader title={name} progress={progress} old={Boolean(old)} />}
        key={categoryId}
        className='tm-collapse-panel'>
        {subCategoryItems}
      </Collapse.Panel>
    </Collapse>
  )
}

export default TaskManagementCategoryItem
