import {stringToColor} from '@libs/utils'

export interface LookupTableCellStatusProps {
  color?: string
  /** (optional) if `color` not filled, will deterministically generate a color from the value */
  stringValue?: string
}

const LookupTableCellStatus = (props: LookupTableCellStatusProps) => {
  const {stringValue, color} = props

  return (
    <span
      className='dot'
      style={{
        backgroundColor: color ?? stringToColor(stringValue ?? `${Math.random() * 10000}`)
      }}></span>
  )
}

export default LookupTableCellStatus
