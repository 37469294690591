import {
  ExtractSubjectType,
  MongoQuery,
  Subject,
  SubjectRawRule,
  createMongoAbility
} from '@casl/ability'
import {Permission} from '@shared/interfaces'

const getAbility = (permissions: Permission[]) =>
  createMongoAbility(
    permissions as SubjectRawRule<string, ExtractSubjectType<Subject>, MongoQuery>[],
    {
      detectSubjectType: ({__type}) => __type
    }
  )

export default getAbility
