import React, {useMemo} from 'react'
import {Button, Col, Row} from 'antd'
import _ from 'lodash'
import {
  ArrowLeftOutlined,
  CheckOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined
} from '@ant-design/icons'
import {CatalogArticle} from '@store/catalog/context'
import {Cart, Product} from '@shared/interfaces'
import {url} from '@libs/cloudinary'
import ImageGallery from '@components/misc/image-gallery'
import {useApp} from '@store/app'

interface ArticleProps {
  article: CatalogArticle
  previous?: CatalogArticle
  next?: CatalogArticle
  products?: {[key: string]: Product[]}
  setArticle?: React.Dispatch<CatalogArticle | undefined>
  cart?: Cart
  onArticleAdd: (article: CatalogArticle) => void
  belongsToCart?: boolean
  hasProject?: boolean
  isError?: boolean
  loading: boolean
}

const Article: React.FC<ArticleProps> = ({
  article,
  products,
  setArticle,
  previous,
  next,
  onArticleAdd,
  loading,
  belongsToCart = false,
  hasProject = false
}) => {
  const product = _.chain<{[key: string]: Product[]}>(products)
    .get(
      typeof article?.saleArticle?.product === 'string'
        ? article?.saleArticle?.product
        : article?.saleArticle?.product?._id,
      []
    )
    .head()
    .value()

  const {t} = useApp()

  const {pics} = !_.isEmpty(article?.saleArticle?.pics) ? article.saleArticle : product
  const images = (pics || []).map((pic) => ({
    ...pic,
    url: url(pic.hash, {
      filter: 'c_fit,h_1000,q_auto:best,w_1000,fl_lossy,f_auto'
    })
  }))

  const actionConfig = useMemo<{
    className: string
    disabled: boolean
    label: string
    action: (article: CatalogArticle) => void
    icon: JSX.Element
  }>(() => {
    if (belongsToCart) {
      return {
        className: 'gotham-font btn-remove-article cursor-auto',
        disabled: true,
        action: _.identity,
        label: t('catalog.article.articleAdded'),
        icon: <CheckOutlined />
      }
    }
    return {
      className: 'gotham-font catalog-article-font btn-add-article',
      action: onArticleAdd,
      disabled: false,
      label: t('catalog.article.addArticle', {article}),
      icon: <PlusOutlined />
    }
  }, [belongsToCart, article, onArticleAdd, t])

  return (
    <div className='article-details'>
      <Row>
        <Col span={6}>
          <span
            className='cursor-pointer'
            onClick={() => {
              setArticle?.(undefined)
            }}>
            <p className='gotham-font catalog-article-font back-to-catalog-title'>
              <ArrowLeftOutlined
                className='back-to-catalog-navigation'
                style={{color: '#5A6069'}}
              />
              {t('catalog.article.backTo', {product})}
            </p>
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <h1 className='article-name'>{article.name}</h1>
        </Col>
        <div className='article-naviguation'>
          <Col span={12} className='article-navigation-div'>
            {previous && (
              <span
                onClick={() => {
                  setArticle?.(previous)
                }}>
                <p className='gotham-font article-navigation-text cursor-pointer'>
                  <LeftOutlined color='primary' className='article-navigation-previous' />
                  {t('catalog.article.previous')}
                </p>
              </span>
            )}
          </Col>
          <Col span={12}>
            {next && (
              <span
                onClick={() => {
                  setArticle?.(next)
                }}>
                <p className='gotham-font article-navigation-text cursor-pointer'>
                  {t('catalog.article.next')}
                  <RightOutlined color='primary' className='article-navigation-next' />
                </p>
              </span>
            )}
          </Col>
        </div>
      </Row>
      <div className='article-image-gallery'>
        <ImageGallery images={images} />
      </div>
      <p className='gotham-font catalog-article-font article-description'>{article.description}</p>
      {hasProject && (
        <Button
          {...actionConfig}
          loading={loading}
          onClick={() => {
            actionConfig.action(article)
          }}>
          {actionConfig.label}
        </Button>
      )}
      {product?.requiredPriceInformation && (
        <div className='gotham-font catalog-article-font article-price-information'>
          {article?.saleArticle?.priceInformation}
        </div>
      )}
    </div>
  )
}

export default Article
