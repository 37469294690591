import React from 'react'
import {ReportSummary} from './import-report.t'
import {Card, Space, Table, Tag} from 'antd'
import {useApp} from '@store/app'
import {ErpDomain} from '@shared/interfaces'

interface SummaryProps {
  summary: ReportSummary
  status: 'success' | 'warning' | 'error' | 'processing'
  duration: number
  memory: number
  type: 'json' | 'csv'
  domain: ErpDomain
}

interface DataType {
  key: number
  status: 'success' | 'warning' | 'error' | 'processing'
  memory: number
  duration: number
  created: number
  updated: number
  total: number
  type: 'json' | 'csv'
}

const Summary: React.FC<SummaryProps> = ({summary, status, memory, duration, type, domain}) => {
  const {t} = useApp()

  const columns = [
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text: string, {status}: DataType) => {
        const color = {
          success: 'green',
          warning: 'yellow',
          error: 'volcano',
          processing: 'geekblue'
        }

        return (
          <Tag color={color[status]} className='bold p-1'>
            {t(`components.forms.fields.widgets.importReport.status.${status}`)}
          </Tag>
        )
      }
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text: string, {type}: DataType) => {
        const color = {
          csv: 'yellow',
          json: 'geekblue'
        }
        return (
          <Tag color={color[type]} className='bold p-1'>
            {type}
          </Tag>
        )
      }
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.created', {domain}),
      dataIndex: 'created',
      key: 'created'
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.updated', {domain}),
      dataIndex: 'updated',
      key: 'updated'
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.parsed', {domain}),
      dataIndex: 'parsed',
      key: 'parsed'
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.notFound', {domain}),
      dataIndex: 'notFound',
      key: 'notFound'
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.total'),
      dataIndex: 'total',
      key: 'total'
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.duration'),
      dataIndex: 'duration',
      key: 'duration'
    },
    {
      title: t('components.forms.fields.widgets.importReport.summary.table.memory'),
      dataIndex: 'memory',
      key: 'memory'
    }
  ]

  const data: DataType[] = [
    {
      key: 1,
      status,
      memory: Math.round(memory),
      duration,
      type,
      ...summary
    }
  ]
  return (
    <Card
      title={t('components.forms.fields.widgets.importReport.summary.title')}
      headStyle={{background: '#5e5e5e', color: '#FFFF'}}>
      <Space direction='vertical' className='w-100'>
        <Table pagination={false} columns={columns} dataSource={data} />
      </Space>
    </Card>
  )
}

export default Summary
