import {Col} from 'antd'
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {FieldDomainWithUniqId} from './layout-input.t'
import LayoutField from './layout-field'

interface LayoutFieldsDrawerProps {
  fields: FieldDomainWithUniqId[]
}

export default function LayoutFieldsDrawer({fields}: LayoutFieldsDrawerProps) {
  //Dnd Ids haven't been generated yet
  if (fields[0] && !fields[0].dndId) return <></>

  return (
    <Col className='layout-fields-drawer'>
      <SortableContext
        items={(fields || []).map((c) => c.dndId)}
        strategy={verticalListSortingStrategy}
        id={'drawer'}>
        {(fields || []).map((field) => (
          <LayoutField
            showDelete={false}
            type='field'
            key={field.dndId}
            label={field.label || 'Label'}
            id={field.dndId}
            data={{label: field.label}}
          />
        ))}
      </SortableContext>
    </Col>
  )
}
