import {FormObject} from '@components/forms/form.t'
import React, {useMemo, useState} from 'react'
import {UseRowSelectionResult} from '../table.t'
import {RowSelectionType} from 'antd/lib/table/interface'
import {TableProps} from 'antd'

const useRowSelection = (): UseRowSelectionResult => {
  const [selectedItems, setSelectedItems] = useState<FormObject[]>([])

  const rowSelection = useMemo<TableProps<FormObject>['rowSelection']>(() => {
    const type: RowSelectionType = 'checkbox'
    return {
      onChange: (selectedRowKeys: React.Key[], selectedRows: FormObject[]) => {
        setSelectedItems(selectedRows)
      },
      getCheckboxProps: (record: FormObject) => ({
        disabled: false, // Column configuration not to be checked
        name: record._id
      }),
      type
    }
  }, [])

  return {rowSelection, selectedItems, setSelectedItems}
}

export default useRowSelection
