import {PlusCircleOutlined} from '@ant-design/icons'
import Exec from '@libs/exec'
import {Action, ErpDomain} from '@shared/interfaces'
import {AppContext} from '@store/app'
import {Button, Row, Space, Statistic, Typography} from 'antd'
import React, {useContext, useMemo} from 'react'
import {CartDomain} from './cart.t'
import {useApp} from '@store/app'

const {Text} = Typography

interface CartHeaderProps {
  cart: CartDomain
}

const CartHeader: React.FC<CartHeaderProps> = ({cart}: CartHeaderProps) => {
  const prices = cart.prices.tvas.summary

  const app = useContext(AppContext)

  const {t} = useApp()

  const triggerAction = async (action: Action) => {
    try {
      await Exec(action.code, {app: app, action})
    } catch (e) {
      console.log(e)
    }
  }

  const actions = useMemo(() => {
    const items = []

    const cartDomain = app.CRM?.getDomain('cart') as ErpDomain
    const productFinderAction = cartDomain.actions.find((a) => a.key === 'productFinder')
    const addArticleAction = cartDomain.actions.find((a) => a.key === 'articleFinder')

    if (
      productFinderAction &&
      (app.ability?.can(`action.${productFinderAction._id}`, 'cart') ||
        app.ability?.can('action.all', 'cart'))
    ) {
      items.push(
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            triggerAction(productFinderAction)
          }}>
          {t('cart.header.addProduct')}
        </Button>
      )
    }

    // if (
    //   addArticleAction &&
    //   (app.ability?.can(`action.${addArticleAction._id}`, 'cart') ||
    //     app.ability?.can('action.all', 'cart'))
    // ) {
    //   items.push(
    //     <Button
    //       icon={<PlusCircleOutlined />}
    //       onClick={() => {
    //         triggerAction(addArticleAction)
    //       }}>
    //       {t('cart.header.addArticle')}
    //     </Button>
    //   )
    // }

    return items
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.CRM, app.ability])

  return (
    <Row className='w-100' justify='space-between'>
      {!cart.locked && !cart.isAbandoned && !!actions.length && <Space>{...actions}</Space>}
      {!!prices && (
        <Statistic
          className='ms-auto'
          prefix={<Text type='secondary'>Total TTC</Text>}
          value={prices.ttc}
          valueStyle={{color: '#935A55'}}
        />
      )}
    </Row>
  )
}

export default CartHeader
