import Exec from '@libs/exec'
import {FormDomain} from '@shared/interfaces'
import {FormikErrors, useFormikContext} from 'formik'
import React, {Dispatch, memo, useContext, useEffect, useRef, useState} from 'react'
import {FieldWrapper} from '../fields/fields.t'
import {FormObject, ValidationAction, ValidationActionKind} from '../form.t'
import {AppContext} from '@store/app'
import {FormObjectValue} from '../form.t'
import * as Utils from '@libs/utils'
import {useFieldHidden} from '@hooks/use-field-hidden'

interface FormFieldHideHandlerProps {
  children: React.ReactNode
  form: FormDomain
  field: FieldWrapper
  dispatchValidation: Dispatch<ValidationAction>
}

interface FormFieldChangeHandlerProps {
  field: FieldWrapper
}

interface FieldChangeReturn {
  value: FormObjectValue
  values: FormObject
  errors: FormikErrors<FormObject>
  prevValue: FormObjectValue
  initialValue: FormObjectValue
}

const useChangeHandler = ({
  onChange,
  fieldPath,
  prefix
}: {
  onChange: (changes: FieldChangeReturn) => void
  fieldPath: string
  prefix: string | undefined
}) => {
  const [prevValue, setPrevValue] = useState<FormObjectValue>()
  const [prevInitialValue, setPrevInitialValue] = useState<FormObjectValue>()
  const {values, errors, initialValues} = useFormikContext<FormObject>()

  const id = Utils.atPath(values, `${prefix}._id`)
  const ref = useRef(id)

  const value = Utils.atPath(values, fieldPath)
  const initialValue = Utils.atPath(initialValues, fieldPath)

  useEffect(() => {
    if (ref.current !== id) {
      ref.current = id
      setPrevValue(undefined)
    } else {
      if (initialValue != prevInitialValue) {
        onChange({value, values, errors, prevValue, initialValue})
        setPrevInitialValue(initialValue)
      } else if (value != prevValue) {
        onChange({value, values, errors, prevValue, initialValue})
        setPrevValue(value)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, initialValue])

  return null
}

const WithOnFieldChangeHandler = memo(useChangeHandler)

export const FormikChangeHandler: React.FC<FormFieldChangeHandlerProps> = ({field}) => {
  const app = useContext(AppContext)
  const formProps = useFormikContext<FormObject>()

  const getValue = (value: string) => {
    return Utils.atPath(
      formProps.values,
      `${
        Utils.relativePath(field.domain.name) ? `${Utils.relativePath(field.domain.name)}.` : ''
      }${value}`
    )
  }

  if (!field.domain.onChange) return null

  return (
    <WithOnFieldChangeHandler
      fieldPath={field.domain.name}
      prefix={field.domain.prefix}
      onChange={async ({value, prevValue, initialValue}) => {
        if (!field.domain.onChange) return
        try {
          const context = window.SM?.sub

          await Exec(field.domain.onChange, {
            app,
            key: field.domain.key,
            context,
            name: field.domain.name,
            value,
            prevValue,
            field: {...field, name: field.domain.name},
            form: formProps,
            // Iso v1
            prefix: `${field.domain.prefix}.`,
            initialValue,
            helper: {getValue}
          })
        } catch (e) {
          console.warn('On Change Handler Function error', field, e)
        }
      }}
    />
  )
}

const FormFieldHideHandler: React.FC<FormFieldHideHandlerProps> = ({
  form,
  field,
  children,
  dispatchValidation
}) => {
  const isHidden = useFieldHidden(field, form)

  useEffect(() => {
    if (isHidden) {
      dispatchValidation({
        kind: ValidationActionKind.REMOVE_FIELD,
        field: field
      })
    } else {
      dispatchValidation({
        kind: ValidationActionKind.ADD_FIELD,
        field: field
      })
    }

    return () => {
      dispatchValidation({
        kind: ValidationActionKind.REMOVE_FIELD,
        field: field
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden])

  if (isHidden) return null

  return (
    <React.Fragment>
      {children}
      <FormikChangeHandler field={field} />
    </React.Fragment>
  )
}

export default FormFieldHideHandler
