import React from 'react'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import {useField} from 'formik'
import * as Yup from 'yup'
import {atPath} from '@libs/utils'
import {Switch, Checkbox} from 'antd'
import _ from 'lodash'
import FormItem from '../form-item/form-item'

interface SwitchCheckboxInputComponentProps {
  key: string
  label: string
  name: string
  type?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  checkboxType?: 'toggle' | 'default'
}

const SwitchCheckboxInputComponent: React.FC<
  FieldComponentProps<SwitchCheckboxInputComponentProps>
> = ({field}) => {
  const [formikField] = useField<boolean>(field)

  // adapt the expected format to be compatible with Formik (lib compatibility)
  const handleOnChange = (checked: boolean) => {
    formikField.onChange({
      target: {
        name: formikField.name,
        value: checked
      }
    })
  }

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      {field?.type === 'bool' && field?.checkboxType === 'default' ? (
        <Checkbox
          {...formikField}
          onChange={(e) => handleOnChange(e.target.checked)}
          disabled={field.disabled}
          checked={formikField.value}
        />
      ) : (
        <Switch
          {...formikField}
          checked={formikField.value}
          onChange={handleOnChange}
          disabled={field.disabled}
        />
      )}
    </FormItem>
  )
}

const SwitchCheckboxInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      if (
        field.defaultValue &&
        ['true', 'false'].includes(field.defaultValue.toLocaleLowerCase())
      ) {
        return JSON.parse(field.defaultValue)
      }

      return Boolean(field.defaultValue)
    },
    validationSchema() {
      let schema = Yup.boolean()

      if (field.required) schema = schema.required()

      return {[field.key]: schema.nullable()}
    },
    generateComponent() {
      return (
        <SwitchCheckboxInputComponent
          field={{
            ..._.omit(field, 'hidden', 'ref')
          }}
        />
      )
    }
  }
}

export default SwitchCheckboxInput
