import React from 'react'
import _ from 'lodash'
import {Card} from 'antd'
import {FieldDomain} from '@shared/interfaces'
import TMProvider from '@store/task-management/provider'
import {useTMContext} from '@store/task-management/context'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import TasksList from './tasks-list'
import OldTasks from './old-tasks'
import Timeline from './timeline'
import Loading from './loading'

const TaskManagementComponent: React.FC<
  FieldComponentProps<Omit<FieldDomain, 'hidden' | 'ref'>>
> = () => {
  const {getChecklistQuery, getTimelineQuery} = useTMContext()

  if (getChecklistQuery?.isError) return null

  const checklist = getChecklistQuery?.data
  const timeline = getTimelineQuery?.data

  return (
    <div style={{display: 'flex'}} className='w-100'>
      <div style={{flex: 3}} className='mx-1'>
        <Loading query={getChecklistQuery} />
        {checklist && (
          <Card className='tm-tasks-card'>
            <TasksList list={checklist?.active} section='active' />
            <OldTasks {...checklist} />
          </Card>
        )}
      </div>
      {!getTimelineQuery?.isError && (
        <div style={{flex: 1}} className='mx-1'>
          <Loading query={getTimelineQuery} />
          {timeline && <Timeline data={timeline} />}
        </div>
      )}
    </div>
  )
}

const TaskManagementWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return (
        <TMProvider>
          <TaskManagementComponent field={_.omit(field, 'hidden', 'ref')} />
        </TMProvider>
      )
    }
  }
}

export default TaskManagementWidget
