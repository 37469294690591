import {BaseEntity, Cart} from '@shared/interfaces'
import _ from 'lodash'

export interface SelectOption {
  /** Select label (to be shown on the select view) */
  label?: string
  /** Select uniq key (id) */
  key?: string
  /** Select value - to be set as a field value */
  value?: string
}

/**
 * Format data to select options
 * @param {BaseEntity[]} data
 * @returns {SelectOption[]}
 */
export const generateOptions = <TData extends BaseEntity>(
  data: TData[],
  labelKey: keyof TData = 'name'
): SelectOption[] =>
  data.map((it) => ({
    key: it._id,
    value: it._id,
    label: it[labelKey]?.toString()
  }))

export const getDefaultOptions = (cart?: Cart, productId?: string): {[key: string]: string} => {
  return _.chain(cart)
    .get('cartItems', [])
    .filter(({searchContext}) => searchContext?.product === productId)
    .map('searchContext.options')
    .flatten()
    .reduce((acc, {option, value}) => {
      return {
        ...acc,
        [option]: value
      }
    }, {})
    .omitBy(_.isNil)
    .value()
}
