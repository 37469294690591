type ValueType = number | string

/**
 * @param {number | string | undefined} value
 * @returns {string} - formatted value with space for thousands (only for the integer part) and comma instead of dot
 * @description Use this function for the antd InputNumber formatter
 */
const formatNumber = (value: ValueType | undefined): string => {
  const numValue = typeof value === 'number' ? value : parseFloat(value || '')
  if (isNaN(numValue)) return ''

  const [integerPart, decimalPart] = `${numValue}`.replace('.', ',').split(',')
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return decimalPart ? `${formattedIntegerPart},${decimalPart}` : formattedIntegerPart
}

export default formatNumber
