import React, {useCallback} from 'react'
import {Card, Space, Timeline as AntdTimeline} from 'antd'
import {TimelineData, TimelineService} from './tm.t'
import {CalendarOutlined} from '@ant-design/icons'
import TaskManagementHelper from './tm-helper'
import {useApp} from '@store/app'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface TimelineProps {
  data: TimelineData
}

const Timeline: React.FC<TimelineProps> = ({data}) => {
  const {t} = useApp()
  const getDateTimelineItems = useCallback(
    (index: number, services?: TimelineService[]) => [
      ...(services
        ? services.map((service) => ({
            children: (
              <>
                <p className='bold my-0'>{service.name}</p>
                {service?.date?.hour && (
                  <p
                    className='my-0'
                    style={{...(service?.date?.hour === 'nd' && {color: 'rgb(255, 77, 79)'})}}>
                    <CalendarOutlined />{' '}
                    {service?.date?.hour !== 'nd'
                      ? service.date.hour.replace(':', 'h')
                      : t('components.forms.fields.widgets.tm.timeline.notDefined', {
                          field: 'Horaire'
                        })}
                  </p>
                )}
                <p
                  className='my-1'
                  style={{
                    ...((!service.place || service.dummyPlace) && {color: 'rgb(255, 77, 79)'})
                  }}>
                  <FontAwesomeIcon icon='location-dot' />{' '}
                  {service.dummyPlace
                    ? t('components.forms.fields.widgets.tm.timeline.dummy', {
                        field: 'Lieu'
                      })
                    : service.place ||
                      t('components.forms.fields.widgets.tm.timeline.notDefined', {
                        field: 'Lieu'
                      })}
                </p>
              </>
            )
          }))
        : []),
      ...(index < data.length - 1
        ? [
            {
              className: 'tm-empty-timeline-item',
              dot: <></>
            }
          ]
        : [])
    ],
    [t, data.length]
  )
  return (
    <Card className='tm-timeline-card'>
      <Space direction='vertical' className='w-100'>
        {data.map((item, index) => (
          <React.Fragment key={item?.span}>
            <label style={{...(item?.span === 'nd' && {color: 'rgb(255, 77, 79)'})}}>
              {TaskManagementHelper.getFormattedTimelineDate(item.date, t)}
            </label>
            <AntdTimeline mode='left' items={getDateTimelineItems(index, item?.services)} />
          </React.Fragment>
        ))}
      </Space>
    </Card>
  )
}

export default Timeline
