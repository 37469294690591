import {ThemeConfig} from 'antd'

const defaulttheme: ThemeConfig = {
  token: {
    colorPrimary: '#6c6f4d',
    colorPrimaryBg: '#EDEFFA',
    colorPrimaryBgHover: '#EDEFFA',
    colorTextBase: '#606266',
    colorPrimaryTextActive: '#6c6f4d',
    colorPrimaryActive: '#6c6f4d',
    colorPrimaryTextHover: '#8F9278',
    colorPrimaryHover: '#8F9278',
    colorPrimaryBorder: '#B7B7AB',
    colorPrimaryBorderHover: '#B7B7AB',
    colorWarning: '#d7ac38',
    colorWhite: '#fff',
    colorError: '#ff4d4f',
    colorInfo: '#6c6f4d',
    colorTextDisabled: '#959595',
    colorBgContainerDisabled: '#EFEFEF',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
  },
  components: {
    Tabs: {
      colorPrimary: '#935a55',
      colorPrimaryActive: '#935a55',
      colorPrimaryHover: '#935a55'
    },
    Button: {
      colorBorder: 'transparent'
    },
    Badge: {
      colorError: '#606266'
    }
  }
}

//Primary Green: #6c6f4d
//Primary Red: #935a55

export default defaulttheme
