import {FormObject} from '@components/forms/form.t'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useErpDomainsByKeyQuery, usePatchMutation, useUpdateMutation} from '@queries'
import {DomainSaveMethod, ErpDomain} from '@shared/interfaces'
import {AppContext, withApp} from '@store/app'
import React, {useCallback, useMemo} from 'react'
import FormPanel from './form'
import Loader from '@components/misc/loader'
import {cleanObjectFromSchema, saveDiff} from '@libs/utils'

export interface FormEditPanelProps extends AppContext {
  /** The erp domain object or key to display */
  domain: ErpDomain | string
  /** The data to edit */
  data: FormObject
  panelKey: PanelsKeys
  onSave?: (values: unknown) => void
}

const FormEditPanel: React.FC<FormEditPanelProps> = ({domain, ...props}) => {
  const {t, panelKey, Panels, toast, onSave, data} = props

  const isDomainId = useMemo(() => {
    return typeof domain === 'string'
  }, [domain])

  const {
    data: fetchedDomain,
    isError,
    isLoading
  } = useErpDomainsByKeyQuery(domain as string, {enabled: typeof domain === 'string'}) // cast is okay because we enable query only if it's an id

  const consolidatedDomain = useMemo(() => {
    return typeof domain === 'string' ? fetchedDomain : domain
  }, [domain, fetchedDomain])

  const updateItemMutation = useUpdateMutation()
  const patchItemMutation = usePatchMutation()

  const onSuccess = useCallback(
    (values: FormObject, message: string) => (res: FormObject) => {
      if (onSave) onSave({...values, ...res})

      Panels.close(panelKey)
      toast.success(message)
    },
    [onSave, panelKey, Panels, toast]
  )

  if (isDomainId && isError) return <p>error</p>
  if ((isDomainId && isLoading) || !consolidatedDomain) return <Loader delay={1000} />

  return (
    <FormPanel
      {...props}
      formId={consolidatedDomain?.forms?.read}
      onSave={(values) => {
        if (values._id === undefined) throw new Error('No id provided')

        if (consolidatedDomain.saveMethod === DomainSaveMethod.PUT) {
          return updateItemMutation
            .mutateAsync(
              {
                domain: consolidatedDomain,
                id: values._id,
                item: cleanObjectFromSchema(consolidatedDomain, {...data, ...values})
              },
              {
                onSuccess: onSuccess(values, 'components.panels.formEdit.success')
              }
            )
            .catch((err) => {
              const errorMessage =
                err.response?.data?.data?.err || 'components.panels.formEdit.error'
              toast.error(errorMessage)
            })
        }

        const diff = saveDiff(consolidatedDomain, data, values)
        return patchItemMutation
          .mutateAsync(
            {domain: consolidatedDomain, id: values._id, patchs: diff},
            {
              onSuccess: onSuccess(values, 'action-bar.saveItemSuccess')
            }
          )
          .catch((err) => {
            const errorMessage = err.response?.data?.data?.err || 'action-bar.saveItemError'
            toast.error(errorMessage)
            Panels.close(panelKey)
          })
      }}
      title={t('components.panels.formEdit.title', {name: consolidatedDomain.name}) || undefined}
    />
  )
}

export default withApp(FormEditPanel)
