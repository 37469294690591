import {ThemeConfig} from 'antd'

const defaulttheme: ThemeConfig = {
  token: {
    colorPrimary: '#e6bfb1',
    colorPrimaryBg: '#f8f9fd'
  }
}

export default defaulttheme
