import {AppContext, withApp} from '@store/app'
import {Col, Row} from 'antd'
import React, {useEffect, useState} from 'react'
import CartValidationHeader from './header'
import ProjectValidation from './projectValidation'
import SuppliersValidation from './suppliersValidation'
import {Cart, CartCategory, CartItemsWithSuppliers, ListItem} from './cartValidation.t'

export interface CartValidationProps extends AppContext {
  cart: Cart
  places: ListItem[]
  suppliers: ListItem[]
  validationItemsCategories: CartCategory[]
  cartItems: CartItemsWithSuppliers[]
}

const CartValidation: React.FC<CartValidationProps> = ({
  cart,
  places,
  suppliers,
  validationItemsCategories,
  ...props
}) => {
  const [step, setStep] = React.useState(0)

  const [cartItems, setCartItems] = useState<CartItemsWithSuppliers[]>(props.cartItems)

  return (
    <Row>
      <Col span={22} offset={1}>
        <CartValidationHeader step={step} />
        {step === 0 && (
          <ProjectValidation cart={cart} places={places} suppliers={suppliers} setStep={setStep} />
        )}
        {step === 1 && (
          <SuppliersValidation
            cart={cart}
            categories={validationItemsCategories}
            cartItems={cartItems}
            setCartItems={setCartItems}
          />
        )}
      </Col>
    </Row>
  )
}

export default withApp(CartValidation)
