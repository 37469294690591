import React, {useMemo} from 'react'
import {WorkflowContext, withWorkflow} from '../../utils/useWorkFlow'
import DefaultHandle from '@components/workflow-builder/handles/default-handle'
import {NodeProps} from 'react-flow-renderer/nocss'
import {Button, Menu, MenuProps, Popover} from 'antd'
import {PlusCircleOutlined, SnippetsOutlined} from '@ant-design/icons'
import {DataNodes, FlowNodes, NodeConfigs, NodeType, NodeContext} from '../node-config'
import {useApp} from '@store/app'

export const Size = {
  width: 32,
  height: 32
}

type MenuItem = Required<MenuProps>['items'][number]

interface DropMenuProps {
  nodeId: string
  availableNodes: NodeType[]
  copiedNode: NodeContext | undefined
  onCreateNode: (sourceId: string, type: NodeType) => void
  onPasteNode: (sourceId: string) => void
  setPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const DropMenu = ({
  availableNodes,
  nodeId,
  copiedNode,
  onCreateNode,
  onPasteNode,
  setPopoverOpen
}: DropMenuProps) => {
  const {t} = useApp()

  const onMenuClick = (e: {key: string}) => {
    const nodeType = e.key
    if (nodeType === 'paste') onPasteNode(nodeId)
    else onCreateNode(nodeId, nodeType as NodeType)

    setPopoverOpen(false)
  }

  const menuItems = useMemo(() => {
    const items: MenuProps['items'] = []

    const dataNodes: MenuItem[] = DataNodes.map((type) => {
      const nodeAvailable = availableNodes.includes(type)

      if (nodeAvailable) {
        return {
          key: type,
          label: NodeConfigs[type].name
        }
      }
      return false
    }).filter(Boolean) as unknown as MenuItem[]

    const flowNodes: MenuItem[] = FlowNodes.map((type) => {
      const nodeAvailable = availableNodes.includes(type)

      if (nodeAvailable) {
        return {
          key: type,
          label: NodeConfigs[type].name
        }
      }
      return false
    }).filter(Boolean) as unknown as MenuItem[]

    if (copiedNode) {
      items.push({
        key: 'paste',
        label: `${t('workflows.paste', {node: copiedNode.node})}`,
        icon: <SnippetsOutlined />
      })
    }

    items.push({
      key: 'dataNodes',
      label: `${t('workflows.dataNodes')}`,
      children: dataNodes,
      type: 'group'
    })

    items.push({
      key: 'flowNodes',
      label: `${t('workflows.flowNodes')}`,
      children: flowNodes,
      type: 'group'
    })

    return items
  }, [availableNodes, copiedNode, t])

  return (
    <div className='drop-node-menu'>
      <Menu onClick={onMenuClick} mode='vertical' items={menuItems} selectable={false} />
    </div>
  )
}

interface DropNodeProps extends NodeProps<unknown>, WorkflowContext {}

function DropNode({
  id,
  sourcePosition,
  targetPosition,
  lockInteracton,
  availableNodes,
  onNodeCreate,
  onNodePaste,
  copiedNode
}: DropNodeProps) {
  const [popoverOpen, setPopoverOpen] = React.useState(false)

  return (
    <React.Fragment>
      <Popover
        open={popoverOpen}
        destroyTooltipOnHide
        content={
          <DropMenu
            nodeId={id}
            copiedNode={copiedNode}
            availableNodes={availableNodes}
            onCreateNode={onNodeCreate}
            onPasteNode={onNodePaste}
            setPopoverOpen={(state) => {
              setPopoverOpen(state)
              lockInteracton(state as boolean)
            }}
          />
        }
        placement='right'
        arrow
        trigger='click'
        onOpenChange={(opened) => {
          lockInteracton(opened)
          setPopoverOpen(opened)
        }}>
        <Button type='dashed' className='drop-node' shape='circle' icon={<PlusCircleOutlined />} />
      </Popover>
      {targetPosition && <DefaultHandle type='target' position={targetPosition} />}
      {sourcePosition && <DefaultHandle type='source' position={sourcePosition} />}
      <style>{`
        .drop-node {
          min-width: ${Size.width}px !important;
          width: ${Size.width}px !important;
          min-height: ${Size.height}px !important;
          height: ${Size.height}px !important;
        }
      `}</style>
    </React.Fragment>
  )
}

export default withWorkflow(DropNode)
