import DefaultHandle from '@components/workflow-builder/handles/default-handle'
import React from 'react'
import {NodeProps} from 'react-flow-renderer/nocss'

export const Size = {
  width: 30,
  height: 30
}

function StartNode({sourcePosition}: NodeProps<unknown>) {
  return (
    <React.Fragment>
      <div className='node start-node'>
        <i className='fas fa-lightbulb-on '></i>
      </div>
      {sourcePosition && <DefaultHandle type='source' position={sourcePosition} />}
      <style>{`
        .start-node {
          width: ${Size.width}px;
          height: ${Size.height}px;
        }
      `}</style>
    </React.Fragment>
  )
}

export default StartNode
