import {getEdgeCenter, EdgeText, EdgeProps} from 'react-flow-renderer/nocss'

const bottomLeftCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`
const bottomRightCorner = (x: number, y: number, size: number): string =>
  `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`
const leftTopCorner = (x: number, y: number, size: number): string =>
  `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`
const rightTopCorner = (x: number, y: number, size: number): string =>
  `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`

const getPath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  borderRadius = 5,
  debug = false
}: {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  borderRadius?: number
  debug?: boolean
}) => {
  const [offsetX, offsetY] = getEdgeCenter({sourceX, sourceY, targetX, targetY})
  const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX))
  const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY))
  const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY)
  const cY = sourceY + 25 //typeof centerY !== 'undefined' ? centerY : _centerY

  let firstCornerPath = null
  let secondCornerPath = null

  if (sourceX <= targetX) {
    firstCornerPath = bottomLeftCorner(sourceX, cY, cornerSize)
    secondCornerPath = rightTopCorner(targetX, cY, cornerSize)
  } else {
    firstCornerPath = bottomRightCorner(sourceX, cY, cornerSize)
    secondCornerPath = leftTopCorner(targetX, cY, cornerSize)
  }

  const path = `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`

  if (debug)
    console.log('Edge Path', {
      path,
      firstCornerPath,
      secondCornerPath,
      sourceX,
      sourceY,
      targetX,
      targetY,
      cornerSize,
      cY
    })

  return path
}

type DefaultEdgeProps = EdgeProps

function DefaultEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  style = {},
  label,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding,
  labelBgBorderRadius,
  markerEnd
}: DefaultEdgeProps) {
  const edgePath = getPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  return (
    <>
      <path
        id={id}
        style={style}
        className='react-flow__edge-path'
        d={edgePath}
        markerEnd={markerEnd}
      />
      {!!label && (
        <EdgeText
          x={targetX}
          y={sourceY + 25}
          label={label}
          labelStyle={labelStyle}
          labelShowBg={labelShowBg}
          labelBgStyle={labelBgStyle}
          labelBgPadding={labelBgPadding}
          labelBgBorderRadius={labelBgBorderRadius}
        />
      )}
    </>
  )
}

export default DefaultEdge
