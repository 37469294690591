import React, {useMemo} from 'react'
import {Col, Progress, Row, Typography, theme} from 'antd'
import {Progress as IProgress} from './tm.t'
import {CheckCircleTwoTone} from '@ant-design/icons'

const {Text} = Typography
const {useToken} = theme

interface CollapsePanelHeaderProps {
  title: string
  progress: IProgress
  old: boolean
}

const CollapsePanelHeader: React.FC<CollapsePanelHeaderProps> = ({title, progress, old}) => {
  const percent = useMemo(() => {
    if (!progress.total || old) return 0
    return (progress.done / progress.total) * 100
  }, [progress, old])

  const {token} = useToken()

  return (
    <Row>
      <Col span={21}>
        <Text className='bold'>{title}</Text>
      </Col>
      {!old && (
        <React.Fragment>
          <Col span={2} className='tm-progress-state'>
            {progress.done}/{progress.total}
          </Col>
          {percent > 0 && (
            <Col span={1} className='tm-progress-progress'>
              {percent < 100 ? (
                <Progress type='circle' percent={percent} showInfo={percent === 100} size={20} />
              ) : (
                <CheckCircleTwoTone twoToneColor={token.colorSuccess} style={{fontSize: '20px'}} />
              )}
            </Col>
          )}
        </React.Fragment>
      )}
    </Row>
  )
}

export default CollapsePanelHeader
