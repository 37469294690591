import React from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import DragCard from './drag-card'

interface SortableProps {
  id: string
  remove?: boolean
  onRemove?: () => void
  handle?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
}

const Sortable: React.FC<SortableProps> = (props) => {
  const {remove, onRemove, handle} = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging
  } = useSortable({id: props.id, disabled: !handle})

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <DragCard
      className={`${isDragging ? 'dimmed' : ''} cursor-auto`}
      setActivatorNodeRef={setActivatorNodeRef}
      setNodeRef={setNodeRef}
      style={style}
      listeners={listeners}
      attributes={attributes}
      handle={handle || false}
      remove={remove || false}
      onRemove={onRemove}>
      {props.children}
    </DragCard>
  )
}

export default Sortable
