type LLInput = {
  lat: number
  lng: number
}

type GoogleMappingItem = {
  long_name: string
  short_name: string
  geometry?: {
    location: {
      lat: () => number
      lng: () => number
    }
  }
  place_id: string
  formatted_address?: string
  types?: string[]
  address_components?: any
}

type GoogleMappingKey = {
  locality: (item: GoogleMappingItem) => [string, string]
  route: (item: GoogleMappingItem) => [string, string]
  street_number: (item: GoogleMappingItem) => [string, string]
  postal_code: (item: GoogleMappingItem) => [string, string]
  administrative_area_level_1: (item: GoogleMappingItem) => [string, string]
  administrative_area_level_2: (item: GoogleMappingItem) => [string, string]
  place_id: (item: GoogleMappingItem) => [string, string]
  country: [
    (item: GoogleMappingItem) => [string, string],
    (item: GoogleMappingItem) => [string, string]
  ]
}

const LLToString = (input: LLInput) =>
  (typeof input === 'object' && [input.lat, input.lng].join(',')) || input

// convert input to { lat: '', lng: '' }
const LLToObject = (input: LLInput | string): LLInput => ({
  lat: parseFloat(
    typeof input === 'object' ? input.lat.toString() : input.replace(/\s/g, '').split(',')[0]
  ),
  lng: parseFloat(
    typeof input === 'object' ? input.lng.toString() : input.replace(/\s/g, '').split(',')[1]
  )
})

const googleMappingKeys: GoogleMappingKey = {
  locality: (item) => ['city', item.long_name],
  route: (item) => ['street', item.long_name],
  street_number: (item) => ['street_number', item.long_name],
  postal_code: (item) => ['zipcode', item.long_name],
  administrative_area_level_1: (item) => ['region', item.long_name],
  administrative_area_level_2: (item) => ['state', item.long_name],
  place_id: (item) => ['place_id', item.place_id],
  country: [(item: any) => ['country', item.long_name], (item) => ['country_code', item.short_name]]
}

type GoogleMappingResult = {
  [key: string]: any
}

const googleMappingReducer = (
  acc: GoogleMappingResult,
  item: GoogleMappingItem
): GoogleMappingResult => {
  if (item.geometry) {
    const [lat, lng] = [item.geometry.location.lat(), item.geometry.location.lng()]
    acc.latlng = [lat, lng].join(',')
    acc.geo = {type: 'Point', coordinates: [lng, lat]}
  }
  if (item.place_id) acc.place_id = item.place_id
  if (item.formatted_address) acc.address = item.formatted_address
  if (!acc.types && item.types) acc.types = item.types

  type GoogleMappingKeyType = keyof typeof googleMappingKeys
  const field = item.types && googleMappingKeys[item.types[0] as GoogleMappingKeyType]
  const modifiers = typeof field == 'function' ? [field] : field || []
  modifiers.forEach((fn) => {
    const [key] = fn(item)
    const [, value] = fn(item)
    if (value) acc[key] = value
  })

  return acc
}

const googleDataParser = (results: GoogleMappingItem[]): GoogleMappingItem[] => {
  try {
    return [results[0], ...results[0].address_components]
  } catch {
    return []
  }
}

const inRadius = (center: LLInput | string, marker: LLInput | string, km = 10): boolean => {
  center = LLToObject(center)
  marker = LLToObject(marker)
  const ky = 40000 / 360
  const kx = Math.cos((Math.PI * center.lat) / 180) * ky
  const dx = Math.abs(center.lng - marker.lng) * kx
  const dy = Math.abs(center.lat - marker.lat) * ky
  return Math.sqrt(dx * dx + dy * dy) <= km
}

interface GeocodeParams {
  address?: string
  placeId?: string
  location?: {
    lat: number
    lng: number
  }
  key?: string
}

const geocode = async (params: GeocodeParams): Promise<GoogleMappingResult> => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode(
      params,
      (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
        if (status !== OK) return reject(status)
        if (!results) return reject('no data found')
        const data = googleDataParser(results as any).reduce(googleMappingReducer, {})
        if (!Object.keys(data).length) return reject('no data found')
        resolve(data)
      }
    )
  })
}

const timezone = async (latlng: string): Promise<string> => {
  const endpoint = `https://maps.googleapis.com/maps/api/timezone/json?location=${latlng}&timestamp=${Math.round(
    Date.now() / 1000
  )}&key=${process.env.GMAP_KEY || process.env.NEXT_PUBLIC_GMAP_KEY}`
  return fetch(endpoint)
    .then((response) => response.json())
    .then((data) => data.timeZoneId)
}

export default {
  LLToString,
  LLToObject,
  inRadius,
  geocode,
  timezone
}
