import {useInmemoriServices} from '@services'
import {QueryClient, UseQueryOptions, useQuery} from '@tanstack/react-query'
import {erpKeys} from './erp'

export function useGetCatalogQuery<Filters = unknown, CatalogData = unknown>(
  filters: Filters,
  options?: UseQueryOptions<CatalogData>
) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: erpKeys.articles._ctx.byFilters(filters).queryKey,
    queryFn: () => erpApi.getCatalogData<Filters, CatalogData>(filters).then(({data}) => data.data),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...options
  })
}

export function invalidateCatalogQueries(queryClient: QueryClient) {
  return Promise.all([queryClient.invalidateQueries({queryKey: erpKeys.articles.queryKey})])
}
