import React, {useState} from 'react'
import * as Yup from 'yup'
import {atPath} from '@libs/utils'
import {useField, useFormikContext} from 'formik'
import {Button} from 'antd'
import _ from 'lodash'
import {generateSignature} from './generateSignature'
import PDFViewer from '@components/misc/pdf-viewer'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import {
  CloudinaryInputComponentProps,
  ErrorType,
  ResultType,
  CloudinaryEventInfo
} from './cloudinary-input.t'
import {useApp} from '@store/app'
import FormItem from '../form-item/form-item'

const CLOUDINARY_CLOUD_NAME = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME
const CLOUDINARY_UPLOAD_PRESET = process.env.NEXT_PUBLIC_CLOUDINARY_UPLOAD_PRESET

const CloudinaryInputComponent: React.FC<FieldComponentProps<CloudinaryInputComponentProps>> = ({
  field
}) => {
  const {label, ...props} = field

  const [formikField, meta, helpers] = useField<{hash: string; url: string} | null>({...props})
  const {setFieldValue} = useFormikContext()

  const {t} = useApp()

  const [signature, setSignature] = useState<{
    signature: string
    timestamp: number
    apiKey: string
  } | null>(null)

  const generatedSignature = async () => {
    const signatureData = await generateSignature({})
    setSignature(signatureData)
  }

  const handleCloudinaryEvent = ({
    event,
    info
  }: {
    event: string
    info: CloudinaryEventInfo
  }): void => {
    if (event === 'success' && info) {
      try {
        setFieldValue(formikField.name, {
          hash: info.public_id,
          url: info.url
        })
      } catch (error) {
        console.error('Erreur lors du parsing des informations Cloudinary: ', error)
      }
    }
  }

  return (
    <FormItem field={field}>
      {formikField.name && !formikField?.value?.hash && (
        <Button
          type='primary'
          disabled={field.disabled}
          onClick={async () => {
            await generatedSignature()

            window?.cloudinary
              ?.createUploadWidget(
                {
                  cloudName: CLOUDINARY_CLOUD_NAME,
                  uploadPreset: CLOUDINARY_UPLOAD_PRESET,
                  sources: ['local', 'camera', 'url'],
                  resourceType: 'auto',
                  buttonText: 'Choisir un fichier',
                  cropping: true,
                  onFailure: (res) => console.log(res),
                  logging: true,
                  signature: signature?.signature,
                  api_key: signature?.apiKey,
                  timestamp: signature?.timestamp
                },
                (error: ErrorType | null, result?: ResultType) => {
                  if (error) console.log('Cloudinary-input error: ', error.message)
                  else if (result) handleCloudinaryEvent({event: result?.event, info: result?.info})
                }
              )
              .open()
          }}>
          {t('components.forms.fields.cloudinary.chooseFile')}
        </Button>
      )}
      {formikField?.value?.hash && (
        <PDFViewer
          file={`https://res.cloudinary.com/inmemori/image/upload/f_pdf/${formikField.value.hash}`}
          helper={helpers}
        />
      )}
    </FormItem>
  )
}

const CloudinaryInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      let schema = Yup.object()
        .shape({
          hash: Yup.string(),
          url: Yup.string()
        })
        .nullable()

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <CloudinaryInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default CloudinaryInput
