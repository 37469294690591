import {Button, Col, Row} from 'antd'
import LayoutColumn from './layout-column'
import {FormLayoutRowWithUniqIds} from './layout-input.t'
import {SortableContext, horizontalListSortingStrategy, useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {useDroppable} from '@dnd-kit/core'
import {useActionContext} from './ActionContext'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface LayoutRowProps {
  row: FormLayoutRowWithUniqIds
}

export default function LayoutRow({row}: LayoutRowProps) {
  const actionContext = useActionContext()

  const {setNodeRef: setDroppableNodeRef} = useDroppable({
    id: row.id + '_droppable',
    data: {
      id: row.id,
      type: 'droppable'
    }
  })

  const {setNodeRef, listeners, attributes, transform, isDragging} = useSortable({
    id: row.id,
    data: {
      type: 'row'
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    ...(isDragging && {opacity: 0})
  }

  function renderChildren() {
    return row.columns.map((column) => (
      <div key={column.id} style={{flex: column.weight}}>
        <LayoutColumn column={column} />
      </div>
    ))
  }

  return (
    <Col style={style}>
      <div className='layout-header top-border-rad'>
        <div className='layout-handle' ref={setNodeRef} {...listeners} {...attributes}>
          <FontAwesomeIcon icon={'up-down-left-right'} />
        </div>
        <div className='layout-buttons'>
          <Button onClick={() => actionContext.addColumn(row.id)}>
            <FontAwesomeIcon icon={'square-dashed-circle-plus'} />
          </Button>
          <Button onClick={() => actionContext.openEditEntityModal(row.id)}>
            <FontAwesomeIcon icon={'gear'} />
          </Button>
          <Button onClick={() => actionContext.deleteRow(row.id)}>
            <FontAwesomeIcon icon={'trash'} />
          </Button>
        </div>
      </div>
      <Row className='layout-row' key={row.id}>
        <SortableContext
          items={row.columns.map((c) => c.id)}
          strategy={horizontalListSortingStrategy}
          id={row.id}>
          {row.columns.length == 0 ? (
            <div
              ref={setDroppableNodeRef}
              id={row.id + '_droppable'}
              className='layout-row-droppable'>
              {renderChildren()}
            </div>
          ) : (
            renderChildren()
          )}
        </SortableContext>
      </Row>
    </Col>
  )
}
