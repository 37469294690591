/**
 * used in price-bundle-input.tsx
 * Allow OPS to link HT to TTC or not
 */

import React from 'react'
import {Button} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface LinkButtonProps {
  isAutomatic?: boolean
  setIsAutomatic: (isAutomatic: boolean) => void
}

const LinkButton: React.FC<LinkButtonProps> = ({isAutomatic, setIsAutomatic}) => {
  const handleToggle = () => {
    setIsAutomatic(!isAutomatic)
  }
  return (
    <Button
      style={{padding: '4px 8px'}}
      type={isAutomatic ? 'default' : 'primary'}
      onClick={handleToggle}>
      <FontAwesomeIcon icon={isAutomatic ? 'link-horizontal' : 'link-horizontal-slash'} />
    </Button>
  )
}

export default LinkButton
