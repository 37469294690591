import React from 'react'
import {useField, useFormikContext} from 'formik'
import Yup from '@libs/yup'
import {ColorPicker} from 'antd'
import _ from 'lodash'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import FormItem from '../form-item/form-item'

interface ColorInputComponentProps {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
}

const rgbToHex = (r: number, g: number, b: number): string =>
  '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()

const ColorInputComponent: React.FC<FieldComponentProps<ColorInputComponentProps>> = ({field}) => {
  const {...props} = field

  const [formikField] = useField<string>({...props})
  const {setFieldValue} = useFormikContext()

  const handleOnChange = (color: any) => {
    const {r, g, b} = color.metaColor
    const hexColor = rgbToHex(Math.round(r), Math.round(g), Math.round(b))
    setFieldValue(formikField.name, hexColor)
  }

  return (
    <React.Fragment>
      <FormItem field={field} style={{flex: 1}} className='mb-2'>
        <ColorPicker
          format='hex'
          value={formikField.value}
          defaultValue={formikField.value}
          onChange={handleOnChange}
          disabled={field.disabled}
        />
      </FormItem>
    </React.Fragment>
  )
}

const ColorInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      const schema = Yup.string().matches(
        /^#[0-9A-F]{6}$/i,
        'Le code couleur doit être au format hexadécimal'
      )
      return {
        [field.key]: field.required ? schema.required() : schema.nullable()
      }
    },
    generateComponent() {
      return <ColorInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default ColorInput
