import React, {ChangeEventHandler} from 'react'

interface ToggleSwitchProps {
  label: string
  checked: boolean
  onChange: (value: boolean) => void
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({label, checked, onChange}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.checked)
  }
  return (
    <div className='form-check form-switch'>
      <span className='form-check-label'>{label}</span>
      <input
        id='catalog-toggle-switch'
        className='form-check-input'
        type='checkbox'
        role='switch'
        data-toggle='toggle'
        checked={checked}
        onChange={handleChange}
      />
    </div>
  )
}

export default ToggleSwitch
