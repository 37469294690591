import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Exec from '@libs/exec'
import * as Utils from '@libs/utils'
import {Button} from 'antd'
import {useFormikContext} from 'formik'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import _ from 'lodash'

interface ButtonWidgetComponentProps {
  name: string
  label: string
  icon?: string
  action?: string
  disabled?: boolean
}

const ButtonWidgetComponent: React.FC<FieldComponentProps<ButtonWidgetComponentProps>> = ({
  field
}: FieldComponentProps<ButtonWidgetComponentProps>) => {
  const {name, label, icon, action, disabled} = field
  const formProps = useFormikContext()

  const getValues = () => {
    return Utils.atPath(
      formProps.values as object,
      `${Utils.relativePath(name) ? `${Utils.relativePath(name)}` : ''}`
    )
  }

  const getValue = (value: string) => {
    return Utils.atPath(
      formProps.values as object,
      `${Utils.relativePath(name) ? `${Utils.relativePath(name)}.` : ''}${value}`
    )
  }

  const onClick = async () => {
    try {
      action &&
        (await Exec(action, {
          // app,  /** TODO: handle app singleton */
          field: {name},
          form: formProps,
          helper: {getValue, getValues}
        }))
    } catch (e) {
      console.warn('Error in button widget action', e)
    }
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      {...(!!icon && {
        icon: <FontAwesomeIcon icon={icon as IconProp} style={{marginRight: '.5em'}} />
      })}>
      {label}
    </Button>
  )
}

const ButtonWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return <ButtonWidgetComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default ButtonWidget
