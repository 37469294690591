import React, {useState, useMemo} from 'react'
import FormComponent from '@components/forms/form'
import {FormObject, FormState} from '@components/forms/form.t'
import {FormikProps} from 'formik'
import {useCallbackRef} from 'use-callback-ref'
import {Button, Col, Divider, Row, Space, Typography} from 'antd'
import {AppContext, withApp} from '@store/app'
import {CloseOutlined} from '@ant-design/icons'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useGetQuery} from '@queries'
import {Action, FormDomain} from '@shared/interfaces'

const {Title} = Typography

export interface FormPanelProps extends AppContext {
  formId?: string
  formKey?: string
  formRef?: React.MutableRefObject<FormikProps<FormObject>>
  panelKey: PanelsKeys
  data?: FormObject
  onSave: (values: FormObject) => void
  onSaveLabel?: string
  title?: string
  defaultEmpty?: boolean
  action?: Action
}

const FormPanel: React.FC<FormPanelProps> = ({
  formId,
  formKey,
  formRef,
  panelKey,
  data = {},
  onSave,
  onSaveLabel = 'panel.form.defaultSaveButton',
  title = 'panel.form.defaultTitle',
  defaultEmpty = false,
  t,
  Panels
}) => {
  const [formState, setFormState] = useState<FormState>({
    isDirty: false,
    isValid: false,
    isSubmitting: false
  })

  const formikRef = useCallbackRef<FormikProps<FormObject>>(null, (node) => {
    if (node && formRef) formRef.current = node
    if (
      node &&
      (node.dirty !== formState.isDirty ||
        node.isValid !== formState.isValid ||
        node.isSubmitting !== formState.isSubmitting)
    )
      setFormState({
        isDirty: node.dirty,
        isValid: node.isValid,
        isSubmitting: node.isSubmitting
      })
  })

  const formsQuery = useGetQuery<FormDomain>('forms', {api: {hydrate: true, limit: 0}})

  const usedForm = useMemo(() => {
    if (formKey && formsQuery.data) {
      return formsQuery.data?.find((f) => f.key === formKey)?._id
    }
    if (formId) return formId
    return
  }, [formId, formKey, formsQuery.data])

  if (!usedForm) return null

  return (
    <Col>
      <Row justify='space-between'>
        <Col>
          <Title level={4}>{t(title)}</Title>
        </Col>
        <Col>
          <Row>
            <Space>
              <Button
                disabled={!(formState.isDirty && formState.isValid)}
                loading={formState.isSubmitting}
                onClick={() => formikRef.current?.submitForm()}
                type={formState.isDirty ? 'primary' : 'default'}>
                {t(onSaveLabel)}
              </Button>
              <Button icon={<CloseOutlined />} onClick={() => Panels.close(panelKey)} />
            </Space>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <FormComponent
          formId={usedForm}
          data={data}
          innerRef={formikRef}
          disabled={false}
          onSubmit={onSave}
        />
      </Row>
    </Col>
  )
}

export default withApp(FormPanel)
