import FormComponent from '@components/forms/form'
import {FormObject} from '@components/forms/form.t'
import {useApp} from '@store/app'
import {Button, Col, Row} from 'antd'
import {FormikProps} from 'formik'
import {useCallback, useRef, useState} from 'react'

function cleanFormObject(formObject: FormObject): FormObject {
  return Object.entries(formObject).reduce((acc, [key, value]) => {
    if (![null, undefined, ''].includes(value as any)) {
      acc[key] = value
    }

    return acc
  }, {} as FormObject)
}

export interface FormFilterProps {
  filterFormId: string
  onFilter: (
    /** Values returned by onSubmit FormComponent */
    cleanFilters: FormObject,
    /** Raw filters filled in form values */
    formFilters?: FormObject,
    /** Raw filters filled in form default values */
    defaultFormFilters?: FormObject
  ) => void
  initialFilters?: FormObject
  submitOnMount?: boolean
}

const FormFilter = (props: FormFilterProps) => {
  const {filterFormId, initialFilters, onFilter, submitOnMount} = props

  const formikRef = useRef<FormikProps<FormObject>>(null)

  const {t} = useApp()

  const [formFilters, setFormFilters] = useState<FormObject | undefined>(initialFilters)
  const [formDefaultValues, setFormDefaultValues] = useState<FormObject>({})

  const onDefaultValuesChange = useCallback(
    (defaultValues: FormObject) => {
      setFormDefaultValues(defaultValues)
    },
    [setFormDefaultValues]
  )

  return (
    <Col>
      <Row>
        <FormComponent
          innerRef={formikRef}
          formId={filterFormId}
          data={formFilters}
          onSubmit={(values) => {
            onFilter(cleanFormObject(values), formikRef.current?.values, formDefaultValues)
            setFormFilters(formikRef.current?.values)
          }}
          onDefaultValuesChange={onDefaultValuesChange}
          submitOnMount={submitOnMount}
        />
      </Row>
      <Row justify={'space-between'}>
        <Button
          type='text'
          onClick={() => {
            setFormFilters(undefined)
            formikRef.current?.resetForm()
          }}>
          {t('components.lookup-component.form-filter.reset')}
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (formikRef.current) {
              formikRef.current.submitForm()
            }
          }}>
          {t('components.lookup-component.form-filter.search')}
        </Button>
      </Row>
    </Col>
  )
}

export default FormFilter
