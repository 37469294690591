import {PlusCircleOutlined} from '@ant-design/icons'
import {FormObject} from '@components/forms/form.t'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PanelsKeys} from '@libs/panels/panels.t'
import {Action} from '@shared/interfaces'
import {useApp} from '@store/app'
import {Button, Col, Row} from 'antd'
import React from 'react'
import MultiselectActionMenu from './multiselect-action-menu'
import {UseActionHandlerResult} from './table.t'

interface CollapseHeaderProps {
  title: string
  actions: {headerActions: Action[]; multiSelectionActions: Action[]}
  add: boolean
  showMultiselectActions: boolean
  createForm?: string
  onActionSuccess: () => void
  actionHandler: UseActionHandlerResult
  onSave?: (values: FormObject) => void
}

const CollapseHeader: React.FC<CollapseHeaderProps> = ({
  title,
  actions: {multiSelectionActions, headerActions},
  actionHandler,
  createForm,
  onSave,
  add,
  showMultiselectActions,
  onActionSuccess
}) => {
  const {Panels} = useApp()

  return (
    <Row>
      <Col flex='auto' className='bold'>
        {title}
      </Col>
      <Col flex='none'>
        {add && (
          <Button
            type='dashed'
            shape='round'
            icon={<PlusCircleOutlined />}
            size={'small'}
            onClick={() => {
              Panels.show(PanelsKeys.FORM, {
                formId: createForm,
                title: `Create ${title}`,
                onSave
              })
            }}>
            Ajouter
          </Button>
        )}
        {headerActions.map((action: Action) => (
          <Button
            key={action._id}
            icon={action.icon && <FontAwesomeIcon icon={action.icon} className='me-2' />}
            onClick={() => actionHandler()(action)}
            className='bold'
            style={{marginLeft: '0.5rem'}}>
            {action.name}
          </Button>
        ))}
        {showMultiselectActions && (
          <MultiselectActionMenu
            actionHandler={actionHandler}
            actions={multiSelectionActions}
            onActionSuccess={onActionSuccess}
          />
        )}
      </Col>
    </Row>
  )
}

export default CollapseHeader
