import {FormObject} from '@components/forms/form.t'
import {Crud, UseActionHandlerResult} from '@components/table/table.t'
import useActionHandler from '@hooks/use-action-handler'
import {PanelsKeys} from '@libs/panels/panels.t'
import {Action, ErpTable} from '@shared/interfaces'
import {useApp} from '@store/app'
import {useCallback} from 'react'

const useMenuItemClick = (table: ErpTable, actions: Action[], crud?: Crud) => {
  const {Panels} = useApp()

  const actionHandler: UseActionHandlerResult = useActionHandler(table.domain?.keyPlural)

  const onMenuItemClick = useCallback(
    (record: FormObject) => (e: {key: string}) => {
      if (e.key.startsWith('edit-'))
        return Panels.show(PanelsKeys.FORM, {
          formId: table.forms?.edit,
          title: `Modifier ${table.name}`,
          data: record,
          onSave: crud?.onEdit
        })
      if (e.key.startsWith('delete-')) return crud?.onRemove(record)
      if (e.key.startsWith('action-')) {
        const action = actions.find((a) => a._id === e.key.replace('action-', ''))
        if (action) actionHandler(record)(action)
        return
      }
    },
    [table.forms?.edit, table.name, crud, Panels, actions, actionHandler]
  )

  return onMenuItemClick
}

export default useMenuItemClick
