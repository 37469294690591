'use client'

import React, {useState} from 'react'
import {Button, Pagination} from 'antd'
import {DeleteOutlined} from '@ant-design/icons'
import {Document, Page, pdfjs} from 'react-pdf'
import {FieldHelperProps} from 'formik'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

interface PDFViewerProps {
  file: string
  helper?: FieldHelperProps<{hash: string; url: string} | null>
}

const PDFViewer: React.FC<PDFViewerProps> = ({file, helper}) => {
  const [numPages, setNumPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const logError = (err: Error) => console.error('Erreur de chargement du PDF:', file, err.message)

  const onDocumentLoadSuccess = ({numPages: loadedNumPages}: {numPages: number}) => {
    setNumPages(loadedNumPages)
  }

  const onRemove = () => {
    if (helper) helper.setValue({hash: '', url: ''})
  }

  const handleRemove = () => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce fichier ?')) {
      onRemove()
    }
  }

  return (
    <div className='pdf-container'>
      {helper && (
        <div className='pdf-button-deleted'>
          <Button icon={<DeleteOutlined />} onClick={handleRemove} />
        </div>
      )}
      <div className='pdf-viewer'>
        <Document
          renderMode='canvas'
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={logError}>
          <Page
            pageNumber={currentPage}
            onLoadError={logError}
            onRenderError={logError}
            className={`pdf-page ${currentPage}`}
            width={window.innerWidth / 2}
          />
        </Document>
      </div>
      {numPages && numPages > 1 && (
        <div className='pdf-navigation'>
          <Pagination
            defaultCurrent={1}
            total={numPages}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  )
}

export default PDFViewer
