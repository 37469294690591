import {Tooltip} from 'antd'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Field} from '@components/forms/fields/inputs/form-item/form-item'

const resolveLabel = (field: Field): React.ReactNode | undefined => {
  if (field.hideLabel) return

  return (
    <>
      {field.label} {field.required && '*'}
      {field.tooltip && field.tooltip.length && (
        <Tooltip color='gray' title={<div dangerouslySetInnerHTML={{__html: field.tooltip}}></div>}>
          <FontAwesomeIcon
            className='cursor-help ms-1'
            color='gray'
            size='sm'
            icon={'circle-question'}
          />
        </Tooltip>
      )}
    </>
  )
}

export default resolveLabel
