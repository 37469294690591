import * as Yup from 'yup'
import {atPath} from '@libs/utils'
import _ from 'lodash'
import dynamic from 'next/dynamic'

import type {FieldComponentFactory} from '../../fields.t'

const RichInputComponent = dynamic(import('./rich-input'), {ssr: false})

const RichInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema = Yup.string()

      if (field.required) schema = schema.required()

      return {[field.key]: schema.nonNullable()}
    },
    generateComponent() {
      return <RichInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default RichInput
