import {createQueryKeys} from '@lukemorales/query-key-factory'
import {ErpApiService, useInmemoriServices} from '@services'
import {ErpDomain, Permission} from '@shared/interfaces'
import {QueryClient, UseQueryOptions, useQuery, useQueryClient} from '@tanstack/react-query'

export const erpKeys = createQueryKeys('erp', {
  domains: {
    queryKey: null,
    contextQueries: {byKeyPlural: (key: string) => [key], byKey: (key: string) => [key]}
  },
  permissions: null,
  articles: {
    queryKey: null,
    contextQueries: {byFilters: <T>(filters: T) => [filters]}
  },
  profiles: {
    queryKey: null,
    contextQueries: {self: null}
  },
  projects: {
    queryKey: null,
    contextQueries: {checklist: (id: string) => [id], timeline: (id: string) => [id]}
  }
})

export function useErpDomainsQuery(options?: UseQueryOptions<ErpDomain[]>) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: erpKeys.domains.queryKey,
    queryFn: async () => {
      return (await erpApi.getErpDomains()).data.data
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  })
}

function defaultErpDomainsByKeyOrKeyPluralQueryOptions(
  domainKey: string,
  isPlural: boolean,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options?: UseQueryOptions<ErpDomain>
) {
  return {
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: isPlural
      ? erpKeys.domains._ctx.byKeyPlural(domainKey).queryKey
      : erpKeys.domains._ctx.byKey(domainKey).queryKey,
    queryFn: async () => {
      const cachedErpDomain = queryClient
        .getQueryData<ErpDomain[]>(erpKeys.domains.queryKey, {exact: true})
        ?.find(({keyPlural, key}) => (isPlural ? keyPlural === domainKey : key === domainKey))

      if (cachedErpDomain) return cachedErpDomain

      const fetchedErpDomain = (await erpApi.getErpDomains()).data.data.find(({keyPlural, key}) =>
        isPlural ? keyPlural === domainKey : key === domainKey
      )

      if (!fetchedErpDomain) throw new Error(`ErpDomain '${domainKey}' not found`)

      return fetchedErpDomain
    },
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  }
}

export function useErpDomainsByKeyPluralQuery(
  domainKeyPlural: string,
  options?: UseQueryOptions<ErpDomain>
) {
  const erpApi = useInmemoriServices().erpApiService
  const queryClient = useQueryClient()

  return useQuery(
    defaultErpDomainsByKeyOrKeyPluralQueryOptions(
      domainKeyPlural,
      true,
      erpApi,
      queryClient,
      options
    )
  )
}

export function useErpDomainsByKeyQuery(domainKey: string, options?: UseQueryOptions<ErpDomain>) {
  const erpApi = useInmemoriServices().erpApiService
  const queryClient = useQueryClient()

  return useQuery(
    defaultErpDomainsByKeyOrKeyPluralQueryOptions(domainKey, false, erpApi, queryClient, options)
  )
}

export function fetchErpDomainsByKeyPlural(
  domainKeyPlural: string,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options?: UseQueryOptions<ErpDomain>
) {
  return queryClient.fetchQuery(
    defaultErpDomainsByKeyOrKeyPluralQueryOptions(
      domainKeyPlural,
      true,
      erpApi,
      queryClient,
      options
    )
  )
}

export function fetchErpDomainsByKey(
  domainKey: string,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options?: UseQueryOptions<ErpDomain>
) {
  return queryClient.fetchQuery(
    defaultErpDomainsByKeyOrKeyPluralQueryOptions(domainKey, false, erpApi, queryClient, options)
  )
}

export function useErpPermissionsQuery(options?: UseQueryOptions<Permission[]>) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: erpKeys.permissions.queryKey,
    queryFn: async () => {
      return (await erpApi.getPermissions()).data.data
    },
    staleTime: 210 * 1000 * 60,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  })
}
