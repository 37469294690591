import {atPath} from '@libs/utils'
import {Collapse, Space} from 'antd'
import React, {Dispatch, useMemo, useState} from 'react'
import * as Yup from 'yup'
import 'yup-phone'

import {
  FieldComponent,
  FieldComponentFactory,
  FieldComponentProps
} from '@components/forms/fields/fields.t'
import {FormObject, ValidationAction} from '@components/forms/form.t'
import {closestCenter, DndContext, DragOverlay, UniqueIdentifier} from '@dnd-kit/core'
import {restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import DragCard from '@libs/dnd/drag-card'
import Sortable from '@libs/dnd/sortable'
import {FieldDomain, FieldSubType, FormDomain} from '@shared/interfaces'
import {FieldArray, useField} from 'formik'
import _ from 'lodash'
import CollapseHeader from '../subform-array/header'
import Inputs from '../inputs'

interface ArrayInputFieldComponentProps {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  subtype?: FieldSubType

  /** if true no add, move or remove feature in this array (default: false) */
  locked?: boolean
}

interface ArrayInputComponentProps extends FieldComponentProps<ArrayInputFieldComponentProps> {
  forms: FormDomain[]
  dispatchValidation: Dispatch<ValidationAction>
}

const ArrayInputComponent: React.FC<ArrayInputComponentProps> = ({
  field,
  forms,
  dispatchValidation
}: ArrayInputComponentProps) => {
  const {label, name} = field

  const [formField] = useField({...field})

  const list: Array<FormObject> = useMemo(() => formField.value || [], [formField.value])

  const indexList = useMemo(
    () => list.map((data, index: number) => `${name}.${index}}`),
    [list, name]
  )

  const subfields = useMemo(() => {
    if (!field.subtype) return []

    return list
      .map((data, index) => {
        if (!field.subtype) return false
        return Inputs[field.subtype](
          {
            ...field,
            key: `${name}.${index}`,
            name: `${name}.${index}`,
            label: '',
            type: field.subtype
          } as FieldDomain,
          forms
        )
      })
      .filter(Boolean) as FieldComponent[]
  }, [field, forms, list, name])

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(true)

  if (!field.subtype) return null

  return (
    <FieldArray {...formField}>
      {({remove, move, push}) => (
        <Collapse
          activeKey={isOpen ? [name] : []}
          style={{flex: 1}}
          onChange={(params) => {
            setIsOpen(params.includes(name))
          }}>
          <Collapse.Panel
            header={
              <CollapseHeader
                label={label}
                add={!field.locked && !field.disabled}
                onAdd={() => {
                  push(field.subtype === 'entity' ? undefined : '')
                  setIsOpen(true)
                }}
              />
            }
            key={name}>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToParentElement, restrictToVerticalAxis]}
              onDragStart={(event) => setActiveId(event.active.id)}
              onDragEnd={(event) => {
                const {active, over} = event

                setActiveId(null)

                if (over && active.id !== over.id) {
                  const oldIndex = indexList.indexOf(`${active.id}`)
                  const newIndex = indexList.indexOf(`${over.id}`)
                  arrayMove(indexList, oldIndex, newIndex)
                  move(oldIndex, newIndex)
                }
              }}>
              {list && Array.isArray(list) && !!list.length && (
                <>
                  <Space direction='vertical' size='middle' style={{display: 'flex'}}>
                    <SortableContext items={indexList} strategy={verticalListSortingStrategy}>
                      {indexList.map((id, index) => {
                        if (id == activeId) return null
                        return (
                          <Sortable
                            key={id}
                            id={id}
                            handle={!field.locked && !field.disabled}
                            remove={!field.locked && !field.disabled}
                            onRemove={() => remove(index)}>
                            {subfields[index].generateComponent(dispatchValidation)}
                          </Sortable>
                        )
                      })}
                    </SortableContext>
                  </Space>
                  <DragOverlay>
                    {activeId ? (
                      <DragCard
                        handle={!field.locked && !field.disabled}
                        remove={!field.locked && !field.disabled}>
                        {subfields[indexList.indexOf(activeId as string)].generateComponent(() => {
                          /* Disabled as we will not handle validation on dummy drag component */
                        })}
                      </DragCard>
                    ) : null}
                  </DragOverlay>
                </>
              )}
            </DndContext>
          </Collapse.Panel>
        </Collapse>
      )}
    </FieldArray>
  )
}

const ArrayInput: FieldComponentFactory = (field, forms) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      return value || []
    },
    validationSchema() {
      let subSchema: Yup.Schema
      switch (field.subtype) {
        case 'number':
          subSchema = Yup.number()
          break
        case 'email':
          subSchema = Yup.string().email()
          break
        case 'url':
          subSchema = Yup.string().url()
          break
        case 'file':
          subSchema = Yup.mixed()
          break
        case 'phone':
          subSchema = Yup.string().phone()
          break
        case 'entity':
          subSchema = Yup.mixed()
          break
        case 'location':
          subSchema = Yup.mixed()
          break
        default:
          subSchema = Yup.string()
      }

      let schema = Yup.array().of(subSchema)

      if (field.required) schema = Yup.array().of(subSchema.required()).min(1).required()

      return {[field.key]: schema}
    },
    generateComponent(dispatchValidation) {
      return (
        <ArrayInputComponent
          forms={forms}
          field={_.omit(field, 'hidden', 'ref')}
          dispatchValidation={dispatchValidation}
        />
      )
    }
  }
}
export default ArrayInput
