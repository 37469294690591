import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCommentExclamation, faBug} from '@fortawesome/pro-regular-svg-icons'
import {FloatButton, Input, Modal, theme} from 'antd'
import {AppContext, withApp} from '@store/app'
import {DatadgoErrorScope} from '@shared/interfaces'
const {useToken} = theme
const {TextArea} = Input

const BUG_REPORT_ONLY = true

const Helper: React.FC<AppContext> = ({t, Datadog, toast}) => {
  const {token} = useToken()

  const [bugComment, setBugComment] = useState('')
  const [bugOpen, setBugOpen] = useState(false)

  const sendBugReport = () => {
    Datadog.trackError(
      new Error(bugComment, {
        cause: bugComment
      }),
      {
        scope: DatadgoErrorScope.GLOBAL,
        comment: bugComment
      }
    )
    setBugComment('')
    toast.success(`${t('help.bug.success')}`)
  }

  return (
    <>
      {BUG_REPORT_ONLY && (
        <FloatButton
          icon={
            <FontAwesomeIcon
              icon={faCommentExclamation}
              style={{maxWidth: '18px'}}
              color={token.colorError}
            />
          }
          onClick={() => setBugOpen(true)}
          tooltip={t('help.bugReport')}
        />
      )}
      {!BUG_REPORT_ONLY && (
        <FloatButton.Group
          trigger='click'
          style={{right: 24}}
          icon={
            <FontAwesomeIcon
              icon={faCommentExclamation}
              style={{maxWidth: '18px'}}
              color={token.colorError}
            />
          }
          tooltip={t('help.defaultButton')}>
          <FloatButton tooltip={t('help.documentation')} />
          <FloatButton
            icon={
              <FontAwesomeIcon icon={faBug} style={{maxWidth: '18px'}} color={token.colorError} />
            }
            tooltip={t('help.bugReport')}
            onClick={() => setBugOpen(true)}
          />
        </FloatButton.Group>
      )}
      <Modal
        title={t('help.bug.tittle')}
        maskClosable
        open={bugOpen}
        onOk={() => {
          setBugOpen(false)
          sendBugReport()
        }}
        okText={t('help.bug.ok')}
        okButtonProps={{disabled: !bugComment}}
        cancelText={t('help.bug.cancel')}
        closable={false}
        onCancel={() => {
          setBugOpen(false)
          setBugComment('')
        }}>
        <TextArea
          rows={4}
          value={bugComment}
          placeholder={`${t('help.bug.placeholder')}`}
          onChange={(e) => {
            setBugComment(e.target.value)
          }}
        />
      </Modal>
    </>
  )
}

export default withApp(Helper)
