import LookupComponent from '@components/lookup-component'
import Loader from '@components/misc/loader'
import {PanelsKeys} from '@libs/panels/panels.t'
import {useErpDomainsByKeyQuery} from '@queries'
import {LookupContext, LookupItem, MatchLookup} from '@shared/erp-api'
import {AppContext, withApp} from '@store/app'
import React from 'react'

export interface EntityFinderPanelProps extends AppContext {
  /** The domain key */
  domain: string
  context?: LookupContext
  customQ?: Record<string, string | undefined>
  matchLookup?: MatchLookup
  onChoose?: (value: LookupItem | undefined) => void
}

const EntityFinderPanel: React.FC<EntityFinderPanelProps> = ({
  domain,
  context,
  customQ,
  matchLookup,
  onChoose,
  Panels
}) => {
  const domainQuery = useErpDomainsByKeyQuery(domain)

  if (domainQuery.isLoading) return <Loader />

  if (domainQuery.isError) Panels.close(PanelsKeys.ENTITY_FINDER)

  return (
    <LookupComponent
      domainName={domainQuery.data?.keyPlural}
      setSelectedLookupItem={(value) => {
        Panels.close(PanelsKeys.ENTITY_FINDER)

        if (onChoose) onChoose(value)
      }}
      autoSelect={false}
      lookupOptions={{q: customQ, matchLookup, context}}
    />
  )
}

export default withApp(EntityFinderPanel)
