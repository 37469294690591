import React from 'react'
import {Button, Dropdown, Menu, MenuProps} from 'antd'
import {MoreOutlined} from '@ant-design/icons'

export interface ActionsDropdownProps {
  menuProps: MenuProps
}

const ActionsDropdown: React.FC<ActionsDropdownProps> = ({menuProps}) => {
  return (
    <Dropdown
      dropdownRender={() => <Menu {...menuProps} />}
      placement='bottomLeft'
      trigger={['click']}
      arrow>
      <Button
        type='default'
        icon={<MoreOutlined />}
        className='bold'
        style={{marginLeft: '0.5rem'}}
      />
    </Dropdown>
  )
}

export default ActionsDropdown
