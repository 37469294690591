import React, {useState} from 'react'
import {useField, useFormikContext} from 'formik'
import Yup from '@libs/yup'
import _ from 'lodash'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import FormItem from '../form-item/form-item'

interface FileUploadInputProps {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
}

const FileUploadInputComponent: React.FC<FieldComponentProps<FileUploadInputProps>> = ({field}) => {
  const [file, setFile] = useState<File>()

  const [formikField] = useField<File>(field)

  const {setFieldValue} = useFormikContext()

  return (
    <React.Fragment>
      <FormItem field={field} style={{flex: 1}} className='mb-2'>
        <input
          type='file'
          name={formikField.name}
          value={file?.name}
          accept='.csv, application/JSON'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const uploadedFile = _.first(e.target.files)
            setFile(uploadedFile)
            setFieldValue(formikField.name, uploadedFile)
          }}
        />
      </FormItem>
    </React.Fragment>
  )
}

const FileUploadInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      const schema = Yup.mixed()

      return {
        [field.key]: field.required ? schema.required('File is required') : schema.nullable()
      }
    },
    generateComponent() {
      return <FileUploadInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default FileUploadInput
