import {atPath, relativePath} from '@libs/utils'
import {BaseEntity, FieldDomain} from '@shared/interfaces'
import handlebars from '@libs/handlebars'
import {LookupOptions} from '@shared/erp-api'

type Field = Pick<FieldDomain, 'q' | 'matchLookup' | 'sort' | 'domain' | 'apiContext'>

/**
 * Handlebars Parser service
 *
 * @param {string} fieldName - eg: house
 * @param {BaseEntity} values - formProps values (formik)
 * @param {EntityPickerField | undefined} field - erp field
 * @param {string | undefined} domain - erp domain key => used to fetch the plural key for fetching data
 */
export default class Parser {
  private field?: Field

  constructor(private fieldName: string, private values: BaseEntity) {}

  setField(field: Field) {
    this.field = field
    return this
  }

  /**
   * Returns the context data for handlebar parser
   *
   * @function
   *
   * @param {any} instance
   * @returns {object}
   */
  private getHandlebarsContext(instance: any) {
    return {
      values: this.values,
      instance
    }
  }

  /**
   * Parses a handlebar template string
   *
   * @function
   *
   * @param {string} v
   * @returns {string}
   */
  private parse(v: string): string {
    if (!v) return ''
    let instance
    const path = relativePath(this.fieldName)
    if (path) instance = atPath(this.values, path)
    return this.values ? handlebars(v, this.getHandlebarsContext(instance)) : v
  }

  /**
   * Parses all the fields with handlebar template
   *
   * @function
   *
   * @returns {Parser}
   */
  parseField(): Parser {
    if (this.field) {
      const {q, sort, matchLookup, domain, apiContext} = this.field
      this.field = {
        ...this.field,
        ...(q ? {q: this.parse(q)} : {}),
        ...(sort ? {sort: this.parse(sort)} : {}),
        ...(matchLookup ? {matchLookup: this.parse(matchLookup)} : {}),
        ...(domain ? {domain: this.parse(domain)} : {}),
        ...(apiContext ? {apiContext: this.parse(apiContext)} : {})
      }
    }

    return this
  }

  /**
   * Transforms the parsed handlebars templates to JSON objects (ready to use for data fetching)
   *
   * @function
   *
   * @returns LookupOptions
   */
  getLookupOptions(): LookupOptions | undefined {
    if (this.field) {
      const {q, sort, matchLookup, apiContext} = this.field
      return {
        ...(q && {q: JSON.parse(q)}),
        ...(matchLookup && {matchLookup: JSON.parse(matchLookup)}),
        ...(sort && {sort: JSON.parse(sort)}),
        ...(apiContext && {context: JSON.parse(apiContext)})
      }
    }
  }

  /**
   * Erp domain key => used to fetch the plural key for fetching data
   *
   * @virtual
   */
  get domain(): string | undefined {
    return this.field?.domain
  }
}
