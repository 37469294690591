import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Action} from '@shared/interfaces'
import {MenuProps} from 'antd'
import React, {useCallback, useMemo} from 'react'
import ActionsDropdown from './actions-dropdown'
import {UseActionHandlerResult} from './table.t'

interface MultiselectActionMenuProps {
  actions: Action[]
  actionHandler: UseActionHandlerResult
  onActionSuccess: () => void
}

const MultiselectActionMenu: React.FC<MultiselectActionMenuProps> = ({
  actions,
  actionHandler,
  onActionSuccess
}) => {
  const items: MenuProps['items'] = useMemo(
    () =>
      actions.map((action: Action) => ({
        key: `action-multi-${action._id}`,
        icon: action.icon && <FontAwesomeIcon icon={action.icon} />,
        label: <a className='bold'>{action.name}</a>
      })),
    [actions]
  )

  const onMenuItemClick = useCallback(
    (e: {key: string}) => {
      if (e.key.startsWith('action-multi-')) {
        const action = actions.find((a) => a._id === e.key.replace('action-multi-', ''))
        if (action) actionHandler?.()(action).then(onActionSuccess)
        return
      }
    },
    [actionHandler, actions, onActionSuccess]
  )

  return <ActionsDropdown menuProps={{onClick: onMenuItemClick, items}} />
}

export default MultiselectActionMenu
