import {v4 as uuid} from 'uuid'
import {arrayMove} from '@dnd-kit/sortable'
import {DragEndEvent, DragOverEvent, DragStartEvent} from '@dnd-kit/core'
import _ from 'lodash'

import {
  FieldDomainWithUniqId,
  FormLayoutColumnWithUniqIds,
  FormLayoutComponentWithUniqId,
  FormLayoutEntity,
  FormLayoutRowWithUniqIds,
  ModalData
} from './layout-input.t'

export function computeUniqDndIds(
  rows: FormLayoutRowWithUniqIds[],
  fields: FieldDomainWithUniqId[]
) {
  const rowsWithUniqIds = !rows
    ? []
    : rows.map((row) => {
        return {
          ...row,
          columns: row.columns.map((column) => {
            return {
              ...column,
              components: column.components.map((component) => {
                return {
                  ...component,
                  dndId: uuid()
                }
              })
            }
          })
        }
      })

  const fieldsWithUniqIds = fields.map((field) => {
    return {
      id: field._id,
      label: field.label,
      dndId: uuid()
    }
  })

  return {rowsWithUniqIds, fieldsWithUniqIds}
}

export function updateLayoutOnDragEnd(
  event: DragEndEvent,
  currentLayout: FormLayoutRowWithUniqIds[]
): FormLayoutRowWithUniqIds[] | null {
  const draggedEntityType = event.active.data.current?.type
  let newRows: FormLayoutRowWithUniqIds[] = _.cloneDeep(currentLayout)

  //If dropping in an empty column, no need to reorder
  if (!event.over || event.over?.data.current?.type === 'droppable') return null

  //Re-order field in column
  if (draggedEntityType === 'component' || draggedEntityType === 'field') {
    const targetContainerID = event.over?.data.current?.sortable?.containerId
    if (targetContainerID === 'drawer' || targetContainerID === 'rows') return null
    const targetColumn = getLayoutEntity(newRows, targetContainerID)
    if (!targetColumn || targetColumn.type !== 'column') return null
    //Put field at correct ordering index in column
    targetColumn.entity.components = arrayMove(
      targetColumn.entity.components,
      event.active.data.current?.sortable?.index,
      event.over?.data.current?.sortable?.index
    )
  }
  //Re-order columns
  else if (draggedEntityType === 'column') {
    const targetContainerId = event.over?.data.current?.sortable?.containerId
    if (targetContainerId === 'drawer' || targetContainerId === 'rows') return null
    const targetRow = getLayoutEntity(newRows, targetContainerId)
    if (!targetRow || targetRow.type !== 'row') return null
    //Put column at correct ordering index in row
    targetRow.entity.columns = arrayMove(
      targetRow.entity.columns,
      event.active.data.current?.sortable?.index,
      event.over?.data.current?.sortable?.index
    )
  }
  //Re-order rows
  else if (draggedEntityType === 'row') {
    //Put row at correct ordering index

    newRows = arrayMove(
      newRows,
      event.active.data.current?.sortable?.index,
      event.over?.data.current?.sortable?.index
    )
  }
  return newRows
}

function getLayoutEntity(
  rows: FormLayoutRowWithUniqIds[],
  id: string
): FormLayoutEntity | undefined {
  let result: FormLayoutEntity | undefined

  searchLoop: for (const row of rows) {
    if (row.id === id) {
      result = {
        entity: row,
        type: 'row'
      }
      break searchLoop
    }
    for (const column of row.columns) {
      if (column.id === id) {
        result = {entity: column, type: 'column'}
        break searchLoop
      }
      for (const component of column.components) {
        if (component.dndId === id) {
          result = {entity: component, type: 'component'}
          break searchLoop
        }
      }
    }
  }
  return result
}

export function updateLayoutOnDragOver(
  event: DragOverEvent,
  currentLayout: FormLayoutRowWithUniqIds[],
  currentFields: FieldDomainWithUniqId[]
) {
  const sourceContainer = event.active.data.current?.sortable
  const targetContainer = event.over?.data.current

  if (
    !targetContainer ||
    !sourceContainer ||
    targetContainer.sortable?.containerId === 'drawer' ||
    sourceContainer.containerId == targetContainer.sortable?.containerId
  )
    return null

  const newRows: FormLayoutRowWithUniqIds[] = _.cloneDeep(currentLayout)
  let newFields: FieldDomainWithUniqId[] = _.cloneDeep(currentFields)

  let target

  if (targetContainer.type === 'droppable') {
    //Dragging in droppable, i.e. an empty sortable
    target = getLayoutEntity(newRows, targetContainer.id)
  } else {
    //Dragging in a sortable with elements
    target = getLayoutEntity(newRows, targetContainer.sortable.containerId)
  }

  const source = getLayoutEntity(newRows, sourceContainer.containerId)

  //Dragging a field
  if (
    (event.active?.data.current?.type === 'field' ||
      event.active?.data.current?.type === 'component') &&
    target?.type === 'column'
  ) {
    let componentToMove: FormLayoutComponentWithUniqId | undefined

    //Dragging a new field onto the layout
    if (sourceContainer.containerId === 'drawer') {
      const newField = currentFields[sourceContainer.index]
      componentToMove = {
        id: newField._id,
        ...newField
      }
      //Generate new uuid for the dragged field
      newFields = currentFields.map((field) =>
        field._id === newField._id ? {...field, dndId: uuid()} : field
      )
    }
    // Moving a component between columns
    else if (source && source.type === 'column') {
      //Remove it form original column
      componentToMove = source.entity.components.splice(sourceContainer.index, 1)[0]
    }

    if (!componentToMove) return {newFields}

    //Add component to target column
    if (targetContainer.type === 'droppable') {
      //Dropping in an empty column
      target.entity.components.push(componentToMove)
    } else {
      //Dropping in a column with components
      target.entity.components.splice(targetContainer.sortable.index, 0, componentToMove)
    }
  }
  //Dragging a column to a row
  else if (
    event.active?.data.current?.type === 'column' &&
    target?.type === 'row' &&
    source &&
    source.type === 'row'
  ) {
    //Remove it form original row
    const columnToMove = source.entity.columns.splice(sourceContainer.index, 1)[0]

    //Add column to target row
    if (targetContainer.type === 'droppable') {
      //Dropping in an empty row
      target.entity.columns.push(columnToMove)
    } else {
      //Dropping in a row with columns
      target.entity.columns.splice(targetContainer.sortable.index, 0, columnToMove)
    }
  }

  return {newRows, newFields}
}

export function updateLayoutOnDragStart(
  event: DragStartEvent,
  currentLayout: FormLayoutRowWithUniqIds[],
  currentFields: FieldDomainWithUniqId[]
) {
  const type = event.active.data.current?.type
  let data: FormLayoutEntity
  if (type === 'field') {
    const field = currentFields.find((field) => field.dndId === event.active.id)
    if (!field) return
    data = {type: 'field', entity: field}
  } else {
    const entity = getLayoutEntity(currentLayout, event.active.id as string)
    if (!entity) return
    data = entity
  }
  return data
}

export function changeColumnWeightInLayout(
  currentLayout: FormLayoutRowWithUniqIds[],
  columnId: string,
  weight: number
) {
  const newRows = _.cloneDeep(currentLayout)

  for (const row of newRows) {
    const columnIndex = row.columns.findIndex(
      (column: FormLayoutColumnWithUniqIds) => column.id === columnId
    )
    if (columnIndex !== -1) {
      row.columns[columnIndex].weight = weight > 1 ? weight : 1
      break
    }
  }
  return newRows
}

export function getModalData(currentLayout: FormLayoutRowWithUniqIds[], entityId: string) {
  const entity = getLayoutEntity(currentLayout, entityId)

  if (entity && (entity.type === 'row' || entity.type === 'column'))
    return {
      id: entity.entity.id,
      label: entity.entity.label || '',
      showLabel: entity.entity.showLabel || false,
      hidden: entity.entity.hidden || '',
      className: entity.entity.className || ''
    }
  else return null
}

export function setModalDataInLayout(
  currentLayout: FormLayoutRowWithUniqIds[],
  modalData: ModalData
) {
  const {id, ...modalDataWithoutId} = modalData
  let newRows = _.cloneDeep(currentLayout)
  newRows = newRows.map((row: FormLayoutRowWithUniqIds) => {
    if (row.id === id) {
      return {...row, ...modalDataWithoutId}
    } else {
      return {
        ...row,
        columns: row.columns.map((column) => {
          if (column.id === id) {
            return {...column, ...modalDataWithoutId}
          } else return {...column}
        })
      }
    }
  })
  return newRows
}

export function deleteComponentInLayout(
  currentLayout: FormLayoutRowWithUniqIds[],
  componentId: string
) {
  const newRows = _.cloneDeep(currentLayout)
  deleteLoop: for (const row of newRows) {
    for (const column of row.columns) {
      const index = column.components.findIndex(
        (component: FormLayoutComponentWithUniqId) => component.dndId === componentId
      )
      if (index !== -1) {
        column.components.splice(index, 1)
        break deleteLoop
      }
    }
  }
  return newRows
}

export function deleteColumnInLayout(currentLayout: FormLayoutRowWithUniqIds[], columnId: string) {
  const newRows = _.cloneDeep(currentLayout)
  newRows.forEach((row: FormLayoutRowWithUniqIds) => {
    row.columns = row.columns.filter((column) => column.id !== columnId)
  })
  return newRows
}

export function addColumnInLayout(currentLayout: FormLayoutRowWithUniqIds[], rowId: string) {
  const newRows = _.cloneDeep(currentLayout)
  newRows
    .find((row: FormLayoutRowWithUniqIds) => row.id === rowId)
    ?.columns.push({
      components: [],
      id: uuid(),
      label: '',
      weight: 1,
      showLabel: false
    })
  return newRows
}

export function addRowInLayout(currentLayout: FormLayoutRowWithUniqIds[], index: number) {
  const newRows = _.cloneDeep(currentLayout)
  newRows.splice(index + 1, 0, {
    columns: [
      {
        components: [],
        id: uuid(),
        label: '',
        weight: 1,
        showLabel: false
      }
    ],
    id: uuid(),
    label: '',
    showLabel: false
  })
  return newRows
}
