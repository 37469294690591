import * as Yup from 'yup'
import React, {useMemo, useState} from 'react'
import {atPath} from '@libs/utils'

import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'

import CodeEditor from '@components/misc/code-editor'
import FormItem from '../form-item/form-item'

interface CodeInputComponentProps {
  _id: string
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  language?: string
}

const CodeInputComponent: React.FC<FieldComponentProps<CodeInputComponentProps>> = ({field}) => {
  const formProps = useFormikContext<{_id: string}>()
  const [formikField, meta] = useField<string>({...field})
  const [filename] = useState<string>(`${formikField.name}/${field.key}/${formProps.values._id}.ts`)

  const editorLanguage = useMemo(() => {
    if (field.type === 'json') return 'json'
    if (!field.language || field.language === 'js' || field.language === 'javascript')
      return 'typescript'
    return field.language
  }, [field])

  const initialValue = useMemo(
    () =>
      typeof meta.initialValue === 'string'
        ? meta.initialValue
        : JSON.stringify(meta.initialValue, null, 2),
    [meta.initialValue]
  )

  const onChange = (value: string) => {
    let formikValue: string | undefined = value
    if (editorLanguage === 'json') {
      try {
        const parsedJson = JSON.parse(value)
        formikValue = parsedJson
      } catch (e) {
        if (!value) formikValue = undefined
        else return
      }
    }

    formProps.setFieldValue(formikField.name, formikValue)
  }

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      <CodeEditor
        name={filename}
        language={editorLanguage}
        parsedValue={initialValue}
        disabled={field.disabled}
        onChange={onChange}
      />
    </FormItem>
  )
}

const CodeInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema =
        field.type === 'json' || (field as CodeInputComponentProps).language === 'json'
          ? Yup.mixed().oneOfSchemas([Yup.object(), Yup.array()])
          : Yup.string()

      if (field.required) schema = schema.required()

      return {[field.key]: schema.nonNullable()}
    },
    generateComponent() {
      return <CodeInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default CodeInput
