type ParamsToSign = {
  [key: string]: string | number
}

type GenerateSignatureData = {
  signature: string
  timestamp: number
  apiKey: string
}

export async function generateSignature(
  paramsToSign: ParamsToSign
): Promise<GenerateSignatureData> {
  try {
    const response = await fetch(`/api/generateCloudinarySignature`, {
      method: 'POST',
      body: JSON.stringify({
        paramsToSign
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      throw new Error('Erreur lors de la génération de la signature Cloudinary')
    }

    const {signature}: {signature: GenerateSignatureData} = await response.json()
    return signature
  } catch (error) {
    console.error('Erreur dans la fonction generateSignature:', error)
    throw error
  }
}
