import {useFormikContext} from 'formik'
import _ from 'lodash'
import {useEffect, useRef} from 'react'
import {FieldComponentFactory, FieldComponentProps} from '../fields.t'
import {filterTextData} from './utils'
import {useInmemoriServices} from '@services'
import {useQueryClient} from '@tanstack/react-query'

interface HtmlWidgetComponentProps {
  content?: string
  disabled?: boolean
  name: string
  path?: string
  snippet?: string
  styles?: {groupStyle?: React.CSSProperties; groupClass?: string}
  widgetContext?: string
}

/**
 * Processes and re-adds script tags from a given element.
 * This is necessary because the HTML standard doesn't allow <script> block execution
 * when inserted using innerHTML. The solution is to create a new script element
 * and append it to the document.
 *
 * @param {HTMLElement} element - The element containing script tags to be processed.
 */
const processScripts = (element: HTMLElement, snippetId?: string): void => {
  const scripts = element.getElementsByTagName('script')

  for (const script of Array.from(scripts)) {
    let scriptContent = script.textContent || ''
    if (snippetId) {
      scriptContent = scriptContent.replaceAll('__MY_SNIPPET_ID__', snippetId)
    }

    const newScript = document.createElement('script')
    const inlineScript = document.createTextNode(scriptContent)

    newScript.appendChild(inlineScript)
    element.removeChild(script)
    element.appendChild(newScript)
  }
}

const HtmlWidgetComponent: React.FC<FieldComponentProps<HtmlWidgetComponentProps>> = ({
  field
}: FieldComponentProps<HtmlWidgetComponentProps>) => {
  const {content, name, path, snippet, widgetContext, styles} = field
  const formProps = useFormikContext<object>()

  const ref = useRef<HTMLDivElement>(null)

  const {erpApiService, apiProxyV1Service} = useInmemoriServices()
  const queryClient = useQueryClient()

  useEffect(() => {
    const snippetId = _.uniqueId('snippet_')

    const updateData = async () => {
      const data = await filterTextData(
        erpApiService,
        queryClient,
        formProps.values,
        name,
        apiProxyV1Service,
        path,
        snippet,
        content,
        widgetContext
      )

      if (ref && ref.current) {
        window.runtimeSnippets[snippetId] = {
          ref,
          formProps
        }

        ref.current.innerHTML = ''

        const div = document.createElement('div')
        div.innerHTML = data || ''

        processScripts(div, snippetId)

        ref.current.appendChild(div)
      }
    }

    updateData()

    // Destroy the runtime snippet when the component is unmounted
    return () => {
      if (window.runtimeSnippets[snippetId]) {
        delete window.runtimeSnippets[snippetId]
      }
    }
  }, [
    name,
    path,
    content,
    snippet,
    widgetContext,
    formProps.values,
    erpApiService,
    queryClient,
    apiProxyV1Service
  ])

  return <div className='form-group' style={styles?.groupStyle} ref={ref}></div>
}

const HtmlWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return <HtmlWidgetComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default HtmlWidget
