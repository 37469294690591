/* eslint-disable no-useless-escape */
// https://raw.githubusercontent.com/axios/axios/v1.x/index.d.ts
const axios = `
// TypeScript Version: 4.1
type AxiosHeaderValue = string | string[] | number | boolean | null;
type RawAxiosHeaders = Record<string, AxiosHeaderValue>;

type MethodsHeaders = {
  [Key in Method as Lowercase<Key>]: AxiosHeaders;
};

interface CommonHeaders  {
  common: AxiosHeaders;
}

type AxiosHeaderMatcher = (this: AxiosHeaders, value: string, name: string, headers: RawAxiosHeaders) => boolean;

type AxiosHeaderSetter = (value: AxiosHeaderValue, rewrite?: boolean | AxiosHeaderMatcher) => AxiosHeaders;

type AxiosHeaderGetter = ((parser?: RegExp) => RegExpExecArray | null) |
    ((matcher?: AxiosHeaderMatcher) => AxiosHeaderValue);

type AxiosHeaderTester = (matcher?: AxiosHeaderMatcher) => boolean;

class AxiosHeaders {
  constructor(
      headers?: RawAxiosHeaders | AxiosHeaders,
      defaultHeaders?: RawAxiosHeaders | AxiosHeaders
  );

  set(headerName?: string, value?: AxiosHeaderValue, rewrite?: boolean | AxiosHeaderMatcher): AxiosHeaders;
  set(headers?: RawAxiosHeaders | AxiosHeaders, rewrite?: boolean): AxiosHeaders;

  get(headerName: string, parser: RegExp): RegExpExecArray | null;
  get(headerName: string, matcher?: true | AxiosHeaderMatcher): AxiosHeaderValue;

  has(header: string, matcher?: true | AxiosHeaderMatcher): boolean;

  delete(header: string | string[], matcher?: AxiosHeaderMatcher): boolean;

  clear(): boolean;

  normalize(format: boolean): AxiosHeaders;

  toJSON(): RawAxiosHeaders;

  static from(thing?: AxiosHeaders | RawAxiosHeaders | string): AxiosHeaders;

  static accessor(header: string | string[]): AxiosHeaders;

  setContentType: AxiosHeaderSetter;
  getContentType: AxiosHeaderGetter;
  hasContentType: AxiosHeaderTester;

  setContentLength: AxiosHeaderSetter;
  getContentLength: AxiosHeaderGetter;
  hasContentLength: AxiosHeaderTester;

  setAccept: AxiosHeaderSetter;
  getAccept: AxiosHeaderGetter;
  hasAccept: AxiosHeaderTester;

  setUserAgent: AxiosHeaderSetter;
  getUserAgent: AxiosHeaderGetter;
  hasUserAgent: AxiosHeaderTester;

  setContentEncoding: AxiosHeaderSetter;
  getContentEncoding: AxiosHeaderGetter;
  hasContentEncoding: AxiosHeaderTester;
}

type RawAxiosRequestHeaders = Partial<RawAxiosHeaders & MethodsHeaders & CommonHeaders>;

type AxiosRequestHeaders = Partial<RawAxiosHeaders & MethodsHeaders & CommonHeaders> & AxiosHeaders;

type RawAxiosResponseHeaders = Partial<Record<string, string> & {
  "set-cookie"?: string[]
}>;

type AxiosResponseHeaders = RawAxiosResponseHeaders & AxiosHeaders;

interface AxiosRequestTransformer {
  (this: AxiosRequestConfig, data: any, headers: AxiosRequestHeaders): any;
}

interface AxiosResponseTransformer {
  (this: AxiosRequestConfig, data: any, headers: AxiosResponseHeaders, status?: number): any;
}

interface AxiosAdapter {
  (config: AxiosRequestConfig): AxiosPromise;
}

interface AxiosBasicCredentials {
  username: string;
  password: string;
}

interface AxiosProxyConfig {
  host: string;
  port: number;
  auth?: {
    username: string;
    password: string;
  };
  protocol?: string;
}

type Method =
    | 'get' | 'GET'
    | 'delete' | 'DELETE'
    | 'head' | 'HEAD'
    | 'options' | 'OPTIONS'
    | 'post' | 'POST'
    | 'put' | 'PUT'
    | 'patch' | 'PATCH'
    | 'purge' | 'PURGE'
    | 'link' | 'LINK'
    | 'unlink' | 'UNLINK';

type ResponseType =
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream';

type responseEncoding =
    | 'ascii' | 'ASCII'
    | 'ansi' | 'ANSI'
    | 'binary' | 'BINARY'
    | 'base64' | 'BASE64'
    | 'base64url' | 'BASE64URL'
    | 'hex' | 'HEX'
    | 'latin1' | 'LATIN1'
    | 'ucs-2' | 'UCS-2'
    | 'ucs2' | 'UCS2'
    | 'utf-8' | 'UTF-8'
    | 'utf8' | 'UTF8'
    | 'utf16le' | 'UTF16LE';

interface TransitionalOptions {
  silentJSONParsing?: boolean;
  forcedJSONParsing?: boolean;
  clarifyTimeoutError?: boolean;
}

interface GenericAbortSignal {
  aborted: boolean;
  onabort: ((...args: any) => any) | null;
  addEventListener: (...args: any) => any;
  removeEventListener: (...args: any) => any;
}

interface FormDataVisitorHelpers {
  defaultVisitor: SerializerVisitor;
  convertValue: (value: any) => any;
  isVisitable: (value: any) => boolean;
}

interface SerializerVisitor {
  (
      this: GenericFormData,
      value: any,
      key: string | number,
      path: null | Array<string | number>,
      helpers: FormDataVisitorHelpers
  ): boolean;
}

interface SerializerOptions {
  visitor?: SerializerVisitor;
  dots?: boolean;
  metaTokens?: boolean;
  indexes?: boolean;
}

// tslint:disable-next-line
interface FormSerializerOptions extends SerializerOptions {
}

interface ParamEncoder {
  (value: any, defaultEncoder: (value: any) => any): any;
}

interface ParamsSerializerOptions extends SerializerOptions {
  encode?: ParamEncoder;
}

type MaxUploadRate = number;

type MaxDownloadRate = number;

interface AxiosProgressEvent {
  loaded: number;
  total?: number;
  progress?: number;
  bytes: number;
  rate?: number;
  estimated?: number;
  upload?: boolean;
  download?: boolean;
}

interface AxiosRequestConfig<D = any> {
  url?: string;
  method?: Method | string;
  baseURL?: string;
  transformRequest?: AxiosRequestTransformer | AxiosRequestTransformer[];
  transformResponse?: AxiosResponseTransformer | AxiosResponseTransformer[];
  headers?: RawAxiosRequestHeaders;
  params?: any;
  paramsSerializer?: ParamsSerializerOptions;
  data?: D;
  timeout?: number;
  timeoutErrorMessage?: string;
  withCredentials?: boolean;
  adapter?: AxiosAdapter;
  auth?: AxiosBasicCredentials;
  responseType?: ResponseType;
  responseEncoding?: responseEncoding | string;
  xsrfCookieName?: string;
  xsrfHeaderName?: string;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
  maxContentLength?: number;
  validateStatus?: ((status: number) => boolean) | null;
  maxBodyLength?: number;
  maxRedirects?: number;
  maxRate?: number | [MaxUploadRate, MaxDownloadRate];
  beforeRedirect?: (options: Record<string, any>, responseDetails: {headers: Record<string, string>}) => void;
  socketPath?: string | null;
  httpAgent?: any;
  httpsAgent?: any;
  proxy?: AxiosProxyConfig | false;
  cancelToken?: CancelToken;
  decompress?: boolean;
  transitional?: TransitionalOptions;
  signal?: GenericAbortSignal;
  insecureHTTPParser?: boolean;
  env?: {
    FormData?: new (...args: any[]) => object;
  };
  formSerializer?: FormSerializerOptions;
}

interface HeadersDefaults {
  common: RawAxiosRequestHeaders;
  delete: RawAxiosRequestHeaders;
  get: RawAxiosRequestHeaders;
  head: RawAxiosRequestHeaders;
  post: RawAxiosRequestHeaders;
  put: RawAxiosRequestHeaders;
  patch: RawAxiosRequestHeaders;
  options?: RawAxiosRequestHeaders;
  purge?: RawAxiosRequestHeaders;
  link?: RawAxiosRequestHeaders;
  unlink?: RawAxiosRequestHeaders;
}

interface AxiosDefaults<D = any> extends Omit<AxiosRequestConfig<D>, 'headers'> {
  headers: HeadersDefaults;
}

interface CreateAxiosDefaults<D = any> extends Omit<AxiosRequestConfig<D>, 'headers'> {
  headers?: RawAxiosRequestHeaders | Partial<HeadersDefaults>;
}

interface AxiosResponse<T = any, D = any>  {
  data: T;
  status: number;
  statusText: string;
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
}

class AxiosError<T = unknown, D = any> extends Error {
  constructor(
      message?: string,
      code?: string,
      config?: AxiosRequestConfig<D>,
      request?: any,
      response?: AxiosResponse<T, D>
  );

  config?: AxiosRequestConfig<D>;
  code?: string;
  request?: any;
  response?: AxiosResponse<T, D>;
  isAxiosError: boolean;
  status?: number;
  toJSON: () => object;
  cause?: Error;
  static readonly ERR_FR_TOO_MANY_REDIRECTS = "ERR_FR_TOO_MANY_REDIRECTS";
  static readonly ERR_BAD_OPTION_VALUE = "ERR_BAD_OPTION_VALUE";
  static readonly ERR_BAD_OPTION = "ERR_BAD_OPTION";
  static readonly ERR_NETWORK = "ERR_NETWORK";
  static readonly ERR_DEPRECATED = "ERR_DEPRECATED";
  static readonly ERR_BAD_RESPONSE = "ERR_BAD_RESPONSE";
  static readonly ERR_BAD_REQUEST = "ERR_BAD_REQUEST";
  static readonly ERR_NOT_SUPPORT = "ERR_NOT_SUPPORT";
  static readonly ERR_INVALID_URL = "ERR_INVALID_URL";
  static readonly ERR_CANCELED = "ERR_CANCELED";
  static readonly ECONNABORTED = "ECONNABORTED";
  static readonly ETIMEDOUT = "ETIMEDOUT";
}

class CanceledError<T> extends AxiosError<T> {
}

type AxiosPromise<T = any> = Promise<AxiosResponse<T>>;

interface CancelStatic {
  new (message?: string): Cancel;
}

interface Cancel {
  message: string | undefined;
}

interface Canceler {
  (message?: string, config?: AxiosRequestConfig, request?: any): void;
}

interface CancelTokenStatic {
  new (executor: (cancel: Canceler) => void): CancelToken;
  source(): CancelTokenSource;
}

interface CancelToken {
  promise: Promise<Cancel>;
  reason?: Cancel;
  throwIfRequested(): void;
}

interface CancelTokenSource {
  token: CancelToken;
  cancel: Canceler;
}

interface AxiosInterceptorOptions {
  synchronous?: boolean;
  runWhen?: (config: AxiosRequestConfig) => boolean;
}

interface AxiosInterceptorManager<V> {
  use<T = V>(onFulfilled?: (value: V) => T | Promise<T>, onRejected?: (error: any) => any, options?: AxiosInterceptorOptions): number;
  eject(id: number): void;
}

class Axios {
  constructor(config?: AxiosRequestConfig);
  defaults: AxiosDefaults;
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>;
  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
}

interface AxiosInstance extends Axios {
  <T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): AxiosPromise<R>;
  <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): AxiosPromise<R>;

  defaults: Omit<AxiosDefaults, 'headers'> & {
    headers: HeadersDefaults & {
      [key: string]: AxiosHeaderValue
    }
  };
}

interface GenericFormData {
  append(name: string, value: any, options?: any): any;
}

interface GenericHTMLFormElement {
  name: string;
  method: string;
  submit(): void;
}

interface AxiosStatic extends AxiosInstance {
  create(config?: CreateAxiosDefaults): AxiosInstance;
  Cancel: CancelStatic;
  CancelToken: CancelTokenStatic;
  Axios: typeof Axios;
  AxiosError: typeof AxiosError;
  readonly VERSION: string;
  isCancel(value: any): value is Cancel;
  all<T>(values: Array<T | Promise<T>>): Promise<T[]>;
  spread<T, R>(callback: (...args: T[]) => R): (array: T[]) => R;
  isAxiosError<T = any, D = any>(payload: any): payload is AxiosError<T, D>;
  toFormData(sourceObj: object, targetFormData?: GenericFormData, options?: FormSerializerOptions): GenericFormData;
  formToJSON(form: GenericFormData|GenericHTMLFormElement): object;
}

declare const axios: AxiosStatic;
`

const bcrypt = `
// Type definitions for bcrypt 5.0
// Project: https://www.npmjs.org/package/bcrypt
// Definitions by:  Peter Harris <https://github.com/codeanimal>
//                  Ayman Nedjmeddine <https://github.com/IOAyman>
//                  David Stapleton <https://github.com/dstapleton92>
//                  BendingBender <https://github.com/BendingBender>
// Definitions: https://github.com/DefinitelyTyped/DefinitelyTyped

/// <reference types="node" />

declare namespace bcrypt {
  /**
   * @param rounds The cost of processing the data. Default 10.
   * @param minor The minor version of bcrypt to use. Either 'a' or 'b'. Default 'b'.
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const saltRounds = 10;
   *
   * const salt = bcrypt.genSaltSync(saltRounds);
   */
  export declare function genSaltSync(rounds?: number, minor?: "a" | "b"): string;

  /**
   * @param rounds The cost of processing the data. Default 10.
   * @param minor The minor version of bcrypt to use. Either 'a' or 'b'. Default 'b'.
   * @return A promise to be either resolved with the generated salt or rejected with an Error
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const saltRounds = 10;
   *
   * (async () => {
   *     const salt = await bcrypt.genSalt(saltRounds);
   * })();
   */
  export declare function genSalt(rounds?: number, minor?: "a" | "b"): Promise<string>;

  /**
   * @param rounds The cost of processing the data. Default 10.
   * @param minor The minor version of bcrypt to use. Either 'a' or 'b'. Default 'b'.
   * @param callback A callback to be fire once the salt has been generated. Uses eio making it asynchronous.
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const saltRounds = 10;
   *
   * // Technique 1 (generate a salt and hash on separate function calls):
   * bcrypt.genSalt(saltRounds, (err, salt) => {
   *     // ...
   * });
   */
  export declare function genSalt(callback: (err: Error | undefined, salt: string) => any): void;
  export declare function genSalt(rounds: number, callback: (err: Error | undefined, salt: string) => any): void;
  export declare function genSalt(
      rounds: number,
      minor: "a" | "b",
      callback: (err: Error | undefined, salt: string) => any,
  ): void;

  /**
   * @param data The data to be encrypted.
   * @param saltOrRounds The salt to be used to hash the password. If specified as a number then a
   * salt will be generated with the specified number of rounds and used.
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const saltRounds = 10;
   * const myPlaintextPassword = 's0//\P4$$w0rD';
   *
   * // Technique 1 (generate a salt and hash on separate function calls):
   * const salt = bcrypt.genSaltSync(saltRounds);
   * const hash = bcrypt.hashSync(myPlaintextPassword, salt);
   * // Store hash in your password DB.
   *
   * // Technique 2 (auto-gen a salt and hash):
   * const hash2 = bcrypt.hashSync(myPlaintextPassword, saltRounds);
   * // Store hash in your password DB.
   */
  export declare function hashSync(data: string | Buffer, saltOrRounds: string | number): string;

  /**
   * @param data The data to be encrypted.
   * @param saltOrRounds The salt to be used in encryption. If specified as a number then a
   * salt will be generated with the specified number of rounds and used.
   * @return A promise to be either resolved with the encrypted data salt or rejected with an Error
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const saltRounds = 10;
   * const myPlaintextPassword = 's0/\/\P4$$w0rD';
   *
   * (async () => {
   *     // Technique 1 (generate a salt and hash on separate function calls):
   *     const salt = await bcrypt.genSalt(saltRounds);
   *     const hash = await bcrypt.hash(myPlaintextPassword, salt);
   *     // Store hash in your password DB.
   *
   *     // Technique 2 (auto-gen a salt and hash):
   *     const hash2 = await bcrypt.hash(myPlaintextPassword, saltRounds);
   *     // Store hash in your password DB.
   * })();
   */
  export declare function hash(data: string | Buffer, saltOrRounds: string | number): Promise<string>;

  /**
   * @param data The data to be encrypted.
   * @param saltOrRounds The salt to be used in encryption. If specified as a number then a
   * salt will be generated with the specified number of rounds and used.
   * @param callback A callback to be fired once the data has been encrypted. Uses eio making it asynchronous.
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const saltRounds = 10;
   * const myPlaintextPassword = 's0/\/\P4$$w0rD';
   *
   * // Technique 1 (generate a salt and hash on separate function calls):
   * bcrypt.genSalt(saltRounds, (err, salt) => {
   *     bcrypt.hash(myPlaintextPassword, salt, (err, hash) => {
   *         // Store hash in your password DB.
   *     });
   * });
   *
   * // Technique 2 (auto-gen a salt and hash):
   * bcrypt.hash(myPlaintextPassword, saltRounds, (err, hash) => {
   *     // Store hash in your password DB.
   * });
   */
  export declare function hash(
      data: string | Buffer,
      saltOrRounds: string | number,
      callback: (err: Error | undefined, encrypted: string) => any,
  ): void;

  /**
   * @param data The data to be encrypted.
   * @param encrypted The data to be compared against.
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const myPlaintextPassword = 's0/\/\P4$$w0rD';
   * const someOtherPlaintextPassword = 'not_bacon';
   *
   * // Load hash from your password DB.
   * bcrypt.compareSync(myPlaintextPassword, hash); // true
   * bcrypt.compareSync(someOtherPlaintextPassword, hash); // false
   */
  export declare function compareSync(data: string | Buffer, encrypted: string): boolean;

  /**
   * @param data The data to be encrypted.
   * @param encrypted The data to be compared against.
   * @return A promise to be either resolved with the comparison result salt or rejected with an Error
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const myPlaintextPassword = 's0/\/\P4$$w0rD';
   * const someOtherPlaintextPassword = 'not_bacon';
   *
   * (async () => {
   *     // Load hash from your password DB.
   *     const result1 = await bcrypt.compare(myPlaintextPassword, hash);
   *     // result1 == true
   *
   *     const result2 = await bcrypt.compare(someOtherPlaintextPassword, hash);
   *     // result2 == false
   * })();
   */
  export declare function compare(data: string | Buffer, encrypted: string): Promise<boolean>;

  /**
   * @param data The data to be encrypted.
   * @param encrypted The data to be compared against.
   * @param callback A callback to be fire once the data has been compared. Uses eio making it asynchronous.
   *
   * @example
   * import * as bcrypt from 'bcrypt';
   * const myPlaintextPassword = 's0/\/\P4$$w0rD';
   * const someOtherPlaintextPassword = 'not_bacon';
   *
   * // Load hash from your password DB.
   * bcrypt.compare(myPlaintextPassword, hash, (err, result) => {
   *     // result == true
   * });
   * bcrypt.compare(someOtherPlaintextPassword, hash, (err, result) => {
   *     // result == false
   * });
   */
  export declare function compare(
      data: string | Buffer,
      encrypted: string,
      callback: (err: Error | undefined, same: boolean) => any,
  ): void;

  /**
   * @param encrypted Hash from which the number of rounds used should be extracted.
   * @returns The number of rounds used to encrypt a given hash.
   */
  export declare function getRounds(encrypted: string): number;
}
`

const currency = `

type CurrencyAny = number | string | CurrencyConstructor;
type CurrencyFormat = (currency?: currency, opts?: CurrencyOptions) => string;
interface CurrencyConstructor {
  (value: CurrencyAny, opts?: CurrencyOptions): Currency;
  new (value: CurrencyAny, opts?: CurrencyOptions): Currency;
}
interface CurrencyOptions {
  symbol?: string;
  separator?: string;
  decimal?: string;
  errorOnInvalid?: boolean;
  precision?: number;
  increment?: number;
  useVedic?: boolean;
  pattern?: string;
  negativePattern?: string;
  format?: CurrencyFormat;
  fromCents?: boolean;
}

interface Currency {
  add(number: CurrencyAny): Currency;
  subtract(number: CurrencyAny): Currency;
  multiply(number: CurrencyAny): Currency;
  divide(number: CurrencyAny): Currency;
  distribute(count: number): Array<Currency>;
  dollars(): number;
  cents(): number;
  format(opts?: CurrencyOptions | CurrencyFormat): string;
  toString(): string;
  toJSON(): number;
  readonly intValue: number;
  readonly value: number;
}

declare const currency: CurrencyConstructor;
`

const moment = `
/**
 * @param strict Strict parsing disables the deprecated fallback to the native Date constructor when
 * parsing a string.
 */
declare function moment(inp?: moment.MomentInput, strict?: boolean): moment.Moment;
/**
 * @param strict Strict parsing requires that the format and input match exactly, including delimiters.
 * Strict parsing is frequently the best parsing option. For more information about choosing strict vs
 * forgiving parsing, see the [parsing guide](https://momentjs.com/guides/#/parsing/).
 */
declare function moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, strict?: boolean): moment.Moment;
/**
 * @param strict Strict parsing requires that the format and input match exactly, including delimiters.
 * Strict parsing is frequently the best parsing option. For more information about choosing strict vs
 * forgiving parsing, see the [parsing guide](https://momentjs.com/guides/#/parsing/).
 */
declare function moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): moment.Moment;

declare namespace moment {
  type RelativeTimeKey = 's' | 'ss' | 'm' | 'mm' | 'h' | 'hh' | 'd' | 'dd' | 'w' | 'ww' | 'M' | 'MM' | 'y' | 'yy';
  type CalendarKey = 'sameDay' | 'nextDay' | 'lastDay' | 'nextWeek' | 'lastWeek' | 'sameElse' | string;
  type LongDateFormatKey = 'LTS' | 'LT' | 'L' | 'LL' | 'LLL' | 'LLLL' | 'lts' | 'lt' | 'l' | 'll' | 'lll' | 'llll';

  interface Locale {
    calendar(key?: CalendarKey, m?: Moment, now?: Moment): string;

    longDateFormat(key: LongDateFormatKey): string;
    invalidDate(): string;
    ordinal(n: number): string;

    preparse(inp: string): string;
    postformat(inp: string): string;
    relativeTime(n: number, withoutSuffix: boolean,
                 key: RelativeTimeKey, isFuture: boolean): string;
    pastFuture(diff: number, absRelTime: string): string;
    set(config: Object): void;

    months(): string[];
    months(m: Moment, format?: string): string;
    monthsShort(): string[];
    monthsShort(m: Moment, format?: string): string;
    monthsParse(monthName: string, format: string, strict: boolean): number;
    monthsRegex(strict: boolean): RegExp;
    monthsShortRegex(strict: boolean): RegExp;

    week(m: Moment): number;
    firstDayOfYear(): number;
    firstDayOfWeek(): number;

    weekdays(): string[];
    weekdays(m: Moment, format?: string): string;
    weekdaysMin(): string[];
    weekdaysMin(m: Moment): string;
    weekdaysShort(): string[];
    weekdaysShort(m: Moment): string;
    weekdaysParse(weekdayName: string, format: string, strict: boolean): number;
    weekdaysRegex(strict: boolean): RegExp;
    weekdaysShortRegex(strict: boolean): RegExp;
    weekdaysMinRegex(strict: boolean): RegExp;

    isPM(input: string): boolean;
    meridiem(hour: number, minute: number, isLower: boolean): string;
  }

  interface StandaloneFormatSpec {
    format: string[];
    standalone: string[];
    isFormat?: RegExp;
  }

  interface WeekSpec {
    dow: number;
    doy?: number;
  }

  type CalendarSpecVal = string | ((m?: MomentInput, now?: Moment) => string);
  interface CalendarSpec {
    sameDay?: CalendarSpecVal;
    nextDay?: CalendarSpecVal;
    lastDay?: CalendarSpecVal;
    nextWeek?: CalendarSpecVal;
    lastWeek?: CalendarSpecVal;
    sameElse?: CalendarSpecVal;

    // any additional properties might be used with moment.calendarFormat
    [x: string]: CalendarSpecVal | void; // undefined
  }

  type RelativeTimeSpecVal = (
    string |
    ((n: number, withoutSuffix: boolean,
      key: RelativeTimeKey, isFuture: boolean) => string)
  );
  type RelativeTimeFuturePastVal = string | ((relTime: string) => string);

  interface RelativeTimeSpec {
    future?: RelativeTimeFuturePastVal;
    past?: RelativeTimeFuturePastVal;
    s?: RelativeTimeSpecVal;
    ss?: RelativeTimeSpecVal;
    m?: RelativeTimeSpecVal;
    mm?: RelativeTimeSpecVal;
    h?: RelativeTimeSpecVal;
    hh?: RelativeTimeSpecVal;
    d?: RelativeTimeSpecVal;
    dd?: RelativeTimeSpecVal;
    w?: RelativeTimeSpecVal
    ww?: RelativeTimeSpecVal;
    M?: RelativeTimeSpecVal;
    MM?: RelativeTimeSpecVal;
    y?: RelativeTimeSpecVal;
    yy?: RelativeTimeSpecVal;
  }

  interface LongDateFormatSpec {
    LTS: string;
    LT: string;
    L: string;
    LL: string;
    LLL: string;
    LLLL: string;

    // lets forget for a sec that any upper/lower permutation will also work
    lts?: string;
    lt?: string;
    l?: string;
    ll?: string;
    lll?: string;
    llll?: string;
  }

  type MonthWeekdayFn = (momentToFormat: Moment, format?: string) => string;
  type WeekdaySimpleFn = (momentToFormat: Moment) => string;
  interface EraSpec {
    since: string | number;
    until: string | number;
    offset: number;
    name: string;
    narrow: string;
    abbr: string;
  }

  interface LocaleSpecification {
    months?: string[] | StandaloneFormatSpec | MonthWeekdayFn;
    monthsShort?: string[] | StandaloneFormatSpec | MonthWeekdayFn;

    weekdays?: string[] | StandaloneFormatSpec | MonthWeekdayFn;
    weekdaysShort?: string[] | StandaloneFormatSpec | WeekdaySimpleFn;
    weekdaysMin?: string[] | StandaloneFormatSpec | WeekdaySimpleFn;

    meridiemParse?: RegExp;
    meridiem?: (hour: number, minute:number, isLower: boolean) => string;

    isPM?: (input: string) => boolean;

    longDateFormat?: LongDateFormatSpec;
    calendar?: CalendarSpec;
    relativeTime?: RelativeTimeSpec;
    invalidDate?: string;
    ordinal?: (n: number) => string;
    ordinalParse?: RegExp;

    week?: WeekSpec;
    eras?: EraSpec[];

    // Allow anything: in general any property that is passed as locale spec is
    // put in the locale object so it can be used by locale functions
    [x: string]: any;
  }

  interface MomentObjectOutput {
    years: number;
    /* One digit */
    months: number;
    /* Day of the month */
    date: number;
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds: number;
  }

  interface argThresholdOpts {
    ss?: number;
    s?: number;
    m?: number;
    h?: number;
    d?: number;
    w?: number | void;
    M?: number;
  }

  interface Duration {
    clone(): Duration;

    humanize(argWithSuffix?: boolean, argThresholds?: argThresholdOpts): string;
    
    humanize(argThresholds?: argThresholdOpts): string;

    abs(): Duration;

    as(units: unitOfTime.Base): number;
    get(units: unitOfTime.Base): number;

    milliseconds(): number;
    asMilliseconds(): number;

    seconds(): number;
    asSeconds(): number;

    minutes(): number;
    asMinutes(): number;

    hours(): number;
    asHours(): number;

    days(): number;
    asDays(): number;

    weeks(): number;
    asWeeks(): number;

    months(): number;
    asMonths(): number;

    years(): number;
    asYears(): number;

    add(inp?: DurationInputArg1, unit?: DurationInputArg2): Duration;
    subtract(inp?: DurationInputArg1, unit?: DurationInputArg2): Duration;

    locale(): string;
    locale(locale: LocaleSpecifier): Duration;
    localeData(): Locale;

    toISOString(): string;
    toJSON(): string;

    isValid(): boolean;

    /**
     * @deprecated since version 2.8.0
     */
    lang(locale: LocaleSpecifier): Moment;
    /**
     * @deprecated since version 2.8.0
     */
    lang(): Locale;
    /**
     * @deprecated
     */
    toIsoString(): string;
  }

  interface MomentRelativeTime {
    future: any;
    past: any;
    s: any;
    ss: any;
    m: any;
    mm: any;
    h: any;
    hh: any;
    d: any;
    dd: any;
    M: any;
    MM: any;
    y: any;
    yy: any;
  }

  interface MomentLongDateFormat {
    L: string;
    LL: string;
    LLL: string;
    LLLL: string;
    LT: string;
    LTS: string;

    l?: string;
    ll?: string;
    lll?: string;
    llll?: string;
    lt?: string;
    lts?: string;
  }

  interface MomentParsingFlags {
    empty: boolean;
    unusedTokens: string[];
    unusedInput: string[];
    overflow: number;
    charsLeftOver: number;
    nullInput: boolean;
    invalidMonth: string | void; // null
    invalidFormat: boolean;
    userInvalidated: boolean;
    iso: boolean;
    parsedDateParts: any[];
    meridiem: string | void; // null
  }

  interface MomentParsingFlagsOpt {
    empty?: boolean;
    unusedTokens?: string[];
    unusedInput?: string[];
    overflow?: number;
    charsLeftOver?: number;
    nullInput?: boolean;
    invalidMonth?: string;
    invalidFormat?: boolean;
    userInvalidated?: boolean;
    iso?: boolean;
    parsedDateParts?: any[];
    meridiem?: string;
  }

  interface MomentBuiltinFormat {
    __momentBuiltinFormatBrand: any;
  }

  type MomentFormatSpecification = string | MomentBuiltinFormat | (string | MomentBuiltinFormat)[];

  export namespace unitOfTime {
    type Base = (
      "year" | "years" | "y" |
      "month" | "months" | "M" |
      "week" | "weeks" | "w" |
      "day" | "days" | "d" |
      "hour" | "hours" | "h" |
      "minute" | "minutes" | "m" |
      "second" | "seconds" | "s" |
      "millisecond" | "milliseconds" | "ms"
    );

    type _quarter = "quarter" | "quarters" | "Q";
    type _isoWeek = "isoWeek" | "isoWeeks" | "W";
    type _date = "date" | "dates" | "D";
    type DurationConstructor = Base | _quarter | _isoWeek;

    export type DurationAs = Base;

    export type StartOf = Base | _quarter | _isoWeek | _date | void; // null

    export type Diff = Base | _quarter;

    export type MomentConstructor = Base | _date;

    export type All = Base | _quarter | _isoWeek | _date |
      "weekYear" | "weekYears" | "gg" |
      "isoWeekYear" | "isoWeekYears" | "GG" |
      "dayOfYear" | "dayOfYears" | "DDD" |
      "weekday" | "weekdays" | "e" |
      "isoWeekday" | "isoWeekdays" | "E";
  }

  type numberlike = number | string;
  interface MomentInputObject {
    years?: numberlike;
    year?: numberlike;
    y?: numberlike;

    months?: numberlike;
    month?: numberlike;
    M?: numberlike;

    days?: numberlike;
    day?: numberlike;
    d?: numberlike;

    dates?: numberlike;
    date?: numberlike;
    D?: numberlike;

    hours?: numberlike;
    hour?: numberlike;
    h?: numberlike;

    minutes?: numberlike;
    minute?: numberlike;
    m?: numberlike;

    seconds?: numberlike;
    second?: numberlike;
    s?: numberlike;

    milliseconds?: numberlike;
    millisecond?: numberlike;
    ms?: numberlike;
  }

  interface DurationInputObject extends MomentInputObject {
    quarters?: numberlike;
    quarter?: numberlike;
    Q?: numberlike;

    weeks?: numberlike;
    week?: numberlike;
    w?: numberlike;
  }

  interface MomentSetObject extends MomentInputObject {
    weekYears?: numberlike;
    weekYear?: numberlike;
    gg?: numberlike;

    isoWeekYears?: numberlike;
    isoWeekYear?: numberlike;
    GG?: numberlike;

    quarters?: numberlike;
    quarter?: numberlike;
    Q?: numberlike;

    weeks?: numberlike;
    week?: numberlike;
    w?: numberlike;

    isoWeeks?: numberlike;
    isoWeek?: numberlike;
    W?: numberlike;

    dayOfYears?: numberlike;
    dayOfYear?: numberlike;
    DDD?: numberlike;

    weekdays?: numberlike;
    weekday?: numberlike;
    e?: numberlike;

    isoWeekdays?: numberlike;
    isoWeekday?: numberlike;
    E?: numberlike;
  }

  interface FromTo {
    from: MomentInput;
    to: MomentInput;
  }

  type MomentInput = Moment | Date | string | number | (number | string)[] | MomentInputObject | void; // null | undefined
  type DurationInputArg1 = Duration | number | string | FromTo | DurationInputObject | void; // null | undefined
  type DurationInputArg2 = unitOfTime.DurationConstructor;
  type LocaleSpecifier = string | Moment | Duration | string[] | boolean;

  interface MomentCreationData {
    input: MomentInput;
    format?: MomentFormatSpecification;
    locale: Locale;
    isUTC: boolean;
    strict?: boolean;
  }

  interface Moment extends Object {
    format(format?: string): string;

    startOf(unitOfTime: unitOfTime.StartOf): Moment;
    endOf(unitOfTime: unitOfTime.StartOf): Moment;

    add(amount?: DurationInputArg1, unit?: DurationInputArg2): Moment;
    /**
     * @deprecated reverse syntax
     */
    add(unit: unitOfTime.DurationConstructor, amount: number|string): Moment;

    subtract(amount?: DurationInputArg1, unit?: DurationInputArg2): Moment;
    /**
     * @deprecated reverse syntax
     */
    subtract(unit: unitOfTime.DurationConstructor, amount: number|string): Moment;

    calendar(): string;
    calendar(formats: CalendarSpec): string;
    calendar(time: MomentInput, formats?: CalendarSpec): string;

    clone(): Moment;

    /**
     * @return Unix timestamp in milliseconds
     */
    valueOf(): number;

    // current date/time in local mode
    local(keepLocalTime?: boolean): Moment;
    isLocal(): boolean;

    // current date/time in UTC mode
    utc(keepLocalTime?: boolean): Moment;
    isUTC(): boolean;
    /**
     * @deprecated use isUTC
     */
    isUtc(): boolean;

    parseZone(): Moment;
    isValid(): boolean;
    invalidAt(): number;

    hasAlignedHourOffset(other?: MomentInput): boolean;

    creationData(): MomentCreationData;
    parsingFlags(): MomentParsingFlags;

    year(y: number): Moment;
    year(): number;
    /**
     * @deprecated use year(y)
     */
    years(y: number): Moment;
    /**
     * @deprecated use year()
     */
    years(): number;
    quarter(): number;
    quarter(q: number): Moment;
    quarters(): number;
    quarters(q: number): Moment;
    month(M: number|string): Moment;
    month(): number;
    /**
     * @deprecated use month(M)
     */
    months(M: number|string): Moment;
    /**
     * @deprecated use month()
     */
    months(): number;
    day(d: number|string): Moment;
    day(): number;
    days(d: number|string): Moment;
    days(): number;
    date(d: number): Moment;
    date(): number;
    /**
     * @deprecated use date(d)
     */
    dates(d: number): Moment;
    /**
     * @deprecated use date()
     */
    dates(): number;
    hour(h: number): Moment;
    hour(): number;
    hours(h: number): Moment;
    hours(): number;
    minute(m: number): Moment;
    minute(): number;
    minutes(m: number): Moment;
    minutes(): number;
    second(s: number): Moment;
    second(): number;
    seconds(s: number): Moment;
    seconds(): number;
    millisecond(ms: number): Moment;
    millisecond(): number;
    milliseconds(ms: number): Moment;
    milliseconds(): number;
    weekday(): number;
    weekday(d: number): Moment;
    isoWeekday(): number;
    isoWeekday(d: number|string): Moment;
    weekYear(): number;
    weekYear(d: number): Moment;
    isoWeekYear(): number;
    isoWeekYear(d: number): Moment;
    week(): number;
    week(d: number): Moment;
    weeks(): number;
    weeks(d: number): Moment;
    isoWeek(): number;
    isoWeek(d: number): Moment;
    isoWeeks(): number;
    isoWeeks(d: number): Moment;
    weeksInYear(): number;
    weeksInWeekYear(): number;
    isoWeeksInYear(): number;
    isoWeeksInISOWeekYear(): number;
    dayOfYear(): number;
    dayOfYear(d: number): Moment;

    from(inp: MomentInput, suffix?: boolean): string;
    to(inp: MomentInput, suffix?: boolean): string;
    fromNow(withoutSuffix?: boolean): string;
    toNow(withoutPrefix?: boolean): string;

    diff(b: MomentInput, unitOfTime?: unitOfTime.Diff, precise?: boolean): number;

    toArray(): number[];
    toDate(): Date;
    toISOString(keepOffset?: boolean): string;
    inspect(): string;
    toJSON(): string;
    unix(): number;

    isLeapYear(): boolean;
    /**
     * @deprecated in favor of utcOffset
     */
    zone(): number;
    zone(b: number|string): Moment;
    utcOffset(): number;
    utcOffset(b: number|string, keepLocalTime?: boolean): Moment;
    isUtcOffset(): boolean;
    daysInMonth(): number;
    isDST(): boolean;

    zoneAbbr(): string;
    zoneName(): string;

    isBefore(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
    isAfter(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
    isSame(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
    isSameOrAfter(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
    isSameOrBefore(inp?: MomentInput, granularity?: unitOfTime.StartOf): boolean;
    isBetween(a: MomentInput, b: MomentInput, granularity?: unitOfTime.StartOf, inclusivity?: "()" | "[)" | "(]" | "[]"): boolean;

    /**
     * @deprecated as of 2.8.0, use locale
     */
    lang(language: LocaleSpecifier): Moment;
    /**
     * @deprecated as of 2.8.0, use locale
     */
    lang(): Locale;

    locale(): string;
    locale(locale: LocaleSpecifier): Moment;

    localeData(): Locale;

    /**
     * @deprecated no reliable implementation
     */
    isDSTShifted(): boolean;

    // NOTE(constructor): Same as moment constructor
    /**
     * @deprecated as of 2.7.0, use moment.min/max
     */
    max(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean): Moment;
    /**
     * @deprecated as of 2.7.0, use moment.min/max
     */
    max(inp?: MomentInput, format?: MomentFormatSpecification, language?: string, strict?: boolean): Moment;

    // NOTE(constructor): Same as moment constructor
    /**
     * @deprecated as of 2.7.0, use moment.min/max
     */
    min(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean): Moment;
    /**
     * @deprecated as of 2.7.0, use moment.min/max
     */
    min(inp?: MomentInput, format?: MomentFormatSpecification, language?: string, strict?: boolean): Moment;

    get(unit: unitOfTime.All): number;
    set(unit: unitOfTime.All, value: number): Moment;
    set(objectLiteral: MomentSetObject): Moment;

    toObject(): MomentObjectOutput;
  }

  export var version: string;
  export var fn: Moment;

  // NOTE(constructor): Same as moment constructor
  /**
   * @param strict Strict parsing disables the deprecated fallback to the native Date constructor when
   * parsing a string.
   */
  export function utc(inp?: MomentInput, strict?: boolean): Moment;
  /**
   * @param strict Strict parsing requires that the format and input match exactly, including delimiters.
   * Strict parsing is frequently the best parsing option. For more information about choosing strict vs
   * forgiving parsing, see the [parsing guide](https://momentjs.com/guides/#/parsing/).
   */
  export function utc(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean): Moment;
  /**
   * @param strict Strict parsing requires that the format and input match exactly, including delimiters.
   * Strict parsing is frequently the best parsing option. For more information about choosing strict vs
   * forgiving parsing, see the [parsing guide](https://momentjs.com/guides/#/parsing/).
   */
  export function utc(inp?: MomentInput, format?: MomentFormatSpecification, language?: string, strict?: boolean): Moment;

  export function unix(timestamp: number): Moment;

  export function invalid(flags?: MomentParsingFlagsOpt): Moment;
  export function isMoment(m: any): m is Moment;
  export function isDate(m: any): m is Date;
  export function isDuration(d: any): d is Duration;

  /**
   * @deprecated in 2.8.0
   */
  export function lang(language?: string): string;
  /**
   * @deprecated in 2.8.0
   */
  export function lang(language?: string, definition?: Locale): string;

  export function locale(language?: string): string;
  export function locale(language?: string[]): string;
  export function locale(language?: string, definition?: LocaleSpecification | void): string; // null | undefined

  export function localeData(key?: string | string[]): Locale;

  export function duration(inp?: DurationInputArg1, unit?: DurationInputArg2): Duration;

  // NOTE(constructor): Same as moment constructor
  export function parseZone(inp?: MomentInput, format?: MomentFormatSpecification, strict?: boolean): Moment;
  export function parseZone(inp?: MomentInput, format?: MomentFormatSpecification, language?: string, strict?: boolean): Moment;

  export function months(): string[];
  export function months(index: number): string;
  export function months(format: string): string[];
  export function months(format: string, index: number): string;
  export function monthsShort(): string[];
  export function monthsShort(index: number): string;
  export function monthsShort(format: string): string[];
  export function monthsShort(format: string, index: number): string;

  export function weekdays(): string[];
  export function weekdays(index: number): string;
  export function weekdays(format: string): string[];
  export function weekdays(format: string, index: number): string;
  export function weekdays(localeSorted: boolean): string[];
  export function weekdays(localeSorted: boolean, index: number): string;
  export function weekdays(localeSorted: boolean, format: string): string[];
  export function weekdays(localeSorted: boolean, format: string, index: number): string;
  export function weekdaysShort(): string[];
  export function weekdaysShort(index: number): string;
  export function weekdaysShort(format: string): string[];
  export function weekdaysShort(format: string, index: number): string;
  export function weekdaysShort(localeSorted: boolean): string[];
  export function weekdaysShort(localeSorted: boolean, index: number): string;
  export function weekdaysShort(localeSorted: boolean, format: string): string[];
  export function weekdaysShort(localeSorted: boolean, format: string, index: number): string;
  export function weekdaysMin(): string[];
  export function weekdaysMin(index: number): string;
  export function weekdaysMin(format: string): string[];
  export function weekdaysMin(format: string, index: number): string;
  export function weekdaysMin(localeSorted: boolean): string[];
  export function weekdaysMin(localeSorted: boolean, index: number): string;
  export function weekdaysMin(localeSorted: boolean, format: string): string[];
  export function weekdaysMin(localeSorted: boolean, format: string, index: number): string;

  export function min(moments: Moment[]): Moment;
  export function min(...moments: Moment[]): Moment;
  export function max(moments: Moment[]): Moment;
  export function max(...moments: Moment[]): Moment;

  /**
   * Returns unix time in milliseconds. Overwrite for profit.
   */
  export function now(): number;

  export function defineLocale(language: string, localeSpec: LocaleSpecification | void): Locale; // null
  export function updateLocale(language: string, localeSpec: LocaleSpecification | void): Locale; // null

  export function locales(): string[];

  export function normalizeUnits(unit: unitOfTime.All): string;
  export function relativeTimeThreshold(threshold: string): number | boolean;
  export function relativeTimeThreshold(threshold: string, limit: number): boolean;
  export function relativeTimeRounding(fn: (num: number) => number): boolean;
  export function relativeTimeRounding(): (num: number) => number;
  export function calendarFormat(m: Moment, now: Moment): string;

  export function parseTwoDigitYear(input: string): number;

  /**
   * Constant used to enable explicit ISO_8601 format parsing.
   */
  export var ISO_8601: MomentBuiltinFormat;
  export var RFC_2822: MomentBuiltinFormat;

  export var defaultFormat: string;
  export var defaultFormatUtc: string;
  export var suppressDeprecationWarnings: boolean;
  export var deprecationHandler: ((name: string | void, msg: string) => void) | void;

  export var HTML5_FMT: {
    DATETIME_LOCAL: string,
    DATETIME_LOCAL_SECONDS: string,
    DATETIME_LOCAL_MS: string,
    DATE: string,
    TIME: string,
    TIME_SECONDS: string,
    TIME_MS: string,
    WEEK: string,
    MONTH: string
  };

}
`

const ObjectId = `
declare class ObjectId {
  _bsontype: 'ObjectID';
  /* Excluded from this release type: index */
  static cacheHexString: boolean;
  /* Excluded from this release type: [kId] */
  /* Excluded from this release type: __id */
  /**
   * Create an ObjectId type
   *
   * @param inputId - Can be a 24 character hex string, 12 byte binary Buffer, or a number.
   */
  constructor(inputId?: string | number | ObjectId);
  /*
  * The ObjectId bytes
  * @readonly
  */
  id: Buffer;
  /*
  * The generation time of this ObjectId instance
  * @deprecated Please use getTimestamp / createFromTime which returns an int32 epoch
  */
  generationTime: number;
  /** Returns the ObjectId id as a 24 character hex string representation */
  toHexString(): string;
  /* Excluded from this release type: getInc */
  /**
   * Generate a 12 byte id buffer used in ObjectId's
   *
   * @param time - pass in a second based timestamp.
   */
  static generate(time?: number): Buffer;
  /**
   * Converts the id into a 24 character hex string for printing
   *
   * @param format - The Buffer toString format parameter.
   */
  toString(format?: string): string;
  /** Converts to its JSON the 24 character hex string representation. */
  toJSON(): string;
  /**
   * Compares the equality of this ObjectId with otherID.
   *
   * @param otherId - ObjectId instance to compare against.
   */
  equals(otherId: string | ObjectId | ObjectIdLike): boolean;
  /** Returns the generation date (accurate up to the second) that this ID was generated. */
  getTimestamp(): Date;
  /* Excluded from this release type: createPk */
  /**
   * Creates an ObjectId from a second based number, with the rest of the ObjectId zeroed out. Used for comparisons or sorting the ObjectId.
   *
   * @param time - an integer number representing a number of seconds.
   */
  static createFromTime(time: number): ObjectId;
  /**
   * Creates an ObjectId from a hex string representation of an ObjectId.
   *
   * @param hexString - create a ObjectId from a passed in 24 character hexstring.
   */
  static createFromHexString(hexString: string): ObjectId;
  /**
   * Checks if a value is a valid bson ObjectId
   *
   * @param id - ObjectId instance to validate.
   */
  static isValid(id: string | number | ObjectId | ObjectIdLike | Buffer | Uint8Array): boolean;
  /* Excluded from this release type: toExtendedJSON */
  /* Excluded from this release type: fromExtendedJSON */
  inspect(): string;
}
`

const Cache = `

interface Snippet {
  _id: ObjectId;
  name: string;
  code: string;
  type: string;
  subtype: string;
  fnName: string;
}

interface Engine {
  _id: ObjectId;
  name: string;
  payload: Record<string, unknown>;
}

interface Fact {
  _id: ObjectId;
  key: string;
  code: string;
  engine: ObjectId | Engine;
}

interface CacheInstance<T> {
  list(): Array<T>;
  get(key: string): T;
  put((key: string, value: T, time: number, timeoutCallback: () => void): void;
  del(key: string): void;
  clear(): void;
  
  debug(): boolean;
  keys(): Array<string>;
  size(): number;
  memsize(): number;

  hits(): number;
  misses(): number;
}

declare const Cache: {
  domain: {
    snippet: CacheInstance<Snippet>;
    fact: CacheInstance<Fact>;
  };
};

`

export default [axios, bcrypt, currency, moment, ObjectId, Cache].join('\n\n')
