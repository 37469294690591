import React from 'react'
import DatePicker from './DatePicker'
import moment, {Moment} from 'moment-timezone'
import {IntRange} from 'rc-picker/lib/interface'

interface TimeDatePickerProps {
  value?: Moment | null
  onTime?: (time: {hours: number; minutes: number}) => void
  format?: string
  onChange?: (date: Moment | null, dateString: string | string[]) => void
  onFocus?: () => void
  disabled?: boolean
  hourStep?: IntRange<1, 23> | undefined
  minuteStep?: IntRange<1, 59> | undefined
}

const TimeDatePicker: React.FC<TimeDatePickerProps> = ({
  value,
  onTime,
  format = 'YYYY-MM-DD HH:mm',
  disabled = false,
  onChange,
  onFocus,
  hourStep,
  minuteStep
}) => {
  const handleDateTimeChange = (date: Moment | null, dateString: string | string[]) => {
    if (date && onTime) {
      const time = {
        hours: date.hour(),
        minutes: date.minute()
      }
      onTime(time)
    }
    if (onChange) {
      onChange(date, dateString)
    }
  }

  const datePickerProps = {
    value: value ? moment(value, [format, moment.ISO_8601]) : null,
    showTime: true,
    format: format,
    onChange: handleDateTimeChange
  }

  return (
    <DatePicker
      className='w-100'
      {...datePickerProps}
      onFocus={onFocus}
      disabled={disabled}
      hourStep={hourStep}
      minuteStep={minuteStep}
    />
  )
}

TimeDatePicker.defaultProps = {
  // for the moment we don't use this prop
  onTime: (time: {hours: number; minutes: number}) => console.log(time),
  disabled: false
}

export default TimeDatePicker
