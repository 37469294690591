import * as Yup from 'yup'
import React from 'react'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'
import WorkflowBuilder from '@components/workflow-builder/workflow-builder'
import Workflow from '@components/workflow-builder/workflow-builder'
import {FormObject} from '@components/forms/form.t'
import {NodeType} from '@components/workflow-builder/nodes/node-config'
import FormItem from '../form-item/form-item'

interface WorkflowInputComponentProps {
  _id: string
  key: string
  label: string
  name: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

const WorkflowInputComponent: React.FC<FieldComponentProps<WorkflowInputComponentProps>> = ({
  field
}) => {
  const formProps = useFormikContext<FormObject>()
  const [formikField] = useField<Workflow>({...field})

  const onChange = (value: Workflow) => {
    formProps.setFieldValue(formikField.name, value)
  }

  const availableNodes = (formProps.values.engine as FormObject)?.availableNodes || []

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      <div className='workflow-container'>
        <WorkflowBuilder
          disabled={field.disabled}
          data={formikField.value}
          onChange={onChange}
          filterNodes={availableNodes as NodeType[]}
        />
      </div>
      <style>{`
        .workflow-container {
          height: 700px;
          display: block;
        }
      `}</style>
    </FormItem>
  )
}

const WorkflowInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema = Yup.object()

      if (field.required) schema = schema.required()

      return {[field.key]: schema.nonNullable()}
    },
    generateComponent() {
      return <WorkflowInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default WorkflowInput
