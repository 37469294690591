import {Button, Col} from 'antd'
import {FormLayoutRowWithUniqIds} from './layout-input.t'
import LayoutRow from './layout-row'
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {PlusCircleOutlined} from '@ant-design/icons'
import {useActionContext} from './ActionContext'
import React from 'react'

interface LayoutRowsProps {
  rows: FormLayoutRowWithUniqIds[]
}

export default function LayoutRows({rows}: LayoutRowsProps) {
  const actionContext = useActionContext()

  if (!rows || !rows.length) {
    return (
      <Button onClick={() => actionContext.addRow(0)} className='layout-row-add-button'>
        <PlusCircleOutlined />
      </Button>
    )
  }

  return (
    <SortableContext
      items={rows.map((r) => r.id)}
      strategy={verticalListSortingStrategy}
      id={'rows'}>
      <Col className='layout-rows'>
        {rows.map((row, index) => (
          <React.Fragment key={row.id}>
            <LayoutRow row={row} />
            <div className='layout-row-add'>
              <Button onClick={() => actionContext.addRow(index)} className='layout-row-add-button'>
                <PlusCircleOutlined />
              </Button>
            </div>
          </React.Fragment>
        ))}
      </Col>
    </SortableContext>
  )
}
