import {CheckCircleFilled, InfoCircleOutlined, RollbackOutlined} from '@ant-design/icons'
import {PanelsKeys} from '@libs/panels/panels.t'
import {AppContext, withApp} from '@store/app'
import {
  Alert,
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider,
  Divider,
  List,
  Row,
  Space,
  theme,
  Typography
} from 'antd'
import React, {useCallback, useMemo} from 'react'
import {Cart, ListItem} from './cartValidation.t'
import {saveDiff} from '@libs/utils'
import {ErpDomain} from '@shared/interfaces'
import {usePatchMutation} from '@queries'
import {FormObject} from '@components/forms/form.t'

const {useToken} = theme

const {Text, Title} = Typography

export interface ProjectValidationProps extends AppContext {
  setStep: (step: number) => void
  places: ListItem[]
  suppliers: ListItem[]
  cart: Cart
}

const CardItem = ({title, description}: {title: string; description: string}) => {
  return (
    <Col className='px-3 py-2'>
      <div>
        <Text strong>{title}</Text>
      </div>
      <div>
        <Text type='secondary'>{description}</Text>
      </div>
    </Col>
  )
}

const ProjectValidation: React.FC<ProjectValidationProps> = ({
  t,
  toast,
  CRM,
  cart,
  Panels,
  modal,
  setStep,
  places = [],
  suppliers = []
}: ProjectValidationProps) => {
  const {token} = useToken()

  const cartDomain = useMemo<ErpDomain | undefined>(
    () => CRM?.getDomain('cart') as ErpDomain,
    [CRM]
  )

  const patchItemMutation = usePatchMutation()

  const onCancel = useCallback(() => {
    modal.confirm({
      title: (
        <Row justify='center'>
          <Title level={4}>{t('panel.cartValidation.steps.project.cancel.modal.title')}</Title>
        </Row>
      ),
      content: (
        <Col className='mx-3 mb-4'>
          <Row className='px-3 my-2'>
            <Text strong className='pre-line'>
              {t('panel.cartValidation.steps.project.cancel.modal.content')}
            </Text>
          </Row>
          <List split={false} size='small'>
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size='small'>1</Avatar>}
                title={t('panel.cartValidation.steps.project.cancel.modal.list1')}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size='small'>2</Avatar>}
                title={t('panel.cartValidation.steps.project.cancel.modal.list2')}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar size='small'>3</Avatar>}
                title={t('panel.cartValidation.steps.project.cancel.modal.list3')}
              />
            </List.Item>
          </List>
        </Col>
      ),
      icon: null,
      onOk: () => {
        if (!cartDomain) return

        const diff = saveDiff(
          cartDomain,
          {...(cart as unknown as FormObject)},
          {...(cart as unknown as FormObject), isAbandoned: true}
        )
        return patchItemMutation
          .mutateAsync(
            {domain: cartDomain, id: cart._id, patchs: diff},
            {
              onSuccess: () => {
                Panels.close(PanelsKeys.CART_VALIDATION)
                toast.success('panel.cartValidation.steps.project.cancel.modal.success')
              }
            }
          )
          .catch((err) => {
            const errorMessage = err.response?.data?.data?.err || 'action-bar.saveItemError'
            toast.error(errorMessage)
          })
      },
      width: 600,
      styles: {
        body: {
          padding: '2rem'
        }
      }
    })
  }, [Panels, cart, cartDomain, modal, patchItemMutation, t, toast])

  return (
    <Col className='mt-4 hidden-scroll-container' style={{maxHeight: '670px', overflow: 'scroll'}}>
      <Space direction='vertical' size='middle' className='w-100'>
        <ConfigProvider
          theme={{
            token: {
              colorText: token.colorWarning,
              fontSize: 14
            }
          }}>
          <Alert
            description={
              <Text className='pre-line'>
                {t('panel.cartValidation.steps.project.alert.description')}
              </Text>
            }
            type='warning'
            icon={<InfoCircleOutlined />}
            showIcon
            style={{alignItems: 'center'}}
          />
        </ConfigProvider>

        {!!places.length && (
          <Card
            title={t('panel.cartValidation.steps.project.places')}
            styles={{
              header: {
                color: '#747578',
                fontSize: '1.1rem',
                background: '#D6DAE2',
                paddingBottom: '5px'
              },
              body: {
                padding: '0px',
                overflow: 'hidden'
              }
            }}>
            {places.map((item, index) => (
              <React.Fragment key={`cartValidation.places.${index}`}>
                <CardItem title={item.title} description={item.description} />
                {index < places.length - 1 && <Divider className='mx-3 my-0' />}
              </React.Fragment>
            ))}
          </Card>
        )}

        {!!suppliers.length && (
          <Card
            title={t('panel.cartValidation.steps.project.suppliers')}
            styles={{
              header: {
                color: '#935A55',
                fontSize: '1.1rem',
                background: '#E6DAD8',
                paddingBottom: '5px'
              },
              body: {
                padding: '0px',
                overflow: 'hidden'
              }
            }}>
            {suppliers.map((item, index) => (
              <React.Fragment key={`cartValidation.suppliers.${index}`}>
                <CardItem title={item.title} description={item.description} />
                {index < places.length - 1 && <Divider className='mx-3 my-0' />}
              </React.Fragment>
            ))}
          </Card>
        )}
        <Row justify='end'>
          <Space direction='horizontal'>
            <Button type='default' onClick={onCancel} icon={<RollbackOutlined />}>
              {t('panel.cartValidation.steps.project.cancel.button')}
            </Button>
            <Button type='default' onClick={() => setStep(1)} icon={<CheckCircleFilled />}>
              {t('panel.cartValidation.steps.project.next')}
            </Button>
          </Space>
        </Row>
      </Space>

      <style>{`
        .ant-alert-message {
          margin-bottom: 0px !important;
        }

        .ant-list-item-meta-avatar {
          margin-inline-end: 8px !important;
        }
      `}</style>
    </Col>
  )
}

export default withApp(ProjectValidation)
