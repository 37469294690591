import {DomainKey} from '@shared/interfaces'
import {useState} from 'react'

export interface ViewManager {
  tab?: string
  setTab: (tab?: string) => void
  viewMode: 'json' | 'form'
  setViewMode: (viewMode: 'json' | 'form') => void
  toggleViewMode: () => void
  tabs?: DomainKey[]
  setTabs: (tabs?: DomainKey[]) => void
}

export const useViewManager = (initialTab?: string): ViewManager => {
  const [tab, setTab] = useState<string | undefined>(initialTab)
  const [tabs, setTabs] = useState<DomainKey[] | undefined>([])
  const [viewMode, setViewMode] = useState<'json' | 'form'>('form')

  const toggleViewMode = () => {
    setViewMode(viewMode === 'form' ? 'json' : 'form')
  }

  return {
    tab,
    setTab,
    viewMode,
    setViewMode,
    tabs,
    setTabs,
    toggleViewMode
  }
}
