import {
  DeleteFilled,
  EditFilled,
  MoreOutlined,
  ShoppingCartOutlined,
  SwapOutlined
} from '@ant-design/icons'
import {PanelsKeys} from '@libs/panels/panels.t'
import {fetchGetByIdQuery, invalidateDomainQueries, useRemoveMutation} from '@queries'
import {PRODUCT_FINDER_ACTION_ID, useInmemoriServices} from '@services'
import {AppContext, withApp} from '@store/app'
import {useQueryClient} from '@tanstack/react-query'
import {Button, ConfigProvider, Dropdown, Flex, MenuProps, Space, theme, Typography} from 'antd'
import React, {useCallback, useMemo} from 'react'
import {CartDomain, CartItemDomain, CartItemStatus} from './cart.t'
import {useDomainByKey} from '@hooks/use-domain'
import {ErpDomain} from '@shared/interfaces'

const {Text} = Typography
const {useToken} = theme

interface CartArticleActionsProps extends AppContext {
  cart: CartDomain
  cartItem: CartItemDomain
}

const CartArticleActions: React.FC<CartArticleActionsProps> = ({
  cart,
  cartItem,
  toast,
  Panels,
  ability,
  CRM,
  t
}: CartArticleActionsProps) => {
  const {token} = useToken()

  const {status, searchContext, price} = cartItem
  const searchProduct = searchContext?.product

  const {erpApiService} = useInmemoriServices()
  const queryClient = useQueryClient()

  const onSave = async (id?: string) => {
    try {
      await invalidateDomainQueries(queryClient, 'carts', id)
    } catch (e) {
      console.error('edit error', e)
    }
  }

  const edit = async () => {
    try {
      const cartData = await fetchGetByIdQuery(
        'cartItems',
        cartItem._id,
        erpApiService,
        queryClient,
        {api: {hydrate: true}}
      )

      Panels.toggle(PanelsKeys.FORM_EDIT, {
        domain: 'cartItem',
        data: cartData,
        onSave
      })
    } catch (err) {
      console.error('Error while fetching cartItem', err)
    }
  }

  const cartItemDomain = useDomainByKey('cartItem')
  const removeMutation = useRemoveMutation()

  const remove = async () => {
    cartItemDomain &&
      removeMutation
        .mutateAsync(
          {domain: cartItemDomain, id: cartItem._id},
          {
            onSuccess() {
              onSave(cart._id)
              toast.success('cart-widget.actions.success')
            }
          }
        )
        .catch((err) => {
          const errorMessage = err.response?.data?.data?.err || 'cart-widget.actions.error'
          toast.error(errorMessage)
        })
  }

  const swap = async () => {
    Panels.toggle(PanelsKeys.PRODUCT_FINDER, {
      action: PRODUCT_FINDER_ACTION_ID,
      cart,
      cartItem,
      searchContext,
      replaceAction: true
    })
  }

  const productFinderAction = useMemo(() => {
    return (CRM?.getDomain('cart') as ErpDomain).actions.find((a) => a.key === 'productFinder')
  }, [CRM])

  const onMenuClick = (e: {key: string}) => {
    switch (e.key) {
      case 'edit':
        edit()
        break
      case 'delete':
        remove()
        break
      case 'swap':
        swap()
        break
    }
  }

  const actionMenu = useMemo(() => {
    const items: MenuProps['items'] = []

    if (status === CartItemStatus.APPROVED && ability?.can('crud.update', cartItem)) {
      items.push({
        key: 'edit',
        label: t('cart-widget.edit'),
        icon: <EditFilled />
      })
    }

    if (
      [CartItemStatus.APPROVED, CartItemStatus.PENDING_SUPPLIER].includes(status) &&
      searchProduct &&
      productFinderAction &&
      (ability?.can(`action.${productFinderAction._id}`, 'cart') ||
        ability?.can('action.all', 'cart'))
    ) {
      items.push({
        key: 'swap',
        label: t('cart-widget.swap'),
        icon: <SwapOutlined />
      })
    }

    if (ability?.can('crud.delete', cartItem)) {
      items.push({
        key: 'delete',
        label: t('cart-widget.delete'),
        icon: <DeleteFilled />,
        danger: true
      })
    }

    return items
  }, [ability, cartItem, productFinderAction, searchProduct, status, t])

  const shouldResolve = useMemo(() => {
    const productFinderAction = (CRM?.getDomain('cart') as ErpDomain).actions.find(
      (a) => a.key === 'productFinder'
    )

    if (
      status === CartItemStatus.APPROVED ||
      status === CartItemStatus.MISSING ||
      !productFinderAction ||
      ability?.cannot(`action.${productFinderAction._id}`, 'cart') ||
      cart.locked ||
      cart.isAbandoned
    )
      return false

    return true
  }, [CRM, ability, cart.isAbandoned, cart.locked, status])

  const resolve = useCallback(() => {
    Panels.toggle(PanelsKeys.PRODUCT_FINDER, {
      action: PRODUCT_FINDER_ACTION_ID,
      cart,
      cartItem,
      searchContext
    })
  }, [Panels, cart, cartItem, searchContext])

  return (
    <Flex vertical gap='small' align='flex-end' justify='space-between'>
      {!!price && (
        <Text strong style={{wordBreak: 'keep-all'}}>
          {price.string.ttc === '0,00€' ? 'OFFERT' : price.string.ttc}
        </Text>
      )}

      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultGhostColor: token.colorText,
              defaultGhostBorderColor: '#e6e6e6',
              defaultHoverBorderColor: token.colorText,
              defaultHoverColor: token.colorText
            }
          }
        }}>
        <Space.Compact>
          {shouldResolve && (
            <Button
              type='default'
              size='small'
              ghost
              onClick={resolve}
              icon={<ShoppingCartOutlined />}>
              {status === CartItemStatus.PENDING_SALE
                ? t('cart-widget.resolve')
                : t('cart-widget.resolveSupplier')}
            </Button>
          )}
          {!(cart.locked || cart.isAbandoned) && !!actionMenu.length && (
            <Dropdown menu={{items: actionMenu, onClick: onMenuClick}} placement='bottomLeft' arrow>
              <Button type='default' size='small' ghost icon={<MoreOutlined />} />
            </Dropdown>
          )}
        </Space.Compact>
      </ConfigProvider>
    </Flex>
  )
}

export default withApp(CartArticleActions)
