import {UseQueryResult} from '@tanstack/react-query'
import {Card, Skeleton} from 'antd'
import React from 'react'

interface LoadingProps {
  query?: UseQueryResult
}

const Loading: React.FC<LoadingProps> = ({query}) => {
  return query?.isLoading ? (
    <Card>
      <Skeleton active />
    </Card>
  ) : null
}

export default Loading
