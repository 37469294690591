import {Loader} from '@googlemaps/js-api-loader'

const initGoogleMaps = () => {
  const loader = new Loader({
    apiKey: process.env.GMAP_KEY || process.env.NEXT_PUBLIC_GMAP_KEY || '',
    version: 'weekly'
  })

  loader.importLibrary('places')
}

export default initGoogleMaps
