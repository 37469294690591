import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {EntityType} from './layout-input.t'
import {Button, Row} from 'antd'
import {useActionContext} from './ActionContext'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface LayoutFieldProps {
  id: string
  data?: object
  label: string
  type: EntityType
  showDelete: boolean
}

export default function LayoutField({id, data, label, type, showDelete}: LayoutFieldProps) {
  const actionContext = useActionContext()
  const {setNodeRef, listeners, attributes, transform, isDragging} = useSortable({
    id,
    data: {
      ...data,
      type
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    ...(isDragging && {opacity: 0})
  }

  return (
    <Row className='layout-field' key={id} id={id} ref={setNodeRef} style={style}>
      <div className='layout-handle' {...listeners} {...attributes}>
        {label}
      </div>
      {showDelete && (
        <Button onClick={() => actionContext.deleteComponent(id)}>
          <FontAwesomeIcon icon={'trash'} />
        </Button>
      )}
    </Row>
  )
}
