import {PlusCircleOutlined} from '@ant-design/icons'
import {Button, Col, Row} from 'antd'
import React from 'react'

interface CollapseHeaderProps {
  label?: string
  add?: boolean
  onAdd?: () => void
}

const CollapseHeader: React.FC<CollapseHeaderProps> = ({label, add, onAdd}) => (
  <Row>
    {label && <Col flex='auto'>{label}</Col>}
    {!!add && (
      <Col flex='none'>
        <Button
          type='dashed'
          shape='round'
          icon={<PlusCircleOutlined />}
          size={'small'}
          onClick={(event) => {
            onAdd && onAdd()
            event.stopPropagation()
          }}>
          Ajouter
        </Button>
      </Col>
    )}
  </Row>
)

export default CollapseHeader
