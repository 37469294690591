import React, {useState} from 'react'
import PlacesAutocomplete, {Suggestion} from 'react-places-autocomplete'
import {Input, Menu} from 'antd'
import geo from '@libs/helpers/geo'

export interface SearchOptions {
  types?: string[]
  componentRestrictions?: {
    country: string[]
  }
}

export interface LocationObject {
  address: string
  fullAddress: string
  zipcode?: number
  city?: string
  country?: string
  latlng?: {
    lat: number
    lng: number
    string: string
  }
  geocode?: {
    latlng?: string
    geo?: any
    place_id?: string
    address?: string
    types?: string[]
    street_number?: string
    street?: string
    city?: string
    state?: string
    region?: string
    country?: string
    country_code?: string
    zipcode?: string
  }
  tz?: string
  info?: string
}

interface PlaceLocationInputProps {
  value: string
  onSelect: (address: LocationObject) => void
  onChange: (address: string) => void
  placeholder?: string
  searchOptions: SearchOptions
  disabled?: boolean
}

let throttle: NodeJS.Timeout | ''

const PlaceLocationInput: React.FC<PlaceLocationInputProps> = ({
  value,
  onSelect,
  onChange,
  placeholder,
  searchOptions,
  disabled = false
}) => {
  const [suggestionMenuOpen, setSuggestionMenuOpen] = useState(false)

  const handleChange = (address: string) => {
    onChange && onChange(address)
  }

  const handleSelect = async (address: string, placeId: string) => {
    // backward compatibility: old notion of address
    const address_short = (address || '').split(',')[0]

    if (throttle) clearTimeout(throttle)

    // append latlng with throttle
    throttle =
      (address || placeId) &&
      setTimeout(async () => {
        try {
          const location = await geo.geocode(placeId ? {placeId} : {address})

          const result = {
            address: address_short,
            fullAddress: address,
            latlng: {...geo.LLToObject(location.latlng), string: location.latlng},
            geocode: {...location},
            tz: await geo.timezone(location.latlng)
          }

          onSelect && onSelect(result)
        } catch (e) {
          console.log(e)
        }
      }, 500)
  }

  return (
    <PlacesAutocomplete
      value={value || ''}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      onChange={handleChange}>
      {({getInputProps, suggestions, getSuggestionItemProps}) => {
        const inputProps = getInputProps({
          placeholder: placeholder,
          className: 'location-input-searchbar'
        })

        return (
          <div>
            <Input
              {...inputProps}
              disabled={disabled}
              onBlur={(e) => {
                setSuggestionMenuOpen(false)
                inputProps.onBlur(e)
              }}
              onFocus={() => setSuggestionMenuOpen(true)}
            />

            {suggestionMenuOpen && (
              <Menu>
                {suggestions.map((suggestion: Suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  const itemProps = getSuggestionItemProps(suggestion, {
                    className
                  })
                  return (
                    <Menu.Item
                      {...itemProps}
                      onClick={(infos) =>
                        itemProps.onClick(infos.domEvent as React.MouseEvent<Element, MouseEvent>)
                      }
                      onMouseEnter={(infos) =>
                        itemProps.onMouseEnter(
                          infos.domEvent as React.MouseEvent<Element, MouseEvent>
                        )
                      }
                      onMouseLeave={(infos) =>
                        itemProps.onMouseLeave(
                          infos.domEvent as React.MouseEvent<Element, MouseEvent>
                        )
                      }
                      key={suggestion.placeId}>
                      {suggestion.description}
                    </Menu.Item>
                  )
                })}
              </Menu>
            )}
          </div>
        )
      }}
    </PlacesAutocomplete>
  )
}

export default PlaceLocationInput
