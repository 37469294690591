import React from 'react'
import {Crud, CustomRenderResult, RenderResult} from './table.t'
import {Checkbox, Tag, TagProps} from 'antd'
import {CheckboxProps} from 'antd/lib/checkbox'
import {ErpTable} from '@shared/interfaces'
import {ColumnType} from 'antd/lib/table'
import Exec from '@libs/exec'
import {FormObject} from '@components/forms/form.t'

const cellComponentResolver = (
  component: 'tag' | 'checkbox',
  {props, value}: RenderResult,
  text: string
) => {
  switch (component) {
    case 'tag':
      return <Tag {...(props as TagProps)}>{value || text}</Tag>
    case 'checkbox':
      return <Checkbox {...(props as CheckboxProps)} checked={Boolean(value)} />
    default:
      return <span>{value || text}</span>
  }
}

export const resolveColumns = async (
  table: ErpTable,
  setColumns: React.Dispatch<ColumnType<FormObject>[]>,
  actionsColumn: ColumnType<FormObject> | null,
  crud?: Crud
) => {
  try {
    const resolvedColumns = await Promise.all(
      table.resolvedColumns.map(async ({key, dataIndex, title, render: renderCode}) => {
        const result: ColumnType<FormObject> = {
          key,
          title,
          dataIndex: dataIndex || key
        }

        if (renderCode) {
          const {component, render}: CustomRenderResult =
            (await Exec(renderCode, {
              crud
            })) || {}
          result.render = (text: string, record: FormObject, index: number) => {
            return cellComponentResolver(component, render(text, record, index), text)
          }
        }

        return result
      })
    )

    setColumns([...resolvedColumns, ...(actionsColumn ? [actionsColumn] : [])])
  } catch (e) {
    console.error('failed to resolve columns', e)
  }
}
