import {useState} from 'react'
import {Switch} from 'antd'

export const houseSwitchKey = 'globalAccess'

const HouseSwitch = () => {
  const [checkedValue, setCheckedValue] = useState(localStorage.getItem(houseSwitchKey) === 'true')
  const onChange = () => {
    const newValue = !checkedValue

    setCheckedValue(newValue)
    localStorage.setItem(houseSwitchKey, newValue.toString())

    window?.SM?.sub?.refresh('project')
  }

  return (
    <>
      <div className='navbar-switch'>
        <span className='navbar-switch-text'>Voir toutes les maisons</span>
        <Switch defaultChecked={checkedValue} onChange={onChange} />
      </div>
    </>
  )
}

export default HouseSwitch
