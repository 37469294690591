import resolveLabel from '@libs/helpers/resolve-label'
import {Form} from 'antd'
import {useField} from 'formik'
import React, {CSSProperties, PropsWithChildren, useMemo} from 'react'

export interface Field {
  hideLabel?: boolean
  required?: boolean
  label: string
  tooltip?: string
  name: string
}

interface FormItemProps extends PropsWithChildren {
  field: Field
  style?: CSSProperties
  className?: string
  hasFeedback?: boolean
  extra?: React.ReactNode
}

interface FormItemValidationStatus {
  validateStatus?: 'success' | 'warning' | 'error' | 'validating' | ''
  help?: React.ReactNode
}

const FormItem: React.FunctionComponent<FormItemProps> = ({
  field,
  style,
  className,
  children,
  hasFeedback,
  extra
}: FormItemProps) => {
  const [, meta] = useField<string | number>(field)

  const validationStatus = useMemo<FormItemValidationStatus>(() => {
    return {
      validateStatus: meta.error ? 'error' : 'success',
      help: meta.error
    }
  }, [meta.error])

  return (
    <Form.Item
      label={resolveLabel(field)}
      name={field.name}
      style={style}
      className={className}
      hasFeedback={hasFeedback}
      extra={extra}
      {...validationStatus}>
      {children}
    </Form.Item>
  )
}

export default FormItem
