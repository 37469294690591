import React, {useMemo} from 'react'
import DefaultHandle from '../handles/default-handle'
import {WorkflowContext, withWorkflow} from '../utils/useWorkFlow'
import {NodeConfigs, NodeData, NodeType} from './node-config'
import {NodeProps} from 'react-flow-renderer/nocss'

interface DefaultNodeProps extends NodeProps<NodeData>, WorkflowContext {}

function DefaultNode({
  id,
  type,
  data,
  sourcePosition,
  targetPosition,
  onNodeDelete,
  onNodeConfig,
  onNodeCopy,
  selectedNode
}: DefaultNodeProps) {
  const isSelected = useMemo(() => selectedNode?.id === id, [selectedNode, id])
  const nodeConfig = useMemo(() => NodeConfigs[type], [type])

  return (
    <React.Fragment>
      <div
        className={`node default-node ${
          isSelected ? 'node-selected' : ''
        } d-flex flex-column justify-content-start`}
        style={
          {
            width: `${nodeConfig.size.width}px`,
            height: `${nodeConfig.size.height}px`,
            '--node-color': nodeConfig.color
          } as React.CSSProperties
        }>
        <div className='d-flex flex-row justify-content-between w-100'>
          <div className='node-type'>
            {nodeConfig.name} {data.optional === true && <span>(Optional)</span>}
          </div>
          {isSelected && (
            <div className='d-flex flex-row node-actions ms-auto align-items-center'>
              <i
                onClick={(event) => {
                  event.stopPropagation()
                  onNodeConfig?.(id)
                }}
                className='cursor ms-1 d-block node-type fas fa-cog cursor-pointer'></i>
              <i
                onClick={(event) => {
                  event.stopPropagation()
                  onNodeCopy?.(id)
                }}
                className='ms-1 d-block cursor node-type fas fa-copy cursor-pointer'></i>
              <i
                onClick={(event) => {
                  event.stopPropagation()
                  onNodeDelete?.(id, type as NodeType)
                }}
                className='ms-1 d-block cursor node-type fas fa-trash-alt cursor-pointer'></i>
            </div>
          )}
        </div>
        <div className={`${!data.content ? 'mt-2' : 'mt-1'} node-label`}>{data.label}</div>
        {!!data.content && <div className='mt-1 node-content'>{data.content}</div>}
      </div>
      {targetPosition && <DefaultHandle type='target' position={targetPosition} />}
      {sourcePosition && <DefaultHandle type='source' position={sourcePosition} />}
    </React.Fragment>
  )
}

export default withWorkflow(DefaultNode)
