import React, {createContext, useContext, useState} from 'react'
import {CategoryLevel, GlobalChecked, TasksListState} from './tm.t'
import TaskManagementSection from './tm-section'
import TaskManagementHelper from './tm-helper'

interface TasksListProps {
  list: CategoryLevel[]
  old?: boolean
  section: 'active' | 'disabled' | 'obsolete'
}

const TasksListContext = createContext<TasksListState>({
  collapseActiveKeys: []
})

export const useTasksListContext = () => useContext(TasksListContext)

const TasksList: React.FC<TasksListProps> = ({list, old, section}) => {
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[] | string>([])
  const [globalChecked, setGlobalChecked] = useState<GlobalChecked>({})

  const context: TasksListState = {
    old,
    section,
    collapseActiveKeys,
    setCollapseActiveKeys,
    globalChecked,
    setGlobalChecked
  }

  return (
    <TasksListContext.Provider value={{...context}}>
      {list &&
        list.map((categoryItem: CategoryLevel) => (
          <TaskManagementSection
            key={TaskManagementHelper.getKey(categoryItem._id, section)}
            name={categoryItem.name}
            sections={categoryItem.sections}
          />
        ))}
    </TasksListContext.Provider>
  )
}

export default TasksList
