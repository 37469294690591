import {FilterFilled, FilterOutlined} from '@ant-design/icons'
import {FormObject} from '@components/forms/form.t'
import {useApp} from '@store/app'
import {Drawer} from 'antd'
import {useMemo, useState} from 'react'
import FormFilter from './form-filter'

function cleanUpFilters(filters?: FormObject): FormObject {
  if (!filters) return {}

  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (value === undefined || value === null || value === '') return acc
    if (Array.isArray(value) && !value.length) return acc
    if (typeof value === 'object' && !Object.keys(value).length) return acc

    return {...acc, [key]: value}
  }, {})
}

function isDefaultFormObject(formObject?: FormObject, defaultFormObject?: FormObject): boolean {
  if (!formObject) return true
  if (!defaultFormObject) return true

  return Object.entries(formObject).every(([key, value]) => {
    return value === defaultFormObject[key]
  })
}

export interface FilterButtonProps {
  filterFormId: string
  onFilter?: (values: FormObject) => void
}

const FilterButton = (props: FilterButtonProps) => {
  const {filterFormId, onFilter} = props
  const [open, setOpen] = useState(false)

  const {t} = useApp()
  const filterTitle = t('components.lookup-component.filter-button.filters')

  const [formFilters, setFormFilters] = useState<FormObject | undefined>(undefined)
  const [defaultFormFilters, setDefaultFormFilters] = useState<FormObject | undefined>(undefined)

  const formFilterProps = useMemo(
    () => ({
      filterFormId,
      initialFilters: formFilters,
      onFilter: (
        cleanFilters: FormObject,
        formFilters?: FormObject,
        defaultFormFilters?: FormObject
      ) => {
        if (onFilter) onFilter(cleanFilters)

        setOpen(false)

        setFormFilters(formFilters)
        setDefaultFormFilters(defaultFormFilters)
      }
    }),
    [filterFormId, formFilters, onFilter]
  )

  const areFiltersActive = useMemo(() => {
    // Clean up filters
    const cleanFilters = cleanUpFilters(formFilters)
    return isDefaultFormObject(cleanFilters, defaultFormFilters)
  }, [defaultFormFilters, formFilters])

  return (
    <>
      <div style={{cursor: 'pointer'}} title={filterTitle} onClick={() => setOpen(!open)}>
        {areFiltersActive ? <FilterOutlined /> : <FilterFilled />}
      </div>

      {!formFilters && (
        <div style={{display: 'none'}}>
          <FormFilter {...formFilterProps} submitOnMount={true} />
        </div>
      )}

      <Drawer
        title={filterTitle}
        placement='left'
        onClose={() => {
          setOpen(false)
        }}
        destroyOnClose={true}
        open={open}>
        <FormFilter {...formFilterProps} />
      </Drawer>
    </>
  )
}

export default FilterButton
