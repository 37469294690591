import {createContext, useContext} from 'react'
import {
  ErpProject,
  TimelineData,
  ChecklistPayload
} from '@components/forms/fields/widgets/task-management-widget/tm.t'
import {ErpDomain} from '@shared/interfaces'
import {UseQueryResult} from '@tanstack/react-query'

/**
 * Task management state interface
 *
 * @interface TMState
 */
export interface TMState {
  project?: ErpProject
  taskDomain?: ErpDomain
  getChecklistQuery?: UseQueryResult<ChecklistPayload>
  getTimelineQuery?: UseQueryResult<TimelineData>
}

export const TMContext = createContext<TMState>({})

export const useTMContext = () => useContext(TMContext)
