import * as Yup from 'yup'
import {fr} from 'yup-locales'

const ONE_OF_SCHEMAS = {
  METHOD: 'oneOfSchemas',
  TEST: {
    NAME: 'one-of-schemas',
    MESSAGE: 'Not all items in ${path} match one of the allowed schemas'
  }
}

export function oneOfSchemas(this: Yup.MixedSchema, schemas: Yup.Schema[]) {
  return this.test(ONE_OF_SCHEMAS.TEST.NAME, ONE_OF_SCHEMAS.TEST.MESSAGE, (item) =>
    schemas.some((schema) => schema.isValidSync(item, {strict: true}))
  )
}

Yup.addMethod<Yup.MixedSchema>(Yup.mixed, ONE_OF_SCHEMAS.METHOD, oneOfSchemas)

// Don't include the key in the error message
const customFrLocale = {
  ...fr,
  mixed: {...fr.mixed, required: 'champ obligatoire'}
}

Yup.setLocale(customFrLocale)

export default Yup
