import React, {useEffect, useMemo, useState} from 'react'
import {Action, ErpTable} from '@shared/interfaces'
import {Table as AntdTable, Collapse} from 'antd'
import {ColumnType} from 'antd/lib/table/interface'
import CollapseHeader from './header'
import {Crud} from './table.t'
import {resolveColumns} from './helpers'
import useRowActions from './hooks/use-row-actions'
import {FormObject} from '@components/forms/form.t'
import _ from 'lodash'
import useActionHandler from '@hooks/use-action-handler'
import useRowSelection from './hooks/use-row-selection'

interface TableProps {
  table: ErpTable
  data: FormObject[]
  crud?: Crud
}

const Table: React.FC<TableProps> = ({table, data, crud}) => {
  const [columns, setColumns] = useState<ColumnType<FormObject>[]>([])

  const {
    header: headerActions,
    row: rowActions,
    multiSelection: multiSelectionActions
  } = useMemo(
    () => ({
      row: [],
      header: [],
      multiSelection: [],
      ..._.groupBy<Action>(table.actions, 'tableLocation')
    }),
    [table.actions]
  )

  const actionsColumn: ColumnType<FormObject> | null = useRowActions(table, rowActions, crud)

  const {rowSelection, selectedItems, setSelectedItems} = useRowSelection()

  const actionHandler = useActionHandler(table.domain?.keyPlural, {
    allItems: data,
    selectedItems
  })

  const normalizedData = useMemo(
    () =>
      data?.map(({_id, key, ...rest}) => ({
        _id,
        key: key || _id,
        ...rest
      })) || [],
    [data]
  )

  useEffect(() => {
    resolveColumns(table, setColumns, actionsColumn, crud)
  }, [table, actionsColumn, crud])

  return (
    <Collapse activeKey={table._id}>
      <Collapse.Panel
        showArrow={false}
        key={table._id}
        header={
          <CollapseHeader
            onSave={crud?.onAdd}
            title={table.name}
            actions={{headerActions, multiSelectionActions}}
            actionHandler={actionHandler}
            createForm={table.forms?.create}
            add={table.extendable}
            showMultiselectActions={Boolean(selectedItems.length)}
            onActionSuccess={() => setSelectedItems([])}
          />
        }>
        <AntdTable
          rowSelection={!table.static ? rowSelection : undefined}
          columns={columns}
          dataSource={normalizedData}
          {...(!table.pagination && {pagination: false})}
        />
      </Collapse.Panel>
    </Collapse>
  )
}

export default Table
