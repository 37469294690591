import React from 'react'
import {useField} from 'formik'
import Yup from '@libs/yup'
import 'yup-phone'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import InputPhone from './input-phone'
import 'antd-country-phone-input/dist/index.css'
import _ from 'lodash'
import FormItem from '../form-item/form-item'

interface PhoneInputComponentProps {
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

const PhoneInputComponent: React.FC<FieldComponentProps<PhoneInputComponentProps>> = ({field}) => {
  const [formikField] = useField<string>(field)

  return (
    <React.Fragment>
      <FormItem field={field} style={{flex: 1}} className='mb-2'>
        <InputPhone {...formikField} disabled={field.disabled} />
      </FormItem>
    </React.Fragment>
  )
}

const PhoneInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      let schemas: (
        | Yup.StringSchema
        | Yup.StringSchema<'' | undefined | null | Yup.Maybe<string>>
      )[] = [Yup.string().phone(), Yup.string().nullable().oneOf([''])]

      if (field.required) schemas = [Yup.string().phone().required()]

      const schema = field.required
        ? Yup.mixed().oneOfSchemas(schemas)
        : Yup.mixed().oneOfSchemas(schemas).nullable()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <PhoneInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default PhoneInput
