import {APIProxyV1Service, APIService, AuthService, ErpApiService} from '@services'
import {useRouter} from 'next/router'
import {createContext, ReactNode, useContext} from 'react'

interface InmemoriServicesContextInterface {
  authService: AuthService
  apiService: APIService
  erpApiService: ErpApiService
  apiProxyV1Service: APIProxyV1Service
}

const InmemoriServicesContext = createContext<InmemoriServicesContextInterface | undefined>(
  undefined
)

export const InmemoriServicesProvider = (props: {
  authServiceBaseUrl: string
  erpApiServiceBaseUrl: string
  children?: ReactNode
}) => {
  const {authServiceBaseUrl, erpApiServiceBaseUrl} = props

  const router = useRouter()

  const apiService = new APIService(
    true,
    () => {
      if (window.location.pathname !== '/login') {
        router
          .push(`/login?redirect=${window.location.pathname}/${window.location.search}`)
          .catch((e) => {
            // workaround for https://github.com/vercel/next.js/issues/37362
            if (!e.cancelled) {
              throw e
            }
          })
      }
    },
    {
      headers: {
        appclient: 'crm'
      }
    }
  )

  const authService = new AuthService(apiService, authServiceBaseUrl)
  const erpApiService = new ErpApiService(apiService, erpApiServiceBaseUrl)
  const apiProxyV1Service = new APIProxyV1Service(apiService, erpApiServiceBaseUrl)

  return (
    <InmemoriServicesContext.Provider
      value={{apiService, authService, erpApiService, apiProxyV1Service}}>
      {props.children}
    </InmemoriServicesContext.Provider>
  )
}

export const useInmemoriServices = () => {
  const context = useContext(InmemoriServicesContext)

  if (context === undefined) {
    throw new Error('useInmemoriServices must be used within a InmemoriServicesProvider')
  }

  return context
}
