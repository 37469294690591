import {LoadingOutlined, SearchOutlined} from '@ant-design/icons'
import {FormObject} from '@components/forms/form.t'
import {useErpDomainsByKeyPluralQuery} from '@queries'
import {Input} from 'antd'
import _ from 'lodash'
import React, {useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import FilterButton from './filter-button'
import {decodeUrl} from '@libs/router'

export interface SearchBarProps {
  domainName?: string
  initialSearch?: string
  placeholder?: string
  loading?: boolean
  onSearch?: (value: string) => void
  onFilter?: (values?: FormObject) => void
  className?: string
  /** Query parameter 'formPattern' from the URL. If exists, we use it for search value on first load */
  formPattern?: string
}

export type SearchBarRef = {reset: () => void}

const SearchBar = React.forwardRef(
  (props: SearchBarProps, ref: React.ForwardedRef<SearchBarRef>) => {
    const {
      domainName,
      initialSearch,
      placeholder = 'Rechercher',
      loading = false,
      onSearch,
      onFilter,
      className = '',
      formPattern
    } = props
    const [search, setSearch] = useState(initialSearch)

    const debouncedSearch = useMemo(() => {
      return _.debounce((value: string) => {
        if (onSearch) onSearch(value)
      }, 1000)
    }, [onSearch])

    const startSearch = useCallback(
      (value: string) => {
        setSearch(value)
        if (value.length) {
          debouncedSearch(value)
        } else {
          // clear the search
          if (onSearch) {
            debouncedSearch.cancel()
            onSearch('')
          }
        }
      },
      [debouncedSearch, onSearch]
    )

    useImperativeHandle(ref, () => {
      return {
        reset: () => {
          setSearch(initialSearch || '')
          onSearch && onSearch(initialSearch || '')
        }
      }
    })

    const erpDomain = useErpDomainsByKeyPluralQuery(domainName as string, {enabled: !!domainName}) // cast done to please type checking, the query is not called if domainName is undefined or ''

    const filterButton = useMemo(() => {
      if (!erpDomain.isLoading && !erpDomain.isError && erpDomain.data.forms?.filter)
        return <FilterButton filterFormId={erpDomain.data.forms.filter} onFilter={onFilter} />
    }, [erpDomain.isError, erpDomain.isLoading, erpDomain.data?.forms?.filter, onFilter])

    useEffect(() => {
      if (!filterButton) onFilter?.()
    }, [filterButton, onFilter])

    useEffect(() => {
      // you don't want to set value to every search bar on the page. Only in the one that respects these conditions:
      if (!formPattern || (domainName && domainName !== 'forms') || typeof formPattern !== 'string')
        return
      setSearch(formPattern)
    }, [domainName, formPattern])

    useEffect(() => {
      const decodedUrl = decodeUrl()
      if (!decodedUrl) return

      const {domain, domainId, subDomain, subDomainId} = decodedUrl

      if (domain?.keyPlural === domainName) {
        if (domainId) {
          setSearch(domainId)
          startSearch(domainId)
        }
      } else if (subDomain?.keyPlural === domainName) {
        if (subDomainId) {
          setSearch(subDomainId)
          startSearch(subDomainId)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Input
        value={search}
        placeholder={placeholder}
        prefix={loading ? <LoadingOutlined /> : <SearchOutlined />}
        className={className}
        allowClear
        suffix={filterButton}
        onChange={(e) => startSearch(e.target.value)}
      />
    )
  }
)

SearchBar.displayName = 'SearchBar'

export default SearchBar
