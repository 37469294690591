import {createContext, useContext} from 'react'

interface ActionContext {
  addColumn: (rowId: string) => void
  deleteColumn: (columnId: string) => void
  addRow: (index: number) => void
  deleteRow: (rowId: string) => void
  deleteComponent: (componentId: string) => void
  openEditEntityModal: (entityId: string) => void
  changeColumnWeight: (columnId: string, weight: number) => void
}
export const ActionContext = createContext<ActionContext | undefined>(undefined)

export const useActionContext = () => {
  const context = useContext(ActionContext)

  if (context === undefined) {
    throw new Error('useActionContext must be used within a ActionContext Provider')
  }

  return context
}
