import React from 'react'
import {Log} from './import-report.t'
import {Card, Space, Table, Tag, Typography} from 'antd'
import {useApp} from '@store/app'

interface LogsProps {
  logs: Log[]
  status: 'success' | 'warning' | 'error' | 'processing'
  level: 'success' | 'warning' | 'error'
}

const Logs: React.FC<LogsProps> = ({logs, status, level}) => {
  const {t} = useApp()

  const back = {
    success: '#478778',
    warning: '#FDDA0D',
    error: '#C41E3A'
  }

  const columns = [
    {
      title: t('components.forms.fields.widgets.importReport.logs.table.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text: string, {type}: Log) => {
        const color: {[key: string]: string} = {
          created: 'green',
          updated: 'yellow',
          default: 'geekblue'
        }

        return (
          <Tag color={color[type.toLocaleLowerCase()] || color.default} className='bold p-1'>
            {type}
          </Tag>
        )
      }
    },
    {
      title: t('components.forms.fields.widgets.importReport.logs.table.detail'),
      dataIndex: 'detail',
      key: 'detail',
      render: (text: string, log: Log) => {
        console.log(log)
        return (
          log.detail && (
            <Typography.Paragraph code copyable>
              {JSON.stringify(log.detail, null, 2)}
            </Typography.Paragraph>
          )
        )
      }
    }
  ]

  if (status === 'processing') return null

  return (
    <Card
      title={`${t(`components.forms.fields.widgets.importReport.logs.levels.${level}`)} (${
        logs.length
      })`}
      className='my-2'
      headStyle={{background: back[level], color: '#FFFF'}}>
      <Space direction='vertical' className='w-100'>
        <Table columns={columns} dataSource={logs} />
      </Space>
    </Card>
  )
}

export default Logs
