import * as Yup from 'yup'
import React, {useCallback, useMemo} from 'react'
import {atPath, relativePath} from '@libs/utils'
import {Button, ConfigProvider, Space, theme} from 'antd'

import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import FormItem from '../form-item/form-item'

interface RadioBoolProps {
  _id: string
  key: string
  label: string
  name: string
  type: string
  fallbackKey?: string | null
  nullState?: boolean
  disabled?: boolean
}

interface RadioBoolButtonProps {
  value: boolean | null
  icon: IconProp
  checked: boolean
  onClick: (value: boolean | null) => void
  disabled?: boolean
}

const {useToken} = theme

const RadioBoolButton = ({value, icon, checked, onClick, disabled}: RadioBoolButtonProps) => {
  const {token} = useToken()

  const bgColor = useMemo(() => {
    switch (value) {
      case true:
        return 'green'
      case false:
        return 'red'
      default:
        return '#cdcdcd'
    }
  }, [value])

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: checked ? bgColor : token.colorWhite,
            defaultBorderColor: checked ? bgColor : token.colorBorder,
            defaultColor: checked ? token.colorWhite : token.colorPrimaryText
          }
        }
      }}>
      <Button
        disabled={disabled}
        onClick={() => onClick(value)}
        icon={<FontAwesomeIcon icon={icon} />}
      />
    </ConfigProvider>
  )
}

const RadioBoolComponent: React.FC<FieldComponentProps<RadioBoolProps>> = ({field}) => {
  const formProps = useFormikContext<{_id: string}>()
  const [formikField] = useField<boolean>(field)

  const nullState = useMemo(() => field.nullState || false, [field.nullState])

  const parent = useMemo(() => {
    return atPath(formProps.values, relativePath(field.name) as string)
  }, [field.name, formProps.values])

  const fallbackValue = useMemo(() => {
    return parent && field.fallbackKey && parent[field.fallbackKey]
  }, [field.fallbackKey, parent])

  const checkValues = useMemo(() => {
    const isChecked = (condition: boolean) => {
      if (formikField.value === condition) return true
      if (
        formikField.value === null &&
        typeof fallbackValue === 'boolean' &&
        fallbackValue === condition
      )
        return true
      return false
    }

    return {
      true: isChecked(true),
      false: isChecked(false),
      null: formikField.value === null || formikField.value === undefined
    }
  }, [formikField.value, fallbackValue])

  const onChange = useCallback(
    (value: boolean | null) => {
      if (field.disabled) return
      formProps.setFieldValue(formikField.name, value)
    },
    [field.disabled, formProps, formikField.name]
  )

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      <Space.Compact>
        <RadioBoolButton
          value={false}
          checked={checkValues.false}
          icon={['fas', 'times']}
          onClick={onChange}
        />
        {!!nullState && (
          <RadioBoolButton
            value={null}
            checked={checkValues.null}
            icon={['fas', 'dot-circle']}
            onClick={onChange}
          />
        )}
        <RadioBoolButton
          value={true}
          checked={checkValues.true}
          icon={['fas', 'check']}
          onClick={onChange}
        />
      </Space.Compact>
    </FormItem>
  )
}

const RadioBool: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema = Yup.string()

      if (field.required) schema = schema.required()

      return {[field.key]: field.nullState ? schema.nullable() : schema.nonNullable()}
    },
    generateComponent() {
      return <RadioBoolComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default RadioBool
