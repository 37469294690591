import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {LookupItem, LookupOptions} from './erp-api'
import {IntRange} from 'rc-picker/lib/interface'
import {FormObject} from '@components/forms/form.t'
import {
  CartDomain,
  CartItemSearchContext,
  CartItemStatus,
  ServiceDomain
} from '@components/forms/fields/widgets/cart-widget/cart.t'

export type DomainKey =
  | 'project'
  | 'task'
  | 'service'
  | 'cart'
  | 'document'
  | 'note'
  | 'workflow'
  | 'saleArticle'
  | 'supplierArticle'
  | 'cartItem' // no need to be exhaustive, it's just to avoid type collision with DomainKeyPlural
export type DomainKeyPlural =
  | 'articles'
  | 'projects'
  | 'tasks'
  | 'services'
  | 'carts'
  | 'forms'
  | 'documents'
  | 'notes'
  | 'workflows'
  | 'cartItems' // no need to be exhaustive, it's just to avoid type collision with DomainKey

export interface BaseEntity {
  _id: string
  uat: Date
  cat: Date
  createdBy: string
  updatedBy: string
  deletedBy?: string
  ref: string
  counter: number
  displayValues: Array<string>
  deleted: boolean
  name?: string
  isHydrated?: boolean
  __type: string
}

export interface Domain extends BaseEntity {
  icon?: IconProp
  name: string
  isHydrated?: boolean
  displayValues: Array<string>
}

export interface DomainRelation {
  field: string
  hydrate: boolean
  isChild?: boolean
  isParent?: boolean
  key: DomainKey
  keyPlural: DomainKeyPlural
  multi?: string
  name: string
  namePlural: string
  sub: boolean
  withDeleted?: boolean
}

export interface ErpDomainOptions {
  duplicate: {enabled?: boolean}
}

export enum DomainSaveMethod {
  PUT = 'put',
  PATCH = 'patch'
}

export interface ErpDomain extends Domain {
  actions: Action[]
  forms: {
    create?: string
    read?: string
    filter?: string
  }
  isChild?: boolean
  isParent?: boolean
  key: DomainKey
  namePlural: string
  keyPlural: DomainKeyPlural
  sub?: boolean
  hideAsSub?: boolean
  tabOrder?: string[]
  tabs: Tab[]
  fields: Record<string, unknown>
  relations: DomainRelation[]
  lookupOptions: LookupOptions
  options: ErpDomainOptions
  saveMethod?: DomainSaveMethod
  mVirtuals: Array<{
    key: string
  }>
}

export enum TabComponentType {
  SINGLE_FORM = 'singleForm',
  LIST_AND_FORM = 'listAndForm'
}

export interface TabCRUDInfo {
  /** the function to execute as a string */
  fn: string
  /** id of the form */
  form: string
}

export interface Tab extends BaseEntity {
  _id: string
  key: DomainKey
  name: string
  component: TabComponentType
  crud: {
    create: TabCRUDInfo
    read: TabCRUDInfo
    update: TabCRUDInfo
    delete: TabCRUDInfo
  }
  actions: Action[]
  isHidden?: string
}

export interface FormLayoutComponent {
  id: string
  label?: string
}

export interface FormLayoutColumn {
  id: string
  label?: string
  showLabel?: boolean
  weight: number
  hidden?: string
  components: FormLayoutComponent[]
  className?: string
}

export interface FormLayoutRow {
  id: string
  label?: string
  showLabel?: boolean
  hidden?: string
  columns: FormLayoutColumn[]
  className?: string
}

export interface FormLayout {
  name: string
  rows: FormLayoutRow[]
}

export interface FormDomain extends Domain {
  key: string
  type: string
  layout?: FormLayout
  outputMw: string
  fields: Array<FieldDomain>
  __type: 'form'
}

export interface FieldNatureMap {
  input: 'input'
  widget: 'widget'
}

export enum DateTimeInputType {
  date = 'date',
  datetime = 'datetime',
  'date-range' = 'date-range',
  'datetime-range' = 'datetime-range',
  time = 'time',
  'time-range' = 'time-range'
}

export enum OtherFieldInputType {
  array = 'array',
  'array-object' = 'array-object',
  bool = 'bool',
  code = 'code',
  color = 'color',
  email = 'email',
  entity = 'entity',
  file = 'file',
  json = 'json',
  layout = 'layout',
  location = 'location',
  multiselect = 'multiselect',
  number = 'number',
  object = 'object',
  password = 'password',
  phone = 'phone',
  'price-bundle' = 'price-bundle',
  rich = 'rich',
  'rich-inline' = 'rich-inline',
  select = 'select',
  text = 'text',
  textarea = 'textarea',
  textareainfinity = 'textareainfinity',
  url = 'url',
  icon = 'icon',
  'file-upload' = 'file-upload',
  'radio-bool' = 'radio-bool',
  wokflow = 'workflow',
  table = 'table'
}

export type FieldInputType = DateTimeInputType | OtherFieldInputType

export interface FieldInputSubType {
  text: 'text'
  number: 'number'
  email: 'email'
  url: 'url'
  phone: 'phone'
  entity: 'entity'
  location: 'location'
  file: 'file'
}

export interface FieldWidgetTypeMap {
  button: 'button'
  cart: 'cart'
  'html-view': 'html-view'
}

export type FieldNature = FieldNatureMap[keyof FieldNatureMap]
export type FieldWidgetType = FieldWidgetTypeMap[keyof FieldWidgetTypeMap]
export type FieldSubType = FieldInputSubType[keyof FieldInputSubType]

export interface FieldDomain extends BaseEntity {
  key: string
  name: string
  nature: FieldNature
  nullState?: boolean
  type: FieldInputType
  subtype?: FieldSubType
  widgetType: FieldWidgetType
  label: string
  hidden: string | boolean
  form: FormDomain | string
  step?: number
  /**
   * will set hour interval for time-input and date-input
   */
  hourStep?: IntRange<1, 23> | undefined
  /**
   * will set minute interval for time-input and date-input
   */
  minuteStep?: IntRange<1, 59> | undefined
  min?: number
  max?: number
  placeholder?: string
  options?: string[]
  subform?: string
  action?: string
  onChange?: string
  disabled?: boolean
  required?: boolean
  defaultValue?: string
  widgetContext?: string
  flat?: boolean
  prefix?: string
  radio?: boolean
  pattern?: string
  subType?: string
  /**
   * domain: a handlebars template to be parsed by handlebars
   * used for entity picker
   */
  domain?: string
  /**
   * query: a handlebars template to be parsed by handlebars on the first place then parsed to JSON object
   * used in order to filter fetched data on server side
   */
  q?: string
  /**
   * matchLookup: a handlebars template to be parsed by handlebars on the first place then parsed to JSON object
   * used on the lookup stage in an aggregate query on the server side
   */
  matchLookup?: string
  /**
   * matchLookup: a handlebars template to be parsed by handlebars on the first place then parsed to JSON object
   * used in order to sort data fetched from the server side
   */
  queryParams?: string
  /**
   * queryParams: a handlebars template to be parsed by handlebars on the first place then parsed to JSON object
   * used to pass custom query params to lookup
   */
  sort?: string
  createContext?: string
  hideLabel?: boolean
  limit?: number
  hideCTAs?: boolean
  __type: 'field'
  table?: string
  tooltip?: string
  apiContext?: string
  /**
   * related to price-bundle-input configuration
   */
  isTvaPath?: boolean
  tvaPath?: string
}

export interface BasicDomain {
  _id: string
}

export interface OptionData {
  key: string
  value: string
}
//! TO BE REMOVED
export interface Select extends BasicDomain {
  _id: string
  options: OptionData[]
}
//! TO BE REMOVED
export interface Snippet extends BasicDomain {
  code: string
  fnName: string
  name: string
  subtype: string
  type: string
}
//! TO BE REMOVED
export interface Permission {
  fields: string[]
}

export interface Profile extends BaseEntity {
  _id: string
  firstname: string
  lastname: string
  houses: string[]
  initials?: string
}

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete'
}

export interface Endpoint extends Domain {
  path: string
  method: HttpMethod
  code: string
}

export enum ActionLocation {
  BAR = 'bar',
  MENU = 'menu',
  DROPDOWN = 'dropdown'
}

export enum ActionNature {
  DOC = 'doc',
  DOMAIN = 'domain'
}

export enum ActionType {
  CUSTOM = 'custom',
  ENDPOINT = 'endpoint',
  CUSTOM_ENDPOINT = 'customEndpoint'
}

export interface Action extends Domain {
  code: string
  hidden: string
  endpoint: Endpoint
  endpoints: Endpoint[]
  location: ActionLocation
  nature: ActionNature
  type: ActionType
  key: string
  order?: number
  tableLocation: 'row' | 'header' | 'multiSelection'
  hideOnMultiselection: boolean
}
export interface House extends BaseEntity {
  __type: 'house'
  name: string
}

export interface Product extends Domain {
  requiredPriceInformation: boolean
  isCatalog?: boolean
  options?: string[]
  pics: Images[]
  __type: 'product'
}

export interface Article extends BaseEntity {
  pics: Images[]
  price: {
    ttc: number | string
  }
  product: string | Product
  description?: string
  desc?: string
  tags: string[]
  house?: string | House
  __type: 'article'
  priceInformation?: string
}

export interface Images {
  hash: string
  _id: string
}

export interface CartItem extends BaseEntity {
  article: string
  saleArticle: string | SaleArticle
  searchContext?: CartItemSearchContext
  status: CartItemStatus
  __type: 'cartItem'
}

export interface Cart extends Domain {
  cartItems: CartItem[]
  project: Domain | string
  __type: 'article'
}

export interface OptionValue extends Domain {
  option?: string
  value?: string
  __type: 'optionValue'
}

export interface Option extends Domain {
  optionValues: OptionValue[]
  __type: 'option'
}

export interface Service extends Domain {
  __type: 'service'
}

export interface Place extends Domain {
  __type: 'place'
}

export interface SearchSaleArticles {
  product?: string | Product
  service?: Service
  options?: Array<OptionValue>
  cart: Cart
  action?: Action
}
export interface SearchProductArticlesResult {
  placeArticles?: Array<Article>
  sectorArticles?: Array<Article>
  productArticles?: Array<Article>
  places?: Array<Place>
}

export interface Category extends BaseEntity {
  name: string
  color?: string
  fontColor?: string
  type: 'regulatory' | 'internal' | 'task'
  parent?: string | Category
}

export interface Supplier extends BaseEntity {
  name: string
  houses: string[]
  transportPriceParams: {
    kmCosts: number
    transportPackage: number
    appliedIncreaseRate: number
    increase: number
    serviceCosts: number
    supportCosts: number
    abroadSupportCosts: number
  }
  __type: 'supplier'
}

export interface ErpDocument extends BaseEntity {
  name: string
  __type: 'document'
}

export interface Team extends BaseEntity {
  name: string
  color: string
  __type: 'document'
}

export interface TaskOwner {
  team?: Team
  profile?: Profile
}

export interface TaskStatus {
  done: {
    checked: boolean
    at: Date
    by: Profile
  }
  disabled: {
    checked: boolean
    at: Date
    by: Profile
  }
  obsolete: {
    checked: boolean
    at: {
      _id: string
      firstname: string
      lastname: string
    }
  }
}

export interface Task extends BaseEntity {
  name: string
  comment?: string
  deadline?: string
  status: TaskStatus
  owners?: TaskOwner[]
  project?: string
  __type: 'task'
}

export interface TableColumn {
  dataIndex: string
  key: string
  title: string
  render: string
}

export interface ErpTable extends BaseEntity {
  name: string
  type: string
  key: string
  pagination: boolean
  endpoint: Endpoint
  actions: Action[]
  domain: ErpDomain
  resolvedColumns: TableColumn[]
  extendable: boolean
  static: boolean
  forms?: {
    create: string
    edit: string
  }
  __type: 'table'
}

export interface SaleArticle extends BaseEntity {
  __type: 'saleArticle'
  tags: string[]
  pics: Images[]
  product: string | Product
  price: {
    ttc: number | string
    estimate: boolean
  }
  priceInformation?: string
}

export interface SupplierArticle extends BaseEntity {
  __type: 'supplierArticle'
  price: {
    ttc: number | string
    estimate: boolean
  }
  place?: Place
  supplier?: Supplier
}

export interface Price extends Omit<FormObject, '_id'> {
  ttc: number
  ht: number
  tva: number
}

export interface PriceBundle extends Price {
  string: {
    [K in keyof Price]: string
  }
  estimate?: boolean
}

export interface SearchArticlesItem extends LookupItem {
  saleArticle: SaleArticle
  cart: string
  description: string
  displayValues: string[]
  price: PriceBundle
  regulatoryDescription: string
  searchContext: CartItemSearchContext
  status: string
  supplierArticle: BaseEntity
}

export interface SearchArticles {
  cart: CartDomain
  service: ServiceDomain
  articles: SearchArticlesItem[]
}

export enum DatadgoErrorScope {
  GLOBAL = 'global',
  MARKETPLACE = 'marketplace'
}
