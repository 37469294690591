import {Card, Space, Typography} from 'antd'
import React from 'react'
import {CategoryLevel} from './tm.t'
import TaskManagementCategoryItem from './tm-category-item'
import {useApp} from '@store/app'
import TaskManagementHelper from './tm-helper'
import {useTasksListContext} from './tasks-list'
import {random} from '@libs/string'

const {Title} = Typography

type TaskManagementSectionProps = Omit<CategoryLevel, '_id' | '__type'>

const TaskManagementSection: React.FC<TaskManagementSectionProps> = ({name, sections}) => {
  const {t} = useApp()
  const {section} = useTasksListContext()

  const [sectionId] = React.useState<string>(random(5))

  return (
    <Card title={<Title level={5}>{name}</Title>} className='tm-section-card mb-1'>
      <Space direction='vertical' className='w-100'>
        {sections?.length
          ? sections.map(({_id, name, subSections}) => (
              <TaskManagementCategoryItem
                categoryId={`${_id}-${sectionId}`}
                name={name}
                subSections={subSections}
                key={TaskManagementHelper.getKey(`${_id}-${sectionId}`, section)}
              />
            ))
          : t('components.forms.fields.widgets.tm.subCategory.noData')}
      </Space>
    </Card>
  )
}

export default TaskManagementSection
