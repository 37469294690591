import _ from 'lodash'
import {ValidationAction, ValidationActionKind, ValidationState} from './form.t'
import * as Yup from 'yup'

export const formValidationReducer = (state: ValidationState, action: ValidationAction) => {
  const {schema} = state

  let fieldSchema
  switch (action.kind) {
    case ValidationActionKind.ADD_FIELD: {
      fieldSchema = action.field?.component?.validationSchema?.()
      if (!fieldSchema) return state
      state.schema = Yup.object().shape({...schema.fields, ...fieldSchema})
      return {...state}
    }
    case ValidationActionKind.REMOVE_FIELD:
      fieldSchema = action.field?.component?.validationSchema?.()
      if (!fieldSchema) return state
      state.schema = Yup.object().shape(_.omit(schema.fields, Object.keys(fieldSchema)))
      return {...state}
    case ValidationActionKind.MERGE_SCHEMA:
      state.schema = Yup.object().shape({...schema.fields, ...action.schema?.fields})
      return {...state}
    case ValidationActionKind.REMOVE_KEYS:
      if (!action.keys) return state
      state.schema = Yup.object().shape(_.omit(schema.fields, action.keys))
      return {...state}
    case ValidationActionKind.RESET:
      state.schema = Yup.object()
      return {...state}
    default:
      return state
  }
}
