import {useErpDomainsQuery} from '@queries'
import {DomainKey, DomainKeyPlural, ErpDomain} from '@shared/interfaces'
import _ from 'lodash'
import {useCallback, useMemo} from 'react'

export interface CRM {
  domainName: (
    domainKeyOrKeyPlural: DomainKey | DomainKeyPlural,
    plural?: boolean,
    sourcePlural?: boolean
  ) => undefined
  domainKey: (
    domainKeyOrKeyPlural: DomainKey | DomainKeyPlural,
    plural?: boolean,
    sourcePlural?: boolean
  ) => undefined
  domainPath: (domainKeyPlural: DomainKeyPlural) => undefined
  domains: ErpDomain[]
  getDomain: (
    domainIdOrKey: string,
    fieldname?: keyof ErpDomain
  ) => ErpDomain | ErpDomain[keyof ErpDomain]
  domainsSingular?: Record<DomainKey, ErpDomain>
  domainsPlural?: Record<DomainKeyPlural, ErpDomain>
}

export const useCRM = (): CRM | undefined => {
  const erpDomains = useErpDomainsQuery()

  const crmData = useMemo(() => {
    if (!erpDomains.data) return undefined

    return {
      domains: erpDomains.data,

      getDomain(domain: string, fieldname?: keyof ErpDomain) {
        const foundDomain = this.domains.find((d) => [d._id, d.key, d.keyPlural].includes(domain))
        return foundDomain && fieldname ? foundDomain[fieldname] : foundDomain
      },
      domainsSingular: _.reduce(
        erpDomains.data,
        (acc, domain) => {
          acc[domain.key] = acc[domain.key] || {}
          acc[domain.key]['name'] = domain.name
          acc[domain.key]['namePlural'] = domain.namePlural
          acc[domain.key]['key'] = domain.key
          acc[domain.key]['keyPlural'] = domain.keyPlural
          return acc
        },
        {} as Record<DomainKey, ErpDomain>
      ),
      domainsPlural: _.reduce(
        erpDomains.data,
        (acc, domain) => {
          acc[domain.keyPlural] = acc[domain.keyPlural] || {}
          acc[domain.keyPlural]['name'] = domain.name
          acc[domain.keyPlural]['namePlural'] = domain.namePlural
          acc[domain.keyPlural]['key'] = domain.key
          acc[domain.keyPlural]['keyPlural'] = domain.keyPlural
          return acc
        },
        {} as Record<DomainKeyPlural, ErpDomain>
      )
    }
  }, [erpDomains.data])

  const fn = useCallback(
    (fieldName: string) => {
      return (
        domainKeyOrKeyPlural: DomainKey | DomainKeyPlural,
        plural?: boolean,
        sourcePlural?: boolean
      ) => {
        try {
          return _.get(
            crmData,
            `domains${sourcePlural ? 'Plural' : 'Singular'}.${domainKeyOrKeyPlural}.${fieldName}${
              plural ? 'Plural' : ''
            }`
          )
        } catch (e) {
          console.log('crm:err:fn', fieldName, domainKeyOrKeyPlural)
        }
      }
    },
    [crmData]
  )

  return crmData
    ? {
        ...crmData,
        domainName: fn('name'),
        domainKey: fn('key'),
        domainPath: (domainKeyPlural: DomainKeyPlural) => fn('key')(domainKeyPlural, true)
      }
    : undefined
}
