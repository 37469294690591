import React from 'react'
import IconDisplay from './IconDisplay'
import {IconName, IconPrefix} from '@fortawesome/fontawesome-svg-core'

interface IconObj {
  type: IconPrefix
  iconName: IconName
}

interface IconRowProps {
  icons: IconObj[]
  onClick: (iconName: IconName) => void
}

const IconRow: React.FC<IconRowProps> = ({icons, onClick}) => (
  <div className='d-flex justify-content-center mb-1'>
    {icons.map((iconObj, index) => (
      <IconDisplay key={index} {...iconObj} onClick={onClick} />
    ))}
  </div>
)

export default IconRow
