import {atPath} from '@libs/utils'
import {Collapse, Space} from 'antd'
import React, {Dispatch, useEffect, useMemo, useState} from 'react'
import * as Yup from 'yup'

import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {
  FormObject,
  ValidationAction,
  ValidationActionKind,
  ValidationState
} from '@components/forms/form.t'
import {formValidationReducer} from '@components/forms/validation'
import {closestCenter, DndContext, DragOverlay, UniqueIdentifier} from '@dnd-kit/core'
import {restrictToParentElement, restrictToVerticalAxis} from '@dnd-kit/modifiers'
import {arrayMove, SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable'
import DragCard from '@libs/dnd/drag-card'
import Sortable from '@libs/dnd/sortable'
import {FieldDomain, FormDomain} from '@shared/interfaces'
import {FieldArray, useField, useFormikContext} from 'formik'
import _ from 'lodash'
import SubformInput from '../subform-input/subform-input'
import CollapseHeader from './header'
import {useApp} from '@store/app'

interface SubformArrayInputFieldComponentProps {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  subform?: string

  /** if true no add, move or remove feature in this array */
  locked?: boolean
}

interface SubformInputComponentProps
  extends FieldComponentProps<SubformArrayInputFieldComponentProps> {
  forms: FormDomain[]
  dispatchValidation: Dispatch<ValidationAction>
}

interface ListIndexationParams {
  indexList: string[]
  keysList: string[]
}

const SubformArrayInputComponent: React.FC<SubformInputComponentProps> = ({
  field,
  forms,
  dispatchValidation
}: SubformInputComponentProps) => {
  const {label, name} = field

  const {ability} = useApp()

  const {values} = useFormikContext<FormObject>()

  const subform = useMemo(
    () => forms.find(({_id}) => _id === field.subform),
    [field.subform, forms]
  )
  const [formField] = useField({...field})

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)

  const [subSchemas, setSubSchemas] = useState<Array<ValidationState>>([])

  const list: Array<FormObject> = useMemo(() => formField.value || [], [formField.value])

  const [isOpen, setIsOpen] = useState<boolean>(true)

  const {indexList, keysList} = useMemo<ListIndexationParams>(
    () =>
      list.reduce<ListIndexationParams>(
        (acc, current, index) => {
          acc.indexList.push(`${name}.${index}}`)
          acc.keysList.push(`${name}.${index}_.${current._id}`)
          return acc
        },
        {indexList: [], keysList: []}
      ),
    [list, name]
  )

  const updateSubSchema = (index: number) => (action: ValidationAction) => {
    const newSchema = formValidationReducer(subSchemas[index] || {schema: Yup.object()}, action)
    if (index === subSchemas.length) subSchemas.push(newSchema)
    else subSchemas[index] = newSchema
    setSubSchemas([...subSchemas])
  }

  const subforms = useMemo(() => {
    if (!subform) return []
    return list.map((data, index) => {
      return SubformInput(
        {...field, key: `${name}.${index}`, name: `${name}.${index}`} as FieldDomain,
        forms,
        {collapsible: false},
        ability,
        values
      )
    })
  }, [ability, field, forms, list, name, subform, values])

  const subformComponents = useMemo(
    () =>
      subforms.map((subform, index) => {
        return subform.generateComponent(updateSubSchema(index))
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subforms]
  )

  useEffect(() => {
    const cleanSchemas = subSchemas.map(
      (sub) => Object.values(sub.schema.fields || {})[0] || Yup.object()
    ) as [Yup.ISchema<unknown, Yup.AnyObject, never, unknown>]

    const schema = Yup.object().shape({
      [field.key]: cleanSchemas.length
        ? Yup.tuple(
            cleanSchemas.slice(0, list.length) as [
              Yup.ISchema<unknown, Yup.AnyObject, never, unknown>
            ]
          ).nullable()
        : Yup.array().nullable()
    })

    dispatchValidation({
      kind: ValidationActionKind.MERGE_SCHEMA,
      schema
    })

    return () => {
      dispatchValidation({
        kind: ValidationActionKind.REMOVE_KEYS,
        keys: [field.key]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.key, subSchemas, list])

  if (!subform) return null

  return (
    <FieldArray {...formField}>
      {({remove, move, push}) => (
        <Collapse
          activeKey={isOpen ? [name] : []}
          style={{flex: 1}}
          onChange={(params) => {
            setIsOpen(params.includes(name))
          }}>
          <Collapse.Panel
            header={
              <CollapseHeader
                label={label}
                add={!field.locked && !field.disabled}
                onAdd={() => {
                  push({})
                  setIsOpen(true)
                }}
              />
            }
            key={name}>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToParentElement, restrictToVerticalAxis]}
              onDragStart={(event) => setActiveId(event.active.id)}
              onDragEnd={(event) => {
                const {active, over} = event

                setActiveId(null)

                if (over && active.id !== over.id) {
                  const oldIndex = indexList.indexOf(active.id as string)
                  const newIndex = indexList.indexOf(over.id as string)
                  arrayMove(indexList, oldIndex, newIndex)
                  setSubSchemas(arrayMove(subSchemas, oldIndex, newIndex))
                  move(oldIndex, newIndex)
                }
              }}>
              {list && Array.isArray(list) && !!list.length && (
                <>
                  <Space direction='vertical' size='middle' style={{display: 'flex'}}>
                    <SortableContext items={indexList} strategy={verticalListSortingStrategy}>
                      {indexList.map((id, index) => (
                        <Sortable
                          key={keysList[index] || id}
                          id={id}
                          handle={!field.locked && !field.disabled}
                          remove={!field.locked && !field.disabled}
                          onRemove={() => {
                            remove(index)
                            setTimeout(() => {
                              setSubSchemas([...subSchemas.filter((_, i) => i !== index)])
                            }, 200)
                          }}
                          style={{...(id == activeId && {display: 'hidden'})}}>
                          {subformComponents[index]}
                        </Sortable>
                      ))}
                    </SortableContext>
                  </Space>
                  <DragOverlay>
                    {activeId ? (
                      <DragCard
                        handle={!field.locked && !field.disabled}
                        remove={!field.locked && !field.disabled}>
                        {subforms[indexList.indexOf(activeId as string)].generateComponent(() => {
                          /* Disabled as we will not handle validation on dummy drag component */
                        })}
                      </DragCard>
                    ) : null}
                  </DragOverlay>
                </>
              )}
            </DndContext>
          </Collapse.Panel>
        </Collapse>
      )}
    </FieldArray>
  )
}

const SubformArrayInput: FieldComponentFactory = (field, forms) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      return value || []
    },
    /* Validation handled by the component itself */
    validationSchema() {
      return {}
    },
    generateComponent(dispatchValidation) {
      return (
        <SubformArrayInputComponent
          forms={forms}
          field={_.omit(field, 'hidden', 'ref')}
          dispatchValidation={dispatchValidation}
        />
      )
    }
  }
}
export default SubformArrayInput
