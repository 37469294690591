import {AxiosService} from '@services/axios'
import type {AxiosRequestConfig} from 'axios'
import {createContext, ReactNode, useContext} from 'react'

import {houseSwitchKey} from '@components/navbar/houseSwitch'

export class APIService extends AxiosService {
  private token: string | null = null

  /**
   * The API Service lets you use an axios API and handles inmemori authentification via JWT tokens
   * @param useLocalStorage if set to true, tokens will be stored in localStorage
   * @param tokenRefreshFailureCallback callback to call when failing to get the auth token
   * @param config (optional) an axios config
   */
  constructor(
    private useLocalStorage: boolean,
    private tokenRefreshFailureCallback: (err: Error) => void,
    config?: AxiosRequestConfig
  ) {
    super(config)

    this.axios.interceptors.request.use(
      (config) => {
        const token = this.getToken()

        if (token) config.headers.Authorization = `Bearer ${token}`
        config.headers['browser-url'] = window.location.origin + window.location.pathname

        const isGlobalAccessEnabled = this.getGlobalAccess() === 'true'
        if (isGlobalAccessEnabled) {
          config.data = config.data || {}
          config.data.context = config.data.context || {}
          config.data.context.globalAccess = true
        }

        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )

    // // A bit overkill, but will help us in the future with OAuth2
    // createAuthRefreshInterceptor(
    //   this.axios,
    //   async (failedRequest) => {
    //     this.tokenRefreshFailureCallback(failedRequest)

    //     console.error('The request could not get a valid token please relogin', failedRequest)

    //     return Promise.reject()
    //   },
    //   {statusCodes: [401]}
    // )
  }

  public setToken(token: string | null) {
    if (this.useLocalStorage) {
      if (token === null) {
        localStorage.removeItem('token')
      } else {
        localStorage.setItem('token', token)
      }
    }
    this.token = token
  }

  public getToken(): string | null {
    if (this.useLocalStorage) {
      return localStorage.getItem('token')
    } else {
      return this.token
    }
  }

  public getGlobalAccess() {
    return localStorage.getItem(houseSwitchKey)
  }
}

const APIServiceContext = createContext<APIService | undefined>(undefined)

export const APIServiceProvider = (props: {apiService: APIService; children?: ReactNode}) => {
  const apiService = props.apiService

  return (
    <APIServiceContext.Provider value={apiService}>{props.children}</APIServiceContext.Provider>
  )
}

export const useAPIService = () => {
  const context = useContext(APIServiceContext)

  if (context === undefined) {
    throw new Error('useAPIService must be used within a APIServiceProvider')
  }

  return context
}
