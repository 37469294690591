/* eslint-disable react/display-name */
import React, {PropsWithChildren, createContext} from 'react'
import {NodeType, Node, NodeContext} from '../nodes/node-config'

export interface WorkflowContext {
  onNodeDelete: (id: string, type: NodeType) => void
  onNodeConfig: (id: string) => void
  onNodeCreate: (sourceId: string, type: NodeType) => void
  onNodeCopy: (id: string) => void
  onNodePaste: (sourceId: string) => void
  lockInteracton: (lock: boolean) => void
  selectedNode: Node | undefined
  copiedNode: NodeContext | undefined
  setSelectedNode: (node: Node) => void
  availableNodes: NodeType[]
}

export const WorkflowContext = createContext<WorkflowContext>({} as WorkflowContext)

const WorkflowProvider = (props: PropsWithChildren<WorkflowContext>) => {
  return <WorkflowContext.Provider value={{...props}}>{props.children}</WorkflowContext.Provider>
}

export const withWorkflow =
  <P extends object>(Component: React.ComponentType<P>): React.FC<Omit<P, keyof WorkflowContext>> =>
  (props) =>
    (
      <WorkflowContext.Consumer>
        {(store) => <Component {...(props as P)} {...store} />}
      </WorkflowContext.Consumer>
    )

export default WorkflowProvider
