import {Col, Row} from 'antd'
import {
  FieldDomainWithUniqId,
  FormLayoutComponentWithUniqId,
  FormLayoutEntity,
  FormLayoutColumnWithUniqIds
} from './layout-input.t'

interface LayoutDragOverlayProps {
  entityData: FormLayoutEntity
}

export default function LayoutDragOverlay({entityData}: LayoutDragOverlayProps) {
  function renderColumn(column: FormLayoutColumnWithUniqIds) {
    return (
      <Col className='layout-column'>
        <div className='layout-column-header'></div>
        {column.components.map((component: FormLayoutComponentWithUniqId) => {
          return renderComponent(component)
        })}
      </Col>
    )
  }

  function renderComponent(component: FormLayoutComponentWithUniqId | FieldDomainWithUniqId) {
    return (
      <Row className='layout-field' key={component.dndId}>
        <div className='layout-handle'>{component.label}</div>
      </Row>
    )
  }

  switch (entityData.type) {
    case 'row':
      return (
        <div>
          <div className='layout-row-header'></div>
          <Row className='layout-row' key={entityData.entity.id}>
            {entityData.entity.columns.map((column: FormLayoutColumnWithUniqIds) =>
              renderColumn(column)
            )}
          </Row>
        </div>
      )

    case 'column':
      return renderColumn(entityData.entity)

    case 'component':
    case 'field':
      return renderComponent(entityData.entity)
    default:
      return null
  }
}
