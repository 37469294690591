import _ from 'lodash'
import {CountryCode, PhoneNumber} from 'libphonenumber-js/types'
import {parsePhoneNumberFromString} from 'libphonenumber-js/max'
import {CountryPhoneInputValue} from 'antd-country-phone-input'

const PhoneToData = (phoneNumber?: PhoneNumber): CountryPhoneInputValue => ({
  phone: phoneNumber?.number,
  short: phoneNumber?.country,
  code: phoneNumber?.countryCallingCode && _.parseInt(phoneNumber?.countryCallingCode as string)
})

export const formatPhone = (
  input: string | CountryPhoneInputValue | undefined,
  defaultCountry = 'FR'
): CountryPhoneInputValue => {
  let phoneNumber: PhoneNumber | undefined

  if (!input) {
    return {
      phone: '',
      short: defaultCountry
    }
  }

  if (typeof input === 'string') {
    phoneNumber = parsePhoneNumberFromString(input)
    return PhoneToData(phoneNumber)
  }

  if (typeof input === 'object' && input.phone) {
    phoneNumber = parsePhoneNumberFromString(
      input.phone,
      (input.short || defaultCountry) as CountryCode
    )
    return phoneNumber ? PhoneToData(phoneNumber) : input
  }

  return input
}
