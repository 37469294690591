import {FormDomain, FormLayoutColumn} from '@shared/interfaces'
import {Col, Row, Space, Typography} from 'antd'
import React, {Dispatch} from 'react'
import {FieldWrapper} from '../fields/fields.t'
import {FormLayoutHideHandler} from '../handlers/form-layout-handlers'
import {ValidationAction} from '../form.t'
import FormFieldHideHandler from '../handlers/form-field-handlers'

const {Title} = Typography

interface FormColumnLayoutProps {
  form: FormDomain
  column: FormLayoutColumn
  fields: FieldWrapper[]
  dispatchValidation: Dispatch<ValidationAction>
}

const FormColumnLayout: React.FC<FormColumnLayoutProps> = ({
  form,
  fields,
  column,
  dispatchValidation
}) => {
  const {components, hidden, showLabel, label, weight} = column

  if (!components || components.length == 0) return null

  return (
    <FormLayoutHideHandler form={form} hidden={hidden || false}>
      <Col style={{flex: weight}}>
        {showLabel && label && (
          <Row>
            <Title level={5}>{label}</Title>
          </Row>
        )}
        <Space direction='vertical' className='w-100' size='small'>
          {components.map((component) => {
            const field = fields.find((f) => f.domain._id === component.id)
            if (!field) return null

            const fieldComponent = field.component?.generateComponent(dispatchValidation)
            if (!fieldComponent) return null

            return (
              <FormFieldHideHandler
                key={component.id}
                form={form}
                field={field}
                dispatchValidation={dispatchValidation}>
                {fieldComponent}
              </FormFieldHideHandler>
            )
          })}
        </Space>
      </Col>
    </FormLayoutHideHandler>
  )
}

export default FormColumnLayout
