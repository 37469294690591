import React, {PropsWithChildren} from 'react'
import {TMContext} from './context'
import {ErpProject} from '@components/forms/fields/widgets/task-management-widget/tm.t'
import {useFormikContext} from 'formik'
import {useDomain} from '@hooks/use-domain'
import {useGetTimelineQuery, useGetChecklistQuery} from '@queries/tasks'

const TMProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {values: project} = useFormikContext<ErpProject>()

  const taskDomain = useDomain('task')

  const getChecklistQuery = useGetChecklistQuery(project?._id)
  const getTimelineQuery = useGetTimelineQuery(project?._id)

  return (
    <TMContext.Provider
      value={{
        project,
        taskDomain,
        getChecklistQuery,
        getTimelineQuery
      }}>
      {children}
    </TMContext.Provider>
  )
}

export default TMProvider
