import env from '@beam-australia/react-env'
import * as utils from './utils'
import currency from 'currency.js'
import moment from 'moment-timezone'

const getFunction = (sync = false) => {
  return Object.getPrototypeOf(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    sync ? function () {} : async function () {}
  ).constructor
}

const getParams = (params = {}) => {
  const libs = {
    currency,
    moment
  }

  const keys = [...Object.keys(params), ...Object.keys(libs)]
  const values = [...Object.values(params), ...Object.values(libs)]

  return {keys, values, libs}
}

const Exec = (code: string, sync: boolean, params = {}) => {
  const {keys, values, libs} = getParams(params)

  const Func = getFunction(sync)

  const fnc = new Func(...keys, 'utils', 'libs', 'env', code)

  return fnc(...values, utils, libs, env('BRANCH_NAME'))
}

const ExecAsync = async <T = any>(code: string, params = {}): Promise<T> => {
  return Exec(code, false, params)
}

export const ExecSync = <T = any>(code: string, params = {}): T => {
  return Exec(code, true, params)
}

export default ExecAsync
