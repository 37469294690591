import {Select} from 'antd'
import {TFunction} from 'next-i18next'
import React from 'react'
import {SelectOption} from './utils'
import {CaretDownOutlined} from '@ant-design/icons'

interface DropdownProps {
  t: TFunction
  options: SelectOption[]
  label: string
  onChange: (value: string) => void
  value?: string
}

const Dropdown: React.FC<DropdownProps> = ({options, value, label, t, onChange}) => {
  return (
    <div className='w-100'>
      <label className='catalog-sidebar-dropdown-label'>{t(label)}</label>
      <Select
        suffixIcon={<CaretDownOutlined className='catalog-sidebar-dropdown-icon' />}
        className='catalog-sidebar-dropdown-select w-100'
        options={options}
        value={value}
        onChange={(v) => {
          onChange(v)
        }}
      />
    </div>
  )
}

export default Dropdown
