import {FormObject} from '@components/forms/form.t'
import {createQueryKeys} from '@lukemorales/query-key-factory'
import {ErpApiService, useInmemoriServices} from '@services'
import {GetOptions, LookupItem, LookupOptions, LookupResults} from '@shared/erp-api'
import {BaseEntity, BasicDomain, ErpDomain} from '@shared/interfaces'
import {
  QueryClient,
  UseQueryOptions,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'
import * as jsonpatch from 'fast-json-patch'

export const domainKeys = createQueryKeys('domain', {
  lookup: (keyPlural: string, options?: LookupOptions) =>
    options ? [keyPlural, options] : [keyPlural],
  get: (keyPlural: string, options?: GetOptions) => ({
    queryKey: options ? [keyPlural, options] : [keyPlural],
    contextQueries: {byId: (id: string) => [id]}
  })
})

function defaultLookupQueryOptions<TData extends LookupItem | BaseEntity = LookupItem>(
  keyPlural: string,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options: LookupOptions,
  queryOptions?: UseQueryOptions<LookupResults<TData>>
) {
  return {
    ...domainKeys.lookup(keyPlural, options),
    queryFn: () => erpApi.lookupDomain<TData>(keyPlural, options).then(({data}) => data.data),
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...queryOptions,
    onSuccess: (response: LookupResults<TData>) => {
      response.data.forEach((item: TData) => {
        if (item.isHydrated)
          queryClient.setQueryData(
            domainKeys.get(keyPlural, {hydrate: true})._ctx.byId(item._id).queryKey,
            item
          )
      })

      if (queryOptions?.onSuccess) queryOptions.onSuccess(response)
    }
  }
}

export function useLookupQuery<TData extends LookupItem | BaseEntity = LookupItem>(
  keyPlural: string,
  options: LookupOptions = {},
  queryOptions?: UseQueryOptions<LookupResults<TData>>
) {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useQuery(
    defaultLookupQueryOptions<TData>(keyPlural, erpApi, queryClient, options, queryOptions)
  )
}

export function fetchLookupQuery<TData extends LookupItem | BaseEntity = LookupItem>(
  keyPlural: string,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options: LookupOptions = {},
  queryOptions?: UseQueryOptions<LookupResults<TData>>
) {
  return queryClient.fetchQuery(
    defaultLookupQueryOptions<TData>(keyPlural, erpApi, queryClient, options, queryOptions)
  )
}

export function fetchGetByIdQuery<T extends BasicDomain>(
  keyPlural: string,
  id: string,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options?: {query?: UseQueryOptions<T>; api?: Omit<GetOptions, 'limit'>}
) {
  return queryClient.fetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: domainKeys.get(keyPlural, options?.api)._ctx.byId(id).queryKey,
    queryFn: () => erpApi.getDomainById<T>(keyPlural, id, options?.api).then(({data}) => data.data),
    ...options?.query
  })
}

export function useGetByIdQuery<T extends BasicDomain>(
  keyPlural: string,
  id: string,
  options?: {query?: UseQueryOptions<T>; api?: Omit<GetOptions, 'limit'>}
) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: domainKeys.get(keyPlural, options?.api)._ctx.byId(id).queryKey,
    queryFn: () => erpApi.getDomainById<T>(keyPlural, id, options?.api).then(({data}) => data.data),
    refetchOnWindowFocus: false,
    ...options?.query
  })
}

export function fetchGetQuery<T extends BasicDomain>(
  keyPlural: string,
  erpApi: ErpApiService,
  queryClient: QueryClient,
  options?: {query?: UseQueryOptions<T[]>; api?: GetOptions}
) {
  return queryClient.fetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: domainKeys.get(keyPlural, options?.api).queryKey,
    queryFn: () => erpApi.getDomain<T>(keyPlural, options?.api).then(({data}) => data.data),
    ...options?.query
  })
}

export function useGetQuery<T>(
  keyPlural: string,
  options?: {query?: UseQueryOptions<T[]>; api?: GetOptions}
) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: domainKeys.get(keyPlural, options?.api).queryKey,
    refetchOnWindowFocus: false,
    queryFn: () => erpApi.getDomain<T>(keyPlural, options?.api).then(({data}) => data.data),
    ...options?.query
  })
}

export function useCreateMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({domain, item}: {domain: ErpDomain; item: FormObject}) =>
      erpApi.createDomainItem(domain, item).then(({data}) => data.data),
    onSuccess: (response, {domain}) => invalidateDomainQueries(queryClient, domain.keyPlural)
  })
}

export function useRemoveMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({domain, id}: {domain: ErpDomain; id: string}) =>
      erpApi.removeDomainItemById(domain, id),
    onSuccess: (response, {domain, id}) => {
      return Promise.all([
        invalidateDomainQueries(queryClient, domain.keyPlural),
        queryClient.removeQueries({
          queryKey: domainKeys.get(domain.keyPlural)._ctx.byId(id).queryKey
        })
      ])
    }
  })
}

export function useRestoreMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({domain, id}: {domain: ErpDomain; id: string}) =>
      erpApi.restoreDomainItemById(domain, id),
    onSuccess: (response, {domain, id}) =>
      invalidateDomainQueries(queryClient, domain.keyPlural, id)
  })
}

export function useUpdateMutation<TData = FormObject>() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({domain, id, item}: {domain: ErpDomain; id: string; item: TData}) =>
      erpApi.updateDomainItemById(domain, id, item).then(({data}) => data.data),
    onSuccess: (response, {domain, id}) =>
      invalidateDomainQueries(queryClient, domain.keyPlural, id)
  })
}

export function usePatchMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({
      domain,
      id,
      patchs
    }: {
      domain: ErpDomain
      id: string
      patchs: jsonpatch.Operation[]
    }) => erpApi.patchDomainItemById(domain, id, patchs).then(({data}) => data.data),
    onSuccess: (response, {domain, id}) =>
      invalidateDomainQueries(queryClient, domain.keyPlural, id)
  })
}

export function useDuplicateMutation() {
  const queryClient = useQueryClient()
  const erpApi = useInmemoriServices().erpApiService

  return useMutation({
    mutationFn: ({domain, id}: {domain: ErpDomain; id: string}) =>
      erpApi.duplicateDomainItemById(domain, id),
    onSuccess: (response, {domain}) => invalidateDomainQueries(queryClient, domain.keyPlural)
  })
}

export function invalidateDomainQueries(
  queryClient: QueryClient,
  domainKeyPlural: string,
  id?: string
) {
  return Promise.all([
    queryClient.invalidateQueries({queryKey: domainKeys.lookup(domainKeyPlural).queryKey}),
    queryClient.invalidateQueries({
      queryKey: domainKeys.get(domainKeyPlural).queryKey,
      predicate: ({queryKey}) => !queryKey.includes('byId')
    }),
    ...((id && [
      queryClient.invalidateQueries({
        queryKey: domainKeys.get(domainKeyPlural)._ctx.byId(id).queryKey
      })
    ]) ||
      [])
  ])
}
