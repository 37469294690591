import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {Row} from 'antd'
import {useField, useFormikContext} from 'formik'
import {DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent} from '@dnd-kit/core'

import {atPath} from '@libs/utils'
import LayoutFieldsDrawer from './layout-fields-drawer'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {
  FormLayoutDomainWithUniqIds,
  FormLayoutEntity,
  FormLayoutRowWithUniqIds,
  ModalData
} from './layout-input.t'
import LayoutRows from './layout-rows'
import LayoutDragOverlay from './layout-drag-overlay'
import {ActionContext} from './ActionContext'
import LayoutModal from './layout-modal'
import {
  computeUniqDndIds,
  updateLayoutOnDragEnd,
  updateLayoutOnDragOver,
  updateLayoutOnDragStart,
  setModalDataInLayout,
  changeColumnWeightInLayout,
  getModalData,
  deleteComponentInLayout,
  deleteColumnInLayout,
  addColumnInLayout,
  addRowInLayout
} from './layout-manage-data'
import FormItem from '../form-item/form-item'

interface LayoutInputComponentProps {
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  min?: number
  max?: number
  step?: number
}

const LayoutEditor: React.FC<FieldComponentProps<LayoutInputComponentProps>> = ({field}) => {
  const [formikField, formikMeta] = useField<FormLayoutDomainWithUniqIds>(field)
  const {setFieldValue, values} = useFormikContext<FormLayoutDomainWithUniqIds>()
  const [modalData, setModalData] = useState<ModalData | null>(null)
  const [active, setActive] = useState<FormLayoutEntity | null>(null)

  const currentLayout = formikField.value?.rows as FormLayoutRowWithUniqIds[]
  const currentFields = values.fields

  useEffect(() => {
    if (!currentLayout && !currentFields) return
    // Assign a uuid to each component in the layout for DnD-kit
    const dataWithUniqIds = computeUniqDndIds(currentLayout, currentFields || [])

    setFieldValue('layout.rows', dataWithUniqIds.rowsWithUniqIds)
    setFieldValue('fields', dataWithUniqIds.fieldsWithUniqIds)
    //Re-calculate only when initial value changes, since the value is immediately re-computed to generate dndIds (unique ids for each component/field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikMeta.initialValue])

  function setLayout(newLayout: FormLayoutRowWithUniqIds[]) {
    setFieldValue('layout.rows', newLayout)
  }

  function onDragEnd(event: DragEndEvent) {
    const newRows = updateLayoutOnDragEnd(event, currentLayout)
    if (newRows) {
      setLayout(newRows)
      setActive(null)
    }
  }

  function onDragOver(event: DragOverEvent) {
    const dragOverResult = updateLayoutOnDragOver(event, currentLayout, currentFields)
    if (dragOverResult?.newRows) setLayout(dragOverResult.newRows)
    if (dragOverResult?.newFields) setFieldValue('fields', dragOverResult.newFields)
  }

  function onDragStart(event: DragStartEvent) {
    const activeData = updateLayoutOnDragStart(event, currentLayout, currentFields)
    // Set the dragoverlay element
    if (activeData) setActive(activeData)
  }

  function addRow(index: number) {
    setLayout(addRowInLayout(currentLayout, index))
  }

  function deleteRow(rowId: string) {
    const filteredRows = currentLayout.filter((row) => row.id !== rowId)
    setLayout(filteredRows)
  }

  function addColumn(rowId: string) {
    setLayout(addColumnInLayout(currentLayout, rowId))
  }

  function deleteColumn(columnId: string) {
    setLayout(deleteColumnInLayout(currentLayout, columnId))
  }

  function deleteComponent(componentId: string) {
    setLayout(deleteComponentInLayout(currentLayout, componentId))
  }

  function changeColumnWeight(columnId: string, weight: number) {
    setLayout(changeColumnWeightInLayout(currentLayout, columnId, weight))
  }

  function openEditEntityModal(entityId: string) {
    const newModalData = getModalData(currentLayout, entityId)
    if (newModalData) setModalData(newModalData)
  }

  function saveModalData(modalData: ModalData) {
    setLayout(setModalDataInLayout(currentLayout, modalData))
    setModalData(null)
  }

  function cancelModal() {
    setModalData(null)
  }

  if (!currentFields) return null

  return (
    <>
      <LayoutModal
        entityData={modalData}
        setEntityData={setModalData}
        handleCancel={cancelModal}
        handleOk={saveModalData}
      />
      <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} onDragOver={onDragOver}>
        <FormItem field={field} className='layout-builder'>
          <Row>
            <ActionContext.Provider
              value={{
                addColumn,
                addRow,
                deleteRow,
                deleteColumn,
                deleteComponent,
                openEditEntityModal,
                changeColumnWeight
              }}>
              <LayoutFieldsDrawer fields={currentFields} />
              <LayoutRows rows={currentLayout} />
            </ActionContext.Provider>
          </Row>
          <DragOverlay>{active && <LayoutDragOverlay entityData={active} />}</DragOverlay>
        </FormItem>
      </DndContext>
    </>
  )
}

const LayoutInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      return atPath(data || {}, field.key)
    },
    validationSchema() {
      return {[field.key]: Yup.object()}
    },
    generateComponent() {
      return <LayoutEditor field={field} />
    }
  }
}

export default LayoutInput
