import Catalog from '@components/catalog'
import {CartItemDomain} from '@components/forms/fields/widgets/cart-widget/cart.t'
import {PanelsKeys} from '@libs/panels/panels.t'
import {Cart} from '@shared/interfaces'
import {AppContext, useApp} from '@store/app'
import React from 'react'

export interface CatalogPanelProps extends AppContext {
  product?: string
  cart?: Cart
  cartItem?: CartItemDomain
}

const Panel: React.FC<CatalogPanelProps> = ({product, cart, cartItem}) => {
  const {Panels} = useApp()
  const onClose = () => Panels.close(PanelsKeys.CATALOG)

  return <Catalog onClose={onClose} product={product} cart={cart} cartItem={cartItem} />
}

export default Panel
