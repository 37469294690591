import {Button, Col} from 'antd'
import {SortableContext, useSortable, verticalListSortingStrategy} from '@dnd-kit/sortable'
import {FormLayoutColumnWithUniqIds} from './layout-input.t'
import {useDroppable} from '@dnd-kit/core'
import LayoutField from './layout-field'
import {CSS} from '@dnd-kit/utilities'
import {useActionContext} from './ActionContext'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

interface LayoutColumnProps {
  column: FormLayoutColumnWithUniqIds
}

export default function LayoutColumn({column}: LayoutColumnProps) {
  const actionContext = useActionContext()

  // Droppable zone to allow dropping items in an empty column
  const {setNodeRef: setDroppableNodeRef} = useDroppable({
    id: column.id + '_droppable',
    data: {
      id: column.id,
      type: 'droppable'
    }
  })

  const {setNodeRef, listeners, attributes, transform, isDragging} = useSortable({
    id: column.id,
    data: {
      type: 'column'
    }
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    ...(isDragging && {opacity: 0})
  }

  function renderChildren() {
    return column.components.map((component) => (
      <LayoutField
        showDelete={true}
        key={component.dndId}
        id={component.dndId}
        data={{label: component.label}}
        type='component'
        label={component.label || ''}
      />
    ))
  }

  return (
    <Col style={style} className='layout-column'>
      <div className='layout-header'>
        <div className='layout-handle' ref={setNodeRef} {...listeners} {...attributes}>
          <FontAwesomeIcon icon={'up-down-left-right'} />
        </div>
        <div className='layout-buttons'>
          <Button onClick={() => actionContext.openEditEntityModal(column.id)}>
            <FontAwesomeIcon icon={'gear'} />
          </Button>
          <Button onClick={() => actionContext.deleteColumn(column.id)}>
            <FontAwesomeIcon icon={'trash'} />
          </Button>
          <Button onClick={() => actionContext.changeColumnWeight(column.id, column.weight - 1)}>
            <FontAwesomeIcon icon={'arrow-left'} />
          </Button>
          <Button onClick={() => actionContext.changeColumnWeight(column.id, column.weight + 1)}>
            <FontAwesomeIcon icon={'arrow-right'} />
          </Button>
        </div>
      </div>
      <SortableContext
        items={column.components.map((c) => c.dndId)}
        strategy={verticalListSortingStrategy}
        id={column.id}>
        {column.components.length == 0 ? (
          <div
            ref={setDroppableNodeRef}
            id={column.id + '_droppable'}
            className='layout-column-droppable'></div>
        ) : (
          <div className='layout-column-sortable'>{renderChildren()}</div>
        )}
      </SortableContext>
    </Col>
  )
}
