import React from 'react'
import {BaseEntity, Task, TaskStatus} from '@shared/interfaces'

export interface TaskCategory {
  _id: string
  name: string
  __type: 'category'
}

export interface Progress {
  done: number
  total: number
}

export interface LinkLevel {
  _id: string
  name: string
  __type: 'document' | 'cartItem' | 'project'
  tasks: Task[]
}

export interface SectorAndSupplierLevel {
  _id: string
  name: string
  __type: 'category'
  progress?: Progress
  subSections: LinkLevel[]
}

export interface CategoryLevel extends TaskCategory {
  sections: SectorAndSupplierLevel[]
}

export interface ChecklistPayload {
  active: CategoryLevel[]
  disabled: CategoryLevel[]
  obsolete: CategoryLevel[]
}

export interface ErpProject extends BaseEntity {
  __type: 'project'
  checklist: ChecklistPayload
}

export interface TaskDetailsView {
  readonly name: string
  readonly comment?: string
  readonly status: TaskStatus
  readonly deadline?: string
  readonly owners: {
    team?: {
      name: string
      tooltip: string
      color: string
    }
    profile?: string
  }[]
  readonly __type: string
}

export enum Constants {
  TAB_ID = '63ecde5c213c6b41968fc684'
}

export interface CheckedState {
  link: boolean
  [key: string]: boolean
}

export interface TaskActions {
  edit: () => void
  toggle: () => void
}

export type GlobalChecked = {
  [key: string]: CheckedState
}

/**
 * Tasks list state interface
 *
 * @interface TasksListState
 */
export interface TasksListState {
  old?: boolean
  section?: 'active' | 'disabled' | 'obsolete'
  collapseActiveKeys: string[] | string
  setCollapseActiveKeys?: React.Dispatch<React.SetStateAction<string[] | string>>
  globalChecked?: GlobalChecked
  setGlobalChecked?: React.Dispatch<React.SetStateAction<GlobalChecked>>
}

export type ProgressStats = {[key: string]: Progress}

export interface TimelineDate {
  date: number
  dayOfWeek: number
  hour: string
  month: number
  span: string
}

export interface TimelineService {
  name: string
  place: string
  dummyPlace: boolean
  date: TimelineDate
}

export interface TimelineItem {
  span: string
  date: TimelineDate
  services: TimelineService[]
}

export type TimelineData = TimelineItem[]
