import type {AxiosRequestConfig} from 'axios'
import axios, {AxiosInstance} from 'axios'

export class AxiosService {
  protected axios: AxiosInstance

  constructor(config?: AxiosRequestConfig) {
    if (!config) config = {}

    this.axios = axios.create(config)
  }

  protected async apiRequest<T>(config: AxiosRequestConfig, url: string, data?: any) {
    if (!config.headers) config.headers = {}

    if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json'

    return this.axios<T>({
      ...config,
      url,
      data
    }).catch((error) => {
      if (error?.response?.status === 401 && window.location.pathname !== '/login') {
        const redirectTo = new URL('/login', window.location.origin)
        if (window.location.pathname !== '/') {
          redirectTo.searchParams.append('redirect', window.location.pathname)
        }

        window.location.href = redirectTo.toString()
        // Add "as never" to the return statement to satisfy TypeScript
        return null as never
      }

      return Promise.reject(error)
    })
  }

  protected async apiRequestHeader(
    config: AxiosRequestConfig,
    url: string,
    headerToRetrieve?: string,
    data?: unknown
  ) {
    if (!config.headers) config.headers = {}

    config.headers['Content-Type'] = 'application/json'

    return this.axios({
      ...config,
      url,
      data: data
    }).then((res) => {
      if (headerToRetrieve) {
        return res.headers[headerToRetrieve] ?? res.headers[headerToRetrieve.toLowerCase()]
      }

      return res.headers
    })
  }

  public get<T = unknown>(url: string, config?: AxiosRequestConfig) {
    return this.apiRequest<T>({...config, method: 'get'}, url)
  }

  public delete<T = unknown>(url: string, config?: AxiosRequestConfig) {
    return this.apiRequest<T>({...config, method: 'delete'}, url)
  }

  public post<T = unknown>(url: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.apiRequest<T>({...config, method: 'post'}, url, data)
  }

  public put<T = unknown>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.apiRequest<T>({...config, method: 'put'}, url, data)
  }

  public patch<T = unknown>(url: string, data: unknown, config?: AxiosRequestConfig) {
    return this.apiRequest<T>({...config, method: 'patch'}, url, data)
  }

  public head(url: string, config?: AxiosRequestConfig, headerToRetrieve?: string, data?: unknown) {
    return this.apiRequestHeader({...config, method: 'head'}, url, headerToRetrieve, data)
  }
}
