import {CatalogArticle} from '@store/catalog/context'
import _ from 'lodash'
import React from 'react'
import {v4 as uuid} from 'uuid'
import ArticleItem from './article-item'
import {Product} from '@shared/interfaces'
import Loader from '@components/misc/loader'

interface ArticlesListProps {
  numberOfColumns: number
  articles: CatalogArticle[]
  isLoading?: boolean
  setArticle?: React.Dispatch<CatalogArticle>
  product?: Product
  belongsToCart(articleId: string): boolean
}

const ArticlesList: React.FC<ArticlesListProps> = ({
  numberOfColumns,
  articles,
  setArticle,
  isLoading,
  belongsToCart,
  product
}) => {
  if (isLoading) {
    return <Loader />
  }

  return (
    <div className='d-flex flex-column w-100 gap-2'>
      {_.chunk(articles, numberOfColumns).map((articles: CatalogArticle[]) => (
        <div
          key={uuid()}
          className='d-flex flex-row justify-content-around align-items-start gap-2'>
          {_.range(numberOfColumns).map((index: number) =>
            articles[index] ? (
              <div key={articles[index]._id} className='flex-grow-1 w-100'>
                <ArticleItem
                  article={articles[index]}
                  setArticle={setArticle}
                  product={product}
                  belongsToCart={belongsToCart(articles[index]?.saleArticle?._id)}
                />
              </div>
            ) : (
              <div key={uuid()} className='flex-grow-1 w-100'></div>
            )
          )}
        </div>
      ))}
    </div>
  )
}

export default ArticlesList
