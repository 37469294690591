import {useInmemoriServices} from '@services'
import {UseQueryOptions, useQuery} from '@tanstack/react-query'
import {erpKeys} from './erp'
import {Profile} from '@shared/interfaces'

export function useGetProfileSelfQuery(options?: UseQueryOptions<Profile>) {
  const erpApi = useInmemoriServices().erpApiService

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: erpKeys.profiles._ctx.self.queryKey,
    queryFn: () =>
      erpApi.getProfileSelf().then(({data}) => {
        const user = data.data
        return user
      }),
    cacheTime: 0,
    staleTime: 0,
    refetchOnWindowFocus: false,
    ...options
  })
}
