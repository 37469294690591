import React, {useMemo, useState, useEffect} from 'react'
import {useField} from 'formik'
import Yup from '@libs/yup'
import {atPath} from '@libs/utils'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import _ from 'lodash'
import {IconDefinition, IconName, IconPrefix, library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {fas} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useApp} from '@store/app'
import {Button, Input, Pagination} from 'antd'
import {UpOutlined, DownOutlined} from '@ant-design/icons'
import IconRow from './IconRow'
import {useDebouncedCallback} from 'use-debounce'
import FormItem from '../form-item/form-item'

const {Search} = Input

interface IconInputComponentProps {
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

type SelectedIconType = {
  icon: IconPrefix
  iconName: IconName
}

const IconInputComponent: React.FC<FieldComponentProps<IconInputComponentProps>> = ({field}) => {
  const {label, ...props} = field

  const [formikField] = useField<string>({...props})

  const {t} = useApp()

  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState<SelectedIconType | null>(null)

  library.add(fas, fab) // import all icons dynamically

  const allIcons = useMemo(() => {
    const blacklist = ['faAd', 'faFontAwesomeLogoFull'] // ISO v1

    const solidIcons = Object.values(fas)
      .map((icon: IconDefinition) => icon.iconName)
      .filter((iconName) => !blacklist.includes(iconName))

    const brandIcons = Object.values(fab)
      .map((icon: IconDefinition) => icon.iconName)
      .filter((iconName) => !blacklist.includes(iconName))

    return [
      ...solidIcons.map((icon) => ({iconName: icon, type: 'fas' as IconPrefix})),
      ...brandIcons.map((icon) => ({iconName: icon, type: 'fab' as IconPrefix}))
    ]
  }, [])

  const filteredIcons = useMemo(() => {
    return allIcons.filter((iconObj) => iconObj.iconName.includes(searchText))
  }, [allIcons, searchText])

  const iconsPerRow = 7
  const rows = 5
  const iconsPerPage = iconsPerRow * rows

  const displayIcons = filteredIcons.slice(
    (currentPage - 1) * iconsPerPage,
    currentPage * iconsPerPage
  )

  const onChange = (icon: string, prefix: IconPrefix) => {
    const valueToSave = `${prefix} fa-${icon}`
    formikField.onChange({target: {name: formikField.name, value: valueToSave}})
  }

  const selectIcon = (icon: string) => {
    const selected = allIcons.find((iconObj) => iconObj.iconName === icon)
    if (selected) {
      onChange(icon, selected.type)
      setSelectedIcon({icon: selected.type, iconName: selected.iconName})
      setIsOpen(false)
    }
  }

  const onSearch = (v: string) => {
    setSearchText(v)
  }

  useEffect(() => {
    if (formikField.value) {
      const [prefix, iconName] = formikField.value.split(' fa-')
      const selected = allIcons.find(
        (iconObj) => iconObj.iconName === iconName && iconObj.type === prefix
      )
      setSelectedIcon(selected ? {icon: selected.type, iconName: selected.iconName} : null)
    }
  }, [formikField.value, allIcons])

  const debouncedOnChange = useDebouncedCallback((value) => {
    setSearchText(value)
  }, 400)

  return (
    <>
      <FormItem field={field} style={{flex: 1}} className='mb-2'>
        <div className='card p-3'>
          <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <div>
              <span>
                {selectedIcon ? (
                  <FontAwesomeIcon
                    icon={[selectedIcon.icon, selectedIcon.iconName]}
                    className='ml-2'
                    size='2x'
                  />
                ) : formikField.value ? (
                  formikField.value
                ) : (
                  t('components.forms.fields.iconPicker.noIcon') || "Pas d'icône"
                )}
              </span>
            </div>
            <Button disabled={field.disabled} onClick={() => setIsOpen(!isOpen)} type='text'>
              {isOpen ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </div>

          <div className='mb-3' />

          {isOpen && (
            <>
              <Search
                placeholder={t('components.forms.fields.iconPicker.search') || 'Rechercher'}
                allowClear
                onSearch={onSearch}
                onChange={(e) => debouncedOnChange(e.target.value)}
                className='mb-3'
                style={{width: '100%'}}
              />
              <div className='mb-3'>
                {/* iconPerRowxrow grid */}
                {Array.from({length: rows}, (_, rowIndex) => {
                  const startIdx = rowIndex * iconsPerRow
                  const endIdx = (rowIndex + 1) * iconsPerRow
                  const iconsInRow = displayIcons.slice(startIdx, endIdx)

                  return <IconRow key={rowIndex} icons={iconsInRow} onClick={selectIcon} />
                })}
              </div>

              <Pagination
                current={currentPage}
                total={filteredIcons.length}
                pageSize={iconsPerRow * rows}
                onChange={(page) => setCurrentPage(page)}
                className='text-center mb-3'
                showSizeChanger={false}
              />
            </>
          )}
        </div>
      </FormItem>
    </>
  )
}

const IconInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      let schema = Yup.string()

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <IconInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default IconInput
