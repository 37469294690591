import React, {useMemo} from 'react'
import {CategoryLevel} from './tm.t'
import TasksList from './tasks-list'
import {Card, Collapse, Space, Typography} from 'antd'
import {useApp} from '@store/app'

const {Title} = Typography

interface OldTasksProps {
  disabled: CategoryLevel[]
  obsolete: CategoryLevel[]
}

interface ListProps {
  list: CategoryLevel[]
  key: 'disabled' | 'obsolete'
  title: string
}

const OldTasks: React.FC<OldTasksProps> = ({disabled, obsolete}) => {
  const {t} = useApp()

  const listsProps: ListProps[] = useMemo<ListProps[]>(
    () => [
      {
        list: disabled,
        key: 'disabled',
        title: 'components.forms.fields.widgets.tm.disabled'
      },
      {
        list: obsolete,
        key: 'obsolete',
        title: 'components.forms.fields.widgets.tm.obsolete'
      }
    ],
    [disabled, obsolete]
  )

  return (
    <Card title='Anciennes tâches' className='mt-4'>
      <Space direction='vertical' className='w-100'>
        {listsProps.map(({list, key, title}) => (
          <Collapse defaultActiveKey={[]} className='mt-1' key={key}>
            <Collapse.Panel
              header={<Title level={5}>{t(title)}</Title>}
              key={key}
              showArrow={false}>
              <TasksList list={list} old section={key} />
            </Collapse.Panel>
          </Collapse>
        ))}
      </Space>
    </Card>
  )
}

export default OldTasks
