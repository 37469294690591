import {FormObject} from '@components/forms/form.t'
import {LookupItem, LookupOptions} from '@shared/erp-api'
import {useEffect, useRef, useState} from 'react'
import LookupTable from './lookup-table'
import SearchBar, {SearchBarRef} from './search-bar'
import {DomainKeyPlural} from '@shared/interfaces'
import {useRouter} from 'next/router'

export interface LookupComponentProps {
  domainName?: DomainKeyPlural
  initialSearch?: string
  initialPage?: number
  selectedLookupItem?: LookupItem
  setSelectedLookupItem?: (value: LookupItem | undefined) => void
  lookupOptions?: LookupOptions
  /** compact mode for selects, modals, panels ... */
  compact?: boolean
  /** (default: true) autoselect first item on mount, page change, etc. */
  autoSelect?: boolean
  /** defines if the component should update the StateManager */
  updateStateManager?: boolean
  /** defines the main domain, if the current one is a sub domain */
  mainDomainName?: string
}

const LookupComponent = (props: LookupComponentProps) => {
  const {
    domainName,
    initialSearch,
    initialPage = 1,
    selectedLookupItem,
    setSelectedLookupItem,
    lookupOptions,
    compact = false,
    autoSelect = true,
    updateStateManager,
    mainDomainName
  } = props
  const router = useRouter()

  const [search, setSearch] = useState(initialSearch)
  const [filter, setFilter] = useState<FormObject | undefined>(undefined)
  const [page, setPage] = useState(initialPage)

  const searchBarRef = useRef<SearchBarRef>(null)

  useEffect(() => {
    if (search !== initialSearch && searchBarRef?.current?.reset) searchBarRef.current.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName, searchBarRef])

  const {formPattern} = router.query

  useEffect(() => {
    if (!formPattern || (domainName && domainName !== 'forms') || typeof formPattern !== 'string')
      return
    setSearch(formPattern)
  }, [formPattern, domainName])

  return (
    <div>
      <div className='lookup-searchbar'>
        <SearchBar
          domainName={domainName}
          formPattern={typeof formPattern === 'string' ? formPattern : undefined}
          initialSearch={initialSearch}
          ref={searchBarRef}
          onSearch={(value) => {
            setSearch(value)
          }}
          onFilter={(filter) => {
            setFilter(filter)
          }}
        />
      </div>
      {domainName && (
        <LookupTable
          domainName={domainName}
          lookupOptions={{...lookupOptions, filter, search}}
          selectedLookupItem={selectedLookupItem}
          setSelectedLookupItem={setSelectedLookupItem}
          page={page}
          setPage={setPage}
          compact={compact}
          autoSelect={autoSelect}
          updateStateManager={updateStateManager}
          mainDomainName={mainDomainName}
        />
      )}
    </div>
  )
}

export default LookupComponent
