import CodeEditor from '@components/misc/code-editor'
import {Col, Input, Modal, Row, Switch} from 'antd'
import {ModalData} from './layout-input.t'

interface LayoutModalProps {
  entityData: ModalData | null
  setEntityData: React.Dispatch<React.SetStateAction<ModalData | null>>
  handleOk: (modalData: ModalData) => void
  handleCancel: () => void
}

export default function LayoutModal({
  entityData,
  setEntityData,
  handleOk,
  handleCancel
}: LayoutModalProps) {
  function changeProperty(property: string, value: string | boolean) {
    if (entityData) setEntityData({...entityData, [property]: value})
  }

  function onOk() {
    if (entityData) handleOk(entityData)
  }

  return (
    <Modal title='Editer' open={entityData !== null} onOk={onOk} onCancel={handleCancel}>
      {entityData && (
        <div className='layout-modal'>
          <Row gutter={16}>
            <Col span={18}>
              Label
              <Input
                value={entityData.label}
                placeholder={'Label'}
                onChange={(event) => {
                  changeProperty('label', event.target.value)
                }}
              />
            </Col>
            <Col span={6}>
              Show Label
              <Switch
                checked={entityData.showLabel}
                onChange={(value: boolean) => {
                  changeProperty('showLabel', value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              Class name
              <Input
                placeholder={'Class Name'}
                value={entityData.className}
                onChange={(event) => {
                  changeProperty('className', event.target.value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              Is Hidden
              <CodeEditor
                name={'hidden'}
                parsedValue={entityData.hidden}
                onChange={(value: string) => {
                  changeProperty('hidden', value)
                }}
              />
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  )
}
