import {AppContext, withApp} from '@store/app'
import {ConfigProvider, Steps} from 'antd'

export interface CartValidationHeaderProps extends AppContext {
  step: number
}

const CartValidationHeader = ({t, step}: CartValidationHeaderProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          fontSizeLG: 16
        },
        components: {
          Steps: {
            descriptionMaxWidth: 300
          }
        }
      }}>
      <Steps
        current={step}
        items={[
          {
            title: <strong>{t('panel.cartValidation.steps.project.step.title')}</strong>,
            description: t('panel.cartValidation.steps.project.step.description')
          },
          {
            title: <strong>{t('panel.cartValidation.steps.suppliers.step.title')}</strong>,
            description: t('panel.cartValidation.steps.suppliers.step.description')
          }
        ]}
      />
    </ConfigProvider>
  )
}

export default withApp(CartValidationHeader)
