import React, {useMemo} from 'react'
import {ImportDomain} from './import-report.t'
import {useFormikContext} from 'formik'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import {FieldDomain} from '@shared/interfaces'
import _ from 'lodash'
import Summary from './summary'
import {useDomainById} from '@hooks/use-domain'
import Logs from './logs'

const ImportReportComponent: React.FC<
  FieldComponentProps<Omit<FieldDomain, 'hidden' | 'ref'>>
> = () => {
  const formProps = useFormikContext<ImportDomain>()

  const report = useMemo(() => formProps.values.report, [formProps.values])

  const domain = useDomainById(
    typeof formProps.values.domain === 'string'
      ? formProps.values.domain
      : formProps.values.domain?._id
  )

  const logs: {key: 'successes' | 'warnings' | 'errors'; level: 'success' | 'warning' | 'error'}[] =
    [
      {
        key: 'successes',
        level: 'success'
      },
      {
        key: 'warnings',
        level: 'warning'
      },
      {
        key: 'errors',
        level: 'error'
      }
    ]

  return (
    <React.Fragment>
      {domain && (
        <Summary
          memory={report.memory}
          duration={report.duration}
          summary={report.summary}
          status={formProps.values.status}
          type={formProps.values.type}
          domain={domain}
        />
      )}
      {logs
        .map(({key, level}) => {
          return (
            report[key]?.length && (
              <Logs key={key} logs={report[key]} level={level} status={formProps.values.status} />
            )
          )
        })
        .filter(Boolean)}
    </React.Fragment>
  )
}

const ImportReportWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return <ImportReportComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default ImportReportWidget
