export const normalize = (s = '') => s.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
export const capitalize = (str = '') => {
  const array = str.split(' ')
  for (let i = 0, x = array.length; i < x; i++)
    array[i] = (array[i][0] || '').toUpperCase() + array[i].substr(1)
  return array.join(' ')
}
export const isVowel = (str = '') => 'aeiou'.indexOf((str[0] || '').toLowerCase()) !== -1
export const elision = (str = '') => isVowel(str) || str[0].toLowerCase() == 'h'

export const capitalizeSentence = (str = '') => {
  const array = str.split(' ')
  for (let i = 0, x = array.length; i < x; i++) array[i] = capitalize(array[i])
  return array.join(' ')
}
export const random = (len: number) => {
  len = len || 9
  let s = ''
  const r = 'abcdefghijklmnopqrstuvwxyz'
  for (let i = 0; i < len; i++) {
    s += r.charAt(Math.floor(Math.random() * r.length))
  }
  return s
}
/**
 * Convert name with lowercased particules
 * Handle d’, de, du, des
 * @param {string} name
 * @returns {string | undefined}
 */
export const name = (name = '') => {
  name = name.toLowerCase()
  name = capitalize(name)
  name = name.replace(/(\sEt\s|\sDe\s|\sDu\s|\sDes\s)/g, (t) => t.toLowerCase()) // first round lowercase
  name = name.replace(/(\sD'|\sL')/g, (t) => t.toLowerCase()) // second round lowercase
  name = name.replace(/(\sd'[a-zé])/g, (t) => " d'" + (t[3] || '').toUpperCase()) // apostrophe case (letter d), ex: Jean d’Ormesson
  name = name.replace(/(\sl'[a-zé])/g, (t) => " L'" + (t[3] || '').toUpperCase()) // apostrophe case (letter l), ex: Victor de L’Espinay
  name = name.replace(/(-[a-z])/g, (v) => (v || '').toUpperCase()) // tiret case, ex: Jean-albert => Jean-Albert
  name = name.replace(/Née/g, 'née') // Catherine Albertalli Née Larive => Catherine Albertalli née Larive
  return name || undefined
}

String.prototype.capitalize = function (this: string) {
  return capitalize(this)
}
