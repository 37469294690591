import {Action, ErpTable} from '@shared/interfaces'
import {useCallback, useMemo} from 'react'
import {Crud} from '../../table.t'
import {ColumnType} from 'antd/lib/table'
import {FormObject} from '@components/forms/form.t'
import ActionsDropdown, {ActionsDropdownProps} from '../../actions-dropdown'
import useActionMenuItems from './use-action-menu-items'
import useMenuItemClick from './use-menu-item-click'

const useRowActions = (
  table: ErpTable,
  actions: Action[],
  crud?: Crud
): ColumnType<FormObject> | null => {
  const getItems = useActionMenuItems(table, actions)

  const onMenuItemClick = useMenuItemClick(table, actions, crud)

  const getActionDropdownProps = useCallback(
    (record: FormObject): ActionsDropdownProps => ({
      menuProps: {
        onClick: onMenuItemClick(record),
        items: getItems(record)
      }
    }),
    [onMenuItemClick, getItems]
  )

  const actionsColumn = useMemo(
    () =>
      actions?.length && {
        key: 'actions',
        title: 'Actions',
        dataIndex: 'actions',
        render(text: string, record: FormObject) {
          if (!actions?.length) return null
          return <ActionsDropdown {...getActionDropdownProps(record)} />
        }
      },
    [getActionDropdownProps, actions.length]
  )

  return actionsColumn || null
}

export default useRowActions
