import React, {useEffect, useMemo, useState} from 'react'
import ReactFlow, {
  Controls,
  Background,
  ReactFlowProvider,
  useReactFlow,
  NodeTypes,
  EdgeTypes,
  FitViewOptions
} from 'react-flow-renderer/nocss'
import DefaultEdge from './edges/default-edge'
import StartNode from './nodes/flow/start-node'
import DropNode from './nodes/flow/drop-node'
import useLayout from './utils/useLayout'
import DefaultNode from './nodes/default-node'
import {
  Node,
  Edge,
  NodeData,
  NodeType,
  DataNodes,
  FlowNodes,
  NodeConfigs,
  NodeContext
} from './nodes/node-config'
import WorkflowContext from './utils/useWorkFlow'
import {defaultEdgeOptions, initialState} from './utils/initialValues'
import NodeManager from './utils/nodeManager'
import {useApp} from '@store/app'
import {PanelsKeys} from '@libs/panels/panels.t'
import {FormObject} from '@components/forms/form.t'
import {DeleteFilled} from '@ant-design/icons'

interface Workflow {
  nodes?: Node[]
  edges?: Edge[]
}

interface WorkflowBuilderProps {
  disabled?: boolean
  data: Workflow
  filterNodes?: NodeType[]
  onChange: (data: Workflow) => void
}

const WorkflowBuilder = ({data = {}, onChange, filterNodes, disabled}: WorkflowBuilderProps) => {
  const {fitView} = useReactFlow()

  const {nodes = [], edges = []} = data
  const [interactionLocked, setInteractionLocked] = useState<boolean>(false)
  const [selectedNode, setSelectedNode] = useState<Node | undefined>()
  const [copiedNode, setCopiedNode] = useState<NodeContext | undefined>()
  const [direction] = useState('TB')
  const {Panels, toast, modal, t} = useApp()

  const nodeTypes = useMemo<NodeTypes>(
    () => ({
      start: StartNode,
      ruleSplit: DefaultNode,
      multiRuleSplit: DefaultNode,
      domainTask: DefaultNode,
      split: DefaultNode,
      product: DefaultNode,
      productOptions: DefaultNode,
      document: DefaultNode,
      documentRaw: DefaultNode,
      article: DefaultNode,
      saleArticle: DefaultNode,
      supplierArticle: DefaultNode,
      service: DefaultNode,
      drop: DropNode
    }),
    []
  )

  const availableNodes = useMemo<NodeType[]>(() => {
    if (!filterNodes || !filterNodes.length) return [...DataNodes, ...FlowNodes]
    return [...filterNodes, ...FlowNodes]
  }, [filterNodes])

  const edgeTypes = useMemo<EdgeTypes>(() => ({default: DefaultEdge}), [])

  const fitViewOptions = useMemo<FitViewOptions>(
    () => ({
      padding: 0.2
    }),
    []
  )

  const openNodePanel = (
    {type, data}: {type: NodeType; data: NodeData},
    onSave: (data: NodeData) => void
  ) => {
    if (!NodeConfigs[type]) return false

    const formId = NodeConfigs[type].formId

    Panels.show(PanelsKeys.FORM, {
      formId,
      data: data,
      onSave: async (values: FormObject) => {
        onSave(values as NodeData)
        Panels.close(PanelsKeys.FORM)
      },
      title: `workflows.nodes.${type}`
    })

    return true
  }

  const nodeManager = NodeManager(data, onChange, openNodePanel)

  const onNodeCreate = (sourceId: string, type: NodeType) => {
    nodeManager.createConnection(sourceId, type)
  }

  const onNodeDelete = (nodeId: string) => {
    const delNode = nodeManager.getNodeFromId(nodeId)
    if (delNode)
      modal.confirm({
        maskClosable: true,
        title: t('workflows.removeNodeConfirm.title', {
          node: delNode,
          nodeConfig: NodeConfigs[delNode.type]
        }),
        icon: <DeleteFilled />,
        okButtonProps: {danger: true},
        content: t('workflows.removeNodeConfirm.content', {
          node: delNode,
          nodeConfig: NodeConfigs[delNode.type]
        }),
        onOk: () => nodeManager.deleteNode(delNode)
      })
  }

  const onNodeCopy = (nodeId: string) => {
    const node = nodeManager.getNodeFromId(nodeId)
    if (!node) return

    const nodeCtx = nodeManager.getNodeContext(node, true)
    setCopiedNode(nodeCtx)
    toast.success(`${t('workflows.copyNodeSuccess', {node: nodeCtx.node})}`)
  }

  const onNodePaste = (sourceId: string) => {
    if (copiedNode) nodeManager.pasteNode(sourceId, copiedNode)
  }

  const onNodeConfig = (nodeId: string) => {
    const node = nodeManager.getNodeFromId(nodeId)

    if (!node) return

    const onSave = (data: NodeData) => {
      try {
        nodeManager.updateNode(node, data)
      } catch (e) {
        console.warn('Update Node error', e)
        toast.err('Update Node error')
      }
    }

    openNodePanel(node, onSave)
  }

  const onNodeClick = (event: React.MouseEvent, node: Node) => {
    if ((!selectedNode || selectedNode.id !== node.id) && NodeConfigs[node.type])
      setSelectedNode(node)
    else setSelectedNode(undefined)
  }

  const layoutedNodes = useLayout(nodes, edges, {direction})

  useEffect(() => {
    if (selectedNode) {
      const ctx = nodeManager.getNodeContext(selectedNode, true)
      const newEdges = edges.map((edge) => {
        if (ctx.edges.includes(edge)) return {...edge, animated: true}
        return {...edge, animated: false}
      })
      onChange({nodes, edges: newEdges})
    } else {
      const newEdges = edges.map((edge) => ({...edge, animated: false}))
      onChange({nodes, edges: newEdges})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNode])

  useEffect(() => {
    if (nodes.length < 10)
      setTimeout(() => {
        if (!selectedNode) fitView({duration: 400})
      }, 100)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutedNodes])

  useEffect(() => {
    if (!data.nodes || !data.edges) onChange(initialState)
  }, [data, onChange])

  return (
    <WorkflowContext
      onNodeDelete={onNodeDelete}
      onNodeConfig={onNodeConfig}
      onNodeCreate={onNodeCreate}
      onNodeCopy={onNodeCopy}
      onNodePaste={onNodePaste}
      copiedNode={copiedNode}
      selectedNode={selectedNode}
      setSelectedNode={setSelectedNode}
      lockInteracton={setInteractionLocked}
      availableNodes={availableNodes}>
      <ReactFlow
        nodes={layoutedNodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeClick={(e, node) => onNodeClick(e, node as Node)}
        minZoom={0.2}
        maxZoom={2.5}
        zoomOnScroll={!interactionLocked}
        zoomOnPinch={!interactionLocked}
        zoomOnDoubleClick={!interactionLocked}
        panOnDrag={!interactionLocked}
        panOnScroll={!interactionLocked}
        fitViewOptions={fitViewOptions}
        fitView>
        <Controls showInteractive={false} />
        <Background />
      </ReactFlow>
    </WorkflowContext>
  )
}

const Workflow = (props: WorkflowBuilderProps) => (
  <ReactFlowProvider>
    <WorkflowBuilder {...props} />
  </ReactFlowProvider>
)

export default Workflow
