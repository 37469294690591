import {Handle, HandleProps} from 'react-flow-renderer/nocss'

interface DefaultHandleProps extends HandleProps {
  style?: React.CSSProperties
}

const DefaultHandle: React.FC<DefaultHandleProps> = (props: DefaultHandleProps) => (
  <Handle {...props} style={{opacity: 0, ...props.style}} />
)

export default DefaultHandle
