import React from 'react'
import {Row, Spin, SpinProps} from 'antd'

const Loader: React.FC<SpinProps> = (props) => (
  <Row justify={'center'} className='loader-container'>
    <Spin className='centered' {...props} />
  </Row>
)

export default Loader
