/**
 * The goal is that OPS can, possibly, define a custom TTC that is saved in the DB, otherwise we have automatic calculation of HT and TTC.
 * The TVA can be fetched from a 'tvaPath' defined in ERP form or a select or from. If none of them are valid, we have a default fallback.
 * If tvaPath is defined, we hide the select and OPS can't change it.
 *
 * tvaValueFromPath can be 0 so don't use !tvaValueFromPath
 *
 * Don't forget to add a post-save in the API to update if you rely on the tvaPath
 *
 * note: <Space.compact won't work with InputNumber/Select from formik-antd.
 */

import * as Yup from 'yup'
import React, {useState, useEffect, useCallback, useMemo} from 'react'
import {atPath} from '@libs/utils'
import {Select, InputNumber} from 'antd'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {FormObject} from '@components/forms/form.t'
import {useField, useFormikContext} from 'formik'
import LinkButton from './link-button'
import _ from 'lodash'
import {useLookupQuery} from '@queries/domains'
import currency from 'currency.js'
import formatNumber from '@libs/helpers/numbers'
import FormItem from '../form-item/form-item'

interface PriceBundleInputComponent {
  key: string
  label: string
  name: string
  required?: boolean
  disabled?: boolean
  tvaPath?: string
  tvaOverride?: boolean
}

interface PriceBundleField {
  ht?: number
  ttc?: number
  tva: number
}

type SelectOptions = {
  key: string
  value: string | number
}

const PriceBundleInputComponent: React.FC<FieldComponentProps<PriceBundleInputComponent>> = ({
  field
}) => {
  const {tvaPath, tvaOverride = false, ...props} = field
  const {setFieldValue, values} = useFormikContext<FormObject>()
  const [formikField, , helper] = useField<PriceBundleField>({...props})
  const [isAutomatic, setIsAutomatic] = useState(true)

  const {tvaSelect, fallbackTva} = useMemo<{tvaSelect: string; fallbackTva: number[]}>(() => {
    const tvaSelect = '621e4ec6fda2c3573b2c3566'
    const fallbackTva = [0, 10, 20]

    return {tvaSelect, fallbackTva}
  }, [])

  // on first render (same default values as in schema definition)
  useEffect(() => {
    if (!formikField.value) {
      const obj = {
        ht: undefined,
        ttc: undefined,
        tva: undefined
      }
      setFieldValue(formikField.name, obj)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tvaValueFromPath = useMemo(
    () => (tvaPath && typeof tvaPath === 'string' ? _.get(values, tvaPath) : undefined),
    [tvaPath, values]
  )

  const {data} = useLookupQuery('selects', {
    limit: 1,
    q: {
      _id: tvaSelect
    }
  })

  const tvaConfiguration = useMemo<number[]>(
    () =>
      Array.isArray(data?.data?.at(0)?.options)
        ? (data?.data?.at(0)?.options as SelectOptions[])?.map((option: SelectOptions) =>
            parseInt(option.key)
          )
        : fallbackTva,
    [data?.data, fallbackTva]
  )

  const tva = useMemo(() => {
    return formikField.value?.tva
  }, [formikField.value?.tva])

  const onHtChange = useCallback(
    (value: unknown) => {
      if (value === undefined || value === null || isNaN(Number(value))) {
        helper.setValue({...formikField.value, ht: undefined, ttc: undefined})
        return
      }

      if (typeof tva !== 'number' || typeof value !== 'number') return

      const ht = value

      if (isAutomatic === false) {
        helper.setValue({...formikField.value, ht})
        return
      }

      const ttc = currency(ht, {precision: 2})
        .multiply(tva / 100)
        .add(ht).value

      helper.setValue({
        ...formikField.value,
        ht,
        ttc
      })
    },
    [formikField.value, isAutomatic, tva, helper]
  )

  const onTtcChange = useCallback(
    (value: unknown) => {
      if (value === undefined || value === null || isNaN(Number(value))) {
        helper.setValue({...formikField.value, ht: undefined, ttc: undefined})
        return
      }

      if (typeof tva !== 'number' || typeof value !== 'number') return

      const ttc = value

      if (isAutomatic === false) {
        helper.setValue({...formikField.value, ttc})
        return
      }

      const ht = currency(ttc).divide(1 + tva / 100).value

      helper.setValue({
        ...formikField.value,
        ht,
        ttc
      })
    },
    [formikField.value, helper, isAutomatic, tva]
  )

  const onTvaChange = useCallback(
    (value: unknown) => {
      if (typeof value !== 'number') return

      const newTva = value
      const ht = formikField.value?.ht || 0

      const ttc = currency(ht, {precision: 2})
        .multiply(newTva / 100)
        .add(ht).value

      helper.setValue({
        ...formikField.value,
        ...(formikField.value?.ht && {ttc}),
        tva: newTva
      })
    },
    [formikField.value, helper]
  )

  useEffect(() => {
    if (tvaValueFromPath && !formikField.value?.tva) {
      onTvaChange(tvaValueFromPath)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tvaValueFromPath])

  return (
    <FormItem field={field} hasFeedback={false}>
      <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
        {(tvaValueFromPath === undefined || tvaOverride) && (
          <Select
            style={{width: 80, flexShrink: 0}}
            options={
              tvaConfiguration &&
              tvaConfiguration.map((tva: number) => ({label: `${tva} %`, value: tva}))
            }
            value={formikField.value?.tva}
            onChange={onTvaChange}
            placeholder='TVA'
          />
        )}
        <InputNumber
          name={`${props.name}.ht`}
          value={formikField.value?.ht}
          style={{width: 108}}
          onChange={onHtChange}
          decimalSeparator=','
          formatter={formatNumber}
          suffix='HT'
        />
        <InputNumber
          name={`${props.name}.ttc`}
          value={formikField.value?.ttc}
          style={{width: 108}}
          onChange={onTtcChange}
          decimalSeparator=','
          formatter={formatNumber}
          suffix='TTC'
        />
        <LinkButton isAutomatic={isAutomatic} setIsAutomatic={setIsAutomatic} />
      </div>
    </FormItem>
  )
}

const PriceBundleInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) return value
      return field.defaultValue || null
    },
    validationSchema() {
      const schema = Yup.object().shape({
        tva: Yup.number(),
        ht: Yup.number(),
        ttc: Yup.number()
      })
      return {
        [field.key]: field.required ? schema.required() : schema.nullable()
      }
    },
    generateComponent() {
      return <PriceBundleInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default PriceBundleInput
