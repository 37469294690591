'use client'

import PDFViewer from '@components/misc/pdf-viewer'
import {FieldComponentFactory} from '../../fields.t'
import {useFormikContext} from 'formik'
import {FormObject} from '@components/forms/form.t'

const PDFWidgetComponent: React.FC = () => {
  const formProps = useFormikContext<FormObject>()

  const file = formProps.values.file as {url: string; hash: string}
  return <PDFViewer file={file?.url} />
}

const PDFWidget: FieldComponentFactory = () => {
  return {
    generateComponent() {
      return <PDFWidgetComponent />
    }
  }
}

export default PDFWidget
