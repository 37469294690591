import {FormObject} from '@components/forms/form.t'
import Exec from '@libs/exec'
import {Action, ActionType} from '@shared/interfaces'
import {AppContext, useApp} from '@store/app'
import {useCallback} from 'react'

/**
 * It takes a URL path, finds all the parameters in it, and replaces them with the values from the item
 * @param action - The context action
 * @param urlPath - The path to the page.
 * @param currentDomain - domain key plural
 * @param item - context item
 *
 * @returns The parsedUrlPath is being returned.
 */
export const parseUrlPath = (
  action: Action,
  urlPath: string,
  currentDomain: string,
  item?: FormObject
) => {
  if (action.nature === 'domain') {
    return `/${currentDomain}${urlPath}${action ? `?action=${action._id}` : ''}`
  }

  const params = urlPath.match(/:(\w+)/g)
  let parsedUrlPath = urlPath

  params?.forEach((param) => {
    if (!item) return
    const paramName = param.replace(':', '')
    const paramValue = item[paramName]
    if (!paramValue) return
    parsedUrlPath = parsedUrlPath.replace(param, paramValue.toString())
  })

  return `/${currentDomain}${parsedUrlPath}${action ? `?action=${action._id}` : ''}`
}

export const handleEndpoint = async (
  action: Action,
  currentDomain: string,
  app: AppContext,
  item?: FormObject
) => {
  const {endpoint} = action

  const {t, toast} = app

  if (!endpoint) {
    console.error(`[endpoint] ${endpoint} not found`)
    return
  }

  let res

  try {
    const promise = app.api[endpoint.method](
      parseUrlPath(action, endpoint.path, currentDomain, item),
      {thraw: true}
    )
    res = await toast.promise(promise, {
      pending: t('action-bar.actions.loading'),
      success: t('action-bar.actions.success'),
      error: t('action-bar.actions.error')
    })
    console.log('[endpoint]', res)
  } catch (e) {
    console.error('[endpoint error]', e)
  }

  return res
}

const useActionHandler = (currentDomain: string, data?: any) => {
  const app = useApp()

  const params = useCallback(
    (action: Action, item?: FormObject) => ({
      action,
      item,
      data
    }),
    [data]
  )

  const actionHandler = useCallback(
    (item?: FormObject) => async (action: Action) => {
      if (action.type === ActionType.ENDPOINT) {
        const res = await handleEndpoint(action, currentDomain, app, item)

        if (res && action.code) {
          try {
            return Exec(action.code, {res, app, ...params(action, item)})
          } catch (e) {
            console.log('[action] code Error', e)
            return
          }
        }
      }

      if (action.code) {
        try {
          return Exec(action.code, {
            app: {...app, VM: window.app?.VM},
            ...params(action, item)
          })
        } catch (e) {
          console.error(e)
        }
      }
    },
    [app, currentDomain, params]
  )

  return actionHandler
}

export default useActionHandler
