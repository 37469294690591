import Exec from '@libs/exec'
import {FormDomain} from '@shared/interfaces'
import {useFormikContext} from 'formik'
import React, {useContext, useEffect, useState} from 'react'
import {FormObject} from '../form.t'
import * as Utils from '@libs/utils'
import {AppContext} from '@store/app'

interface FormLayoutHideHandlerProps {
  children: React.ReactNode
  form: FormDomain
  hidden: unknown
}

export const FormLayoutHideHandler: React.FC<FormLayoutHideHandlerProps> = ({
  form,
  hidden,
  children
}) => {
  const formProps = useFormikContext<FormObject>()
  const [isHidden, setIsHidden] = useState(true)
  const app = useContext(AppContext)

  const getValue = (value: string) => {
    return Utils.atPath(formProps?.values || {}, value)
  }

  useEffect(() => {
    if (typeof hidden === 'undefined') setIsHidden(false)
    if (typeof hidden === 'boolean') setIsHidden(hidden)
    if (typeof hidden === 'string') {
      if (hidden) {
        const exec = async () => {
          try {
            const context: Record<string, FormObject> = {}
            // if (window.SM) context[window.SM.mainDomain] = window.SM.sub[window.SM.mainDomain]

            const value = await Exec(hidden, {
              app,
              context,
              values: formProps.values,
              form: formProps,
              helper: {getValue}
            })
            if (typeof value === 'boolean') setIsHidden(value)
          } catch (e) {
            console.warn('Hide Handler Function error', form, e)
          }
        }

        exec()
      } else setIsHidden(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hidden, formProps.values, form])

  if (isHidden) return null

  return <React.Fragment>{children}</React.Fragment>
}
