import _ from 'lodash'
import {
  DataNodes,
  Edge,
  FlowNodes,
  LaneContext,
  Node,
  NodeContext,
  NodeData,
  NodeDataLane,
  NodeType
} from '../nodes/node-config'
import Workflow from '../workflow-builder'

const NodeManager = (
  {nodes = [], edges = []}: Workflow,
  onChange: (data: Workflow) => void,
  openNodePanel: (
    {type, data}: {type: NodeType; data: NodeData},
    onSave: (data: NodeData) => void
  ) => void,
  debug = false
) => {
  /// Helpers
  const getParentEdge = (node: Node) => edges.find((e) => e.target === node.id)
  const getChildEdges = (node: Node) => edges.filter((e) => e.source === node.id)
  const getNodeFromId = (id: string) => nodes.find((n) => n.id === id)

  /// Nodes Creation

  // Data Nodes
  const createDataNode = (sourceId: string, type: NodeType) => {
    const onSave = async (data: NodeData) => {
      createDefaultDataNode(sourceId, type, data as NodeData)
    }

    openNodePanel({type, data: {}}, onSave)
  }

  const createDefaultDataNode = (sourceId: string, type: NodeType, data: NodeData = {}) => {
    const maxId = Math.max(...nodes.map((n) => parseInt(n.id)))
    const targetId = `${maxId + 1}`
    const dropId = `${maxId + 2}`

    const targetNode = {
      id: targetId,
      data,
      position: {x: 0, y: 0},
      type
    }

    const dropNode = {
      id: dropId,
      data: {},
      position: {x: 0, y: 0},
      type: NodeType.drop
    }

    const connectingEdge = {
      id: `${sourceId}->${targetId}`,
      source: sourceId,
      target: targetId
    }
    const dropgEdge = {id: `${targetId}->${dropId}`, source: targetId, target: dropId}

    const newNodes = nodes.concat([targetNode, dropNode])
    const newEdges = edges.concat([connectingEdge, dropgEdge]).map((e) => {
      if (e.source === sourceId && e.target !== targetId) {
        e.source = dropId
        e.id = `${e.source}->${e.target}`
      }

      return e
    })

    onChange({nodes: newNodes, edges: newEdges})
  }

  // Flow Nodes

  const createFlowNode = (sourceId: string, type: NodeType) => {
    const onSave = async (data: NodeData) => {
      if (!data.label) return

      if (
        [NodeType.split, NodeType.multiRuleSplit].includes(type) &&
        data.lanes &&
        data.lanes.length
      ) {
        switch (type) {
          case NodeType.multiRuleSplit:
            return createMultiRuleSplit(sourceId, data)
          case NodeType.split:
            if (data.lanes.length > 1) return createSplit(sourceId, data)
        }
      } else if (type === NodeType.ruleSplit) createRuleSplit(sourceId, data)
    }

    openNodePanel({type, data: {}}, onSave)
  }

  const createRuleSplit = (sourceId: string, data: NodeData = {}) => {
    const maxId = Math.max(...nodes.map((n) => parseInt(n.id)))
    const targetId = `${maxId + 1}`

    const targetNode: Node = {
      id: targetId,
      data: data,
      position: {x: 0, y: 0},
      type: NodeType.ruleSplit
    }

    const connectingEdge = {id: `${sourceId}->${targetId}`, source: sourceId, target: targetId}

    let newEdges = edges.filter((e) => e.source !== sourceId)
    const targetEdge = edges.find((e) => e.source === sourceId)

    newEdges = newEdges.concat([connectingEdge])

    let newNodes = [...nodes].concat([targetNode])

    const target = targetEdge && targetEdge.target

    const dropId = `${maxId + 2}`

    const dropNode = {
      id: dropId,
      data: {},
      position: {x: 0, y: 0},
      type: NodeType.drop
    }

    newNodes = newNodes.concat([dropNode])

    if (target) {
      const dropEdge = {id: `${dropId}->${target}`, source: dropId, target: target}
      newEdges = newEdges.concat([dropEdge])
    }

    ;['true', 'false'].forEach((label) => {
      const res = insertEmptyPath({
        source: targetId,
        currentNodes: newNodes,
        currentEdges: newEdges,
        label: label,
        target: dropId
      })

      newEdges = res.edges
      newNodes = res.nodes
    })

    onChange({nodes: newNodes, edges: newEdges})
  }

  const createMultiRuleSplit = (sourceId: string, data: NodeData = {}) => {
    const maxId = Math.max(...nodes.map((n) => parseInt(n.id)))
    const targetId = `${maxId + 1}`

    const targetNode = {
      id: targetId,
      data: data,
      position: {x: 0, y: 0},
      type: NodeType.multiRuleSplit
    }

    const connectingEdge = {id: `${sourceId}->${targetId}`, source: sourceId, target: targetId}

    let newEdges = edges.filter((e) => e.source !== sourceId)
    const targetEdge = edges.find((e) => e.source === sourceId)

    newEdges = newEdges.concat([connectingEdge])

    let newNodes = [...nodes].concat([targetNode])

    const target = targetEdge && targetEdge.target

    const dropId = `${maxId + 2}`

    const dropNode = {
      id: dropId,
      data: {},
      position: {x: 0, y: 0},
      type: NodeType.drop
    }

    newNodes = newNodes.concat([dropNode])

    if (target) {
      const dropEdge = {id: `${dropId}->${target}`, source: dropId, target: target}
      newEdges = newEdges.concat([dropEdge])
    }

    const lanes = data.lanes || []

    lanes.forEach((lane) => {
      const res = insertEmptyPath({
        source: targetId,
        currentNodes: newNodes,
        currentEdges: newEdges,
        label: lane.label,
        target: dropId
      })

      lane.id = res.id

      newEdges = res.edges
      newNodes = res.nodes
    })

    if (data.else) {
      const res = insertEmptyPath({
        source: targetId,
        currentNodes: newNodes,
        currentEdges: newEdges,
        label: 'else',
        target: dropId
      })

      newEdges = res.edges
      newNodes = res.nodes
    }

    onChange({nodes: newNodes, edges: newEdges})
  }

  const createSplit = (sourceId: string, data: NodeData = {}) => {
    const maxId = Math.max(...nodes.map((n) => parseInt(n.id)))
    const targetId = `${maxId + 1}`

    const targetNode = {
      id: targetId,
      data: data,
      position: {x: 0, y: 0},
      type: NodeType.split
    }

    const connectingEdge = {id: `${sourceId}->${targetId}`, source: sourceId, target: targetId}

    let newEdges = edges.filter((e) => e.source !== sourceId)
    const targetEdge = edges.find((e) => e.source === sourceId)

    newEdges = newEdges.concat([connectingEdge])

    let newNodes = [...nodes].concat([targetNode])

    const target = targetEdge && targetEdge.target

    const dropId = `${maxId + 2}`

    const dropNode = {
      id: dropId,
      data: {},
      position: {x: 0, y: 0},
      type: NodeType.drop
    }
    newNodes = newNodes.concat([dropNode])

    if (target) {
      const dropEdge = {id: `${dropId}->${target}`, source: dropId, target: target}
      newEdges = newEdges.concat([dropEdge])
    }

    const lanes = data.lanes || []

    lanes.forEach((lane) => {
      const res = insertEmptyPath({
        source: targetId,
        currentNodes: newNodes,
        currentEdges: newEdges,
        label: lane.label,
        target: dropId
      })

      lane.id = res.id

      newEdges = res.edges
      newNodes = res.nodes
    })

    onChange({nodes: newNodes, edges: newEdges})
  }

  // Global

  const createConnection = (sourceId: string, type: NodeType) => {
    if (DataNodes.includes(type)) return createDataNode(sourceId, type)
    if (FlowNodes.includes(type)) return createFlowNode(sourceId, type)
  }

  const insertEmptyPath = ({
    source,
    currentNodes,
    currentEdges,
    label,
    target,
    animated = false
  }: {
    source: string
    currentNodes: Node[]
    currentEdges: Edge[]
    label?: string
    target?: string
    animated?: boolean
  }) => {
    const maxId = Math.max(...currentNodes.map((n) => parseInt(n.id)))
    const dropId = `${maxId + 1}`

    const dropNode = {
      id: dropId,
      data: {},
      position: {x: 0, y: 0},
      type: NodeType.drop
    }

    const dropgEdge = {
      id: `${source}->${dropId}`,
      source: source,
      target: dropId,
      animated,
      label: label
    }

    currentNodes = currentNodes.concat([dropNode])
    currentEdges = currentEdges.concat([dropgEdge])

    if (target) {
      currentEdges = currentEdges.concat([
        {
          id: `${dropId}->${target}`,
          source: dropId,
          animated,
          target: target
        }
      ])
    }

    return {id: dropgEdge.id, nodes: currentNodes, edges: currentEdges}
  }

  /// Generate Node Context

  // Data Nodes
  const getDataNodeContext = (node: Node, standalone = false): NodeContext => {
    const inputEdge = getParentEdge(node)
    const outputEdge = getChildEdges(node)[0]

    let outputNodes: Node[] = []
    let outputEdges: Edge[] = []
    let outputNode

    if (outputEdge) {
      outputNode = getNodeFromId(outputEdge.target)
      if (standalone && outputNode && inputEdge) {
        outputNodes = [outputNode]
        outputEdges = [outputEdge, inputEdge]
      } else if (outputNode) {
        const outputNodeCtx = getNodeContext(outputNode)
        outputNodes = [outputNode, ...outputNodeCtx.nodes]
        outputEdges = [outputEdge, ...outputNodeCtx.edges]
      }
    }

    if (debug)
      console.log('getDataNodeContext', {
        node,
        inputEdge,
        outputEdge,
        outputNode,
        outputNodes,
        outputEdges
      })

    return {
      node,
      inputEdge: inputEdge,
      nodes: outputNodes,
      edges: outputEdges,
      outputNode
    }
  }

  // Flow Nodes

  const getFlowNodeContext = (node: Node, standalone = false): NodeContext => {
    const inputEdge = getParentEdge(node)
    let outputEdges = [...getChildEdges(node)]

    let outputNodes: Node[] = []

    const lanes = outputEdges.map((edge) => {
      const nextNode = getNodeFromId(edge.target)
      const nextCtx = nextNode && getNodeContext(nextNode)
      return {
        id: edge.id,
        label: edge.label,
        nodes: [nextNode, ...(nextCtx?.nodes || [])],
        edges: [edge, ...(nextCtx?.edges || [])],
        drops: [nextNode, ...(nextCtx?.nodes || [])]
          .filter((n) => n && n.type === NodeType.drop)
          .map((n) => n?.id)
      } as LaneContext
    })

    const collisionDrops = _.intersection(...lanes.map((lane) => lane.drops))
    let outputNode

    if (standalone && collisionDrops && collisionDrops.length) {
      const collisionNodeId = collisionDrops[0]
      outputNode = getNodeFromId(collisionNodeId)
      const collisionCtx = outputNode && getNodeContext(outputNode)
      const laneNodes = collisionCtx?.nodes.map((n) => n.id)
      const laneEdges = collisionCtx?.edges.map((e) => e.id)

      lanes.forEach((l) => {
        outputNodes = outputNodes.concat(l.nodes.filter((n) => !laneNodes?.includes(n.id)))
        outputNodes = outputNodes.filter(
          (e, i) => outputNodes.findIndex((a) => a.id === e.id) === i
        )
        outputEdges = outputEdges.concat(l.edges.filter((e) => !laneEdges?.includes(e.id)))
      })
    } else {
      lanes.forEach((l) => {
        outputNodes = outputNodes.concat(l.nodes)
        outputEdges = outputEdges.concat(l.edges)
      })
    }

    if (standalone && inputEdge) outputEdges = outputEdges.concat([inputEdge])

    if (debug)
      console.log('getFlowNodeContext', {
        node,
        inputEdge,
        outputEdges,
        outputNodes,
        outputNode,
        lanes
      })

    return {
      node,
      inputEdge: inputEdge,
      nodes: outputNodes,
      edges: outputEdges,
      outputNode,
      lanes
    }
  }

  // Global

  const getNodeContext = (node: Node, standalone = false) => {
    if (FlowNodes.includes(node.type)) return getFlowNodeContext(node, standalone)
    else return getDataNodeContext(node, standalone)
  }

  /// Delete Node

  const deleteNode = (node: Node) => {
    const ctx = getNodeContext(node, true)

    const ctxNodeIds = ctx.nodes.map((n) => n.id)
    const ctxEdgeIds = ctx.edges.map((e) => e.id)

    const newNodes = nodes.filter(
      (n) =>
        !ctxNodeIds.includes(n.id) &&
        (!ctx.outputNode || n.id !== ctx.outputNode.id) &&
        n.id != node.id
    )
    let newEdges = edges.filter((e) => !ctxEdgeIds.includes(e.id))

    if (ctx.outputNode) {
      const targetEdge = getChildEdges(ctx.outputNode)[0]

      if (targetEdge)
        newEdges = newEdges.map((e) => {
          if (e.id != targetEdge.id || !ctx.inputEdge) return e
          return {
            ...e,
            source: ctx.inputEdge.source,
            id: `${ctx.inputEdge.source}->${e.target}`
          }
        })
    }

    if (debug)
      console.log('deleteNode', {
        node,
        ctx,
        ctxNodeIds,
        ctxEdgeIds,
        newNodes,
        newEdges
      })

    onChange({nodes: newNodes, edges: newEdges})
  }

  const deletePath = ({
    lane,
    currentNodes,
    currentEdges,
    outputNode
  }: {
    lane: LaneContext
    currentNodes: Node[]
    currentEdges: Edge[]
    outputNode?: Node
  }) => {
    const edgeLimit = lane.edges.findIndex((e) => e.target === outputNode?.id)
    const nodeLimit = lane.nodes.findIndex((n) => n.id === outputNode?.id)

    const removeEdges = lane.edges.slice(0, edgeLimit + 1)
    const removeNodes = lane.nodes.slice(0, nodeLimit)

    const newNodes = currentNodes.filter((n) => !removeNodes.find((n2) => n2.id === n.id))
    const newEdges = currentEdges.filter((e) => !removeEdges.find((e2) => e2.id === e.id))

    return {edges: newEdges, nodes: newNodes}
  }

  /// Paste Node
  const pasteNode = (sourceId: string, nodeContext: NodeContext) => {
    let maxId = Math.max(...nodes.map((n) => parseInt(n.id)))
    const nodeIdMapping: {[key: string]: string} = {}

    const copiedNodes = [nodeContext.node, ...nodeContext.nodes].map((n) => {
      maxId++
      nodeIdMapping[n.id] = `${maxId}`
      return {id: `${maxId}`, type: n.type, data: {...n.data}} as Node
    })

    const connectingEdge = {
      id: `${sourceId}->${nodeIdMapping[nodeContext.node.id]}`,
      source: sourceId,
      target: nodeIdMapping[nodeContext.node.id]
    }

    let copiedEdges = nodeContext.edges
      .filter((e) => e.target !== nodeContext.node.id)
      .filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id))
      .map((e) => {
        const target = nodeIdMapping[e.target]
        const source = nodeIdMapping[e.source]
        const id = `${source}->${target}`
        return {label: e.label, id, target, source, animated: false} as Edge
      })

    copiedEdges = copiedEdges.concat([connectingEdge])

    const outputEdge = edges.find((e) => e.source === sourceId)

    let newEdges = [...edges].filter((e) => e.source !== sourceId).concat(copiedEdges)

    if (outputEdge && nodeContext.outputNode?.id) {
      outputEdge.source = nodeIdMapping[nodeContext.outputNode.id]
      outputEdge.id = `${outputEdge.source}->${outputEdge.target}`
      newEdges = newEdges.concat([outputEdge])
    }

    const newNodes = [...nodes].concat(copiedNodes)

    if (debug)
      console.log('pasteNode', {
        newEdges,
        newNodes,
        copiedNodes,
        copiedEdges,
        nodes,
        edges,
        nodeContext,
        sourceId,
        nodeIdMapping,
        outputEdge
      })

    onChange({nodes: newNodes, edges: newEdges})
  }

  /// Update Node
  const updateNode = (node: Node, data: NodeData) => {
    const nodeIndex = nodes.indexOf(node)

    let newNodes = [...nodes]
    const newEdges = [...edges]
    if (nodeIndex === -1) throw 'Node not found.'

    newNodes = newNodes.map((n, index) => (index === nodeIndex ? {...n, data} : n))

    if (node.type === NodeType.multiRuleSplit) {
      return updateMultiRuleSplit(nodeIndex, newNodes, newEdges, node, data)
    } else if (node.type === NodeType.split) {
      return updateSplit(nodeIndex, newNodes, newEdges, node, data)
    }

    newNodes = newNodes.map((n, index) => (index === nodeIndex ? {...n, data} : n))

    onChange({nodes: newNodes, edges: newEdges})
  }

  const updateMultiRuleSplit = (
    nodeIndex: number,
    newNodes: Node[],
    newEdges: Edge[],
    node: Node,
    data: NodeData
  ) => {
    const nodeCtx = getNodeContext(node, true)
    const oldLanes = node.data.lanes
    const newLanes = data.lanes

    if (!newLanes || newLanes.length < 2)
      throw 'A Multi Rule Split node needs to have more than one lane.'
    const missingLanes: NodeDataLane[] = []
    const removeLanes: NodeDataLane[] = []
    const updateLanes: NodeDataLane[][] = []

    newLanes.forEach((lane) => {
      if (lane.id) {
        const oldLane = oldLanes?.find((l) => l.id === lane.id)
        if (!oldLane) missingLanes.push(lane)
        else if (lane.label != oldLane.label) updateLanes.push([oldLane, lane])
      } else {
        missingLanes.push(lane)
      }
    })

    if (!node.data.else && !!data.else) missingLanes.push({label: 'else'})

    nodeCtx.lanes?.forEach((lane) => {
      if (lane.label === 'else') {
        if (!data.else) {
          removeLanes.push(lane)
        }
      } else {
        const newLane = newLanes.find((l) => l.id === lane.id)
        if (!newLane) removeLanes.push(lane)
      }
    })

    missingLanes.forEach((lane) => {
      const res = insertEmptyPath({
        source: node.id,
        currentNodes: newNodes,
        currentEdges: newEdges,
        label: lane.label || '',
        target: nodeCtx.outputNode?.id,
        animated: true
      })

      lane.id = res.id

      newEdges = res.edges
      newNodes = res.nodes
    })

    removeLanes.forEach((lane) => {
      const res = deletePath({
        lane: lane as LaneContext,
        outputNode: nodeCtx.outputNode,
        currentNodes: newNodes,
        currentEdges: newEdges
      })
      newEdges = res.edges
      newNodes = res.nodes
    })

    updateLanes.forEach(([oldLane, newLane]) => {
      const laneCtx = nodeCtx.lanes?.find((l) => l.id === oldLane.id)
      const firstEdge = laneCtx?.edges[0]
      newEdges = newEdges.map((e) => (e.id === firstEdge?.id ? {...e, label: newLane.label} : e))
    })

    newNodes = newNodes.map((n, index) => (index === nodeIndex ? {...n, data} : n))

    onChange({nodes: newNodes, edges: newEdges})
  }

  const updateSplit = (
    nodeIndex: number,
    newNodes: Node[],
    newEdges: Edge[],
    node: Node,
    data: NodeData
  ) => {
    const nodeCtx = getNodeContext(node, true)
    const oldLanes = node.data.lanes
    const newLanes = data.lanes

    if (!newLanes || newLanes.length < 2) throw 'A Split node needs to have more than one lane.'
    const missingLanes: NodeDataLane[] = []
    const removeLanes: NodeDataLane[] = []
    const updateLanes: NodeDataLane[][] = []

    newLanes.forEach((lane) => {
      if (lane.id) {
        const oldLane = oldLanes?.find((l) => l.id === lane.id)
        if (!oldLane) missingLanes.push(lane)
        else if (lane.label != oldLane.label) updateLanes.push([oldLane, lane])
      } else {
        missingLanes.push(lane)
      }
    })

    nodeCtx.lanes?.forEach((lane) => {
      const newLane = newLanes.find((l) => l.id === lane.id)
      if (!newLane) removeLanes.push(lane)
    })

    missingLanes.forEach((lane) => {
      const res = insertEmptyPath({
        source: node.id,
        currentNodes: newNodes,
        currentEdges: newEdges,
        label: lane.label,
        target: nodeCtx.outputNode?.id,
        animated: true
      })

      lane.id = res.id

      newEdges = res.edges
      newNodes = res.nodes
    })

    removeLanes.forEach((lane) => {
      const res = deletePath({
        lane: lane as LaneContext,
        outputNode: nodeCtx.outputNode,
        currentNodes: newNodes,
        currentEdges: newEdges
      })
      newEdges = res.edges
      newNodes = res.nodes
    })

    updateLanes.forEach(([oldLane, newLane]) => {
      const laneCtx = nodeCtx.lanes?.find((l) => l.id === oldLane.id)
      const firstEdge = laneCtx?.edges[0]
      newEdges = newEdges.map((e) => (e.id === firstEdge?.id ? {...e, label: newLane.label} : e))
    })

    newNodes = newNodes.map((n, index) => (index === nodeIndex ? {...n, data} : n))

    if (debug)
      console.log('updateSplit', {
        nodeIndex,
        newNodes,
        newEdges,
        node,
        data,
        nodeCtx,
        oldLanes,
        newLanes,
        missingLanes,
        removeLanes,
        updateLanes
      })

    onChange({nodes: newNodes, edges: newEdges})
  }

  return {
    getNodeContext,
    getFlowNodeContext,
    getDataNodeContext,
    insertEmptyPath,
    createConnection,
    createSplit,
    createMultiRuleSplit,
    createRuleSplit,
    createFlowNode,
    createDefaultDataNode,
    createDataNode,
    getParentEdge,
    getChildEdges,
    getNodeFromId,
    deleteNode,
    updateNode,
    pasteNode
  }
}

export default NodeManager
