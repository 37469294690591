import Exec from '@libs/exec'
import {useGetQuery} from '@queries'
import {FormDomain} from '@shared/interfaces'
import {AppContext, withApp} from '@store/app'
import {Col, Form} from 'antd'
import {Formik, FormikHelpers, FormikProps, FormikValues} from 'formik'
import {RefObject, useCallback, useEffect, useMemo, useReducer, useState} from 'react'
import * as Yup from 'yup'
import {FieldWrapper} from './fields/fields.t'
import {FormObject} from './form.t'
import FormLayout from './layout/form-layout'
import {formInitalValues, initializeForm} from './utils'
import {formValidationReducer} from './validation'
interface FormProps extends AppContext {
  formId: string
  data?: FormObject
  innerRef?: RefObject<FormikProps<FormObject>>
  className?: string
  disabled?: boolean
  onSubmit?: (values: FormObject, formikHelpers: FormikHelpers<object>) => void | Promise<unknown>
  onDefaultValuesChange?: (values: FormObject) => void
  submitOnMount?: boolean
  onMount?: () => void
}

const FormComponent = ({
  formId,
  data = undefined,
  disabled = false,
  innerRef,
  onSubmit,
  ability,
  onDefaultValuesChange,
  submitOnMount,
  onMount
}: FormProps) => {
  const [form, setForm] = useState<FormDomain | undefined>()
  const [fields, setFields] = useState<FieldWrapper[]>()

  const [initialValues, setInitialValues] = useState<FormikValues>({})
  const [formikLoaded, setFormikLoaded] = useState<boolean>(!data || !Object.keys(data).length)

  const formsQuery = useGetQuery<FormDomain>('forms', {api: {hydrate: true, limit: 0}})

  const [validationState, dispatchValidation] = useReducer(formValidationReducer, {
    schema: Yup.object()
  })

  useEffect(() => {
    innerRef?.current?.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationState])

  useEffect(() => {
    if (!formsQuery.isSuccess || !formsQuery.data) return
    const loadedForm = formsQuery.data.find(({_id, key}) => _id === formId || key === formId)
    if (!loadedForm) return
    setForm(loadedForm)
    setFields(
      initializeForm(loadedForm, formsQuery.data, undefined, data, ability, undefined, disabled)
    )
  }, [ability, data, disabled, formId, formsQuery.data, formsQuery.isSuccess])

  useEffect(() => {
    if (!fields) return
    setInitialValues(formInitalValues(fields, data))
  }, [data, fields])

  const execFormMiddleware = useCallback(
    async (item: FormObject) => {
      if (!form?.outputMw) return item
      const value = await Exec(form.outputMw, {
        form: item
      })
      return value || item
    },
    [form?.outputMw]
  )

  useEffect(() => {
    if (!form || !formsQuery.data) return
    onDefaultValuesChange?.(
      formInitalValues(
        initializeForm(form, formsQuery.data, undefined, undefined, ability, undefined, disabled),
        {}
      )
    )
  }, [ability, disabled, form, formsQuery.data, onDefaultValuesChange])

  useEffect(() => {
    if (
      !submitOnMount ||
      !innerRef?.current?.submitForm ||
      Object.keys(innerRef?.current?.initialValues).length === 0
    )
      return
    innerRef.current.submitForm()
  }, [innerRef, innerRef?.current?.initialValues, innerRef?.current?.submitForm, submitOnMount])

  useEffect(() => {
    if (innerRef?.current?.submitForm && onMount) onMount()
  }, [innerRef, innerRef?.current?.submitForm, onMount])

  const formName = useMemo(() => (Math.random() + 1).toString(36).substring(7), [])

  if (!form || !fields || !fields.length) return null

  return (
    <Col span={24}>
      <Formik
        innerRef={innerRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnMount
        validateOnBlur
        validationSchema={validationState.schema}
        onReset={() => {
          setFormikLoaded(true)
        }}
        onSubmit={async (values, formik) => {
          formik.setSubmitting(true)
          const postMWItem = await execFormMiddleware(values)
          await onSubmit?.(postMWItem, formik)
          formik.setSubmitting(false)
        }}>
        {() => {
          if (!formikLoaded) return null
          return (
            <Form layout='vertical' className='mt-3' name={formName}>
              <FormLayout form={form} fields={fields} dispatchValidation={dispatchValidation} />
            </Form>
          )
        }}
      </Formik>
    </Col>
  )
}

FormComponent.displayName = 'FormComponent'

export default withApp(FormComponent)
