import React from 'react'
import {Select} from 'antd'
import {TFunction} from 'next-i18next'
import {Option, OptionValue} from '@shared/interfaces'
import {generateOptions} from './utils'
import _ from 'lodash'
import {CaretDownOutlined} from '@ant-design/icons'

interface OptionsSelectProps {
  options: Option[]
  t: TFunction
  optionsSelected: {[key: string]: string}
  setOptionsSelected?: React.Dispatch<{[key: string]: string}>
}

const OptionsSelect: React.FC<OptionsSelectProps> = ({
  options,
  optionsSelected,
  setOptionsSelected,
  t
}) => {
  return (
    <div className='w-100'>
      <span
        style={{
          fontStyle: 'normal',
          fontSize: '17px',
          fontWeight: '500',
          color: '#E6BFB1',
          width: '100%'
        }}>
        <label style={{float: 'left', fontFamily: 'NewKansas'}}>
          {t('catalog.sidebar.options')}
        </label>
        <a
          style={{
            float: 'right',
            color: '#ffff',
            fontSize: '12px',
            marginTop: '2px',
            textDecoration: 'underline'
          }}
          className='cursor-pointer gotham-font'
          onClick={() => {
            setOptionsSelected?.({})
          }}>
          {t('catalog.sidebar.resetOptions')}
        </a>
      </span>
      {options && (
        <div className='w-100'>
          {options.map(({_id, optionValues, name}) => (
            <Select
              allowClear
              suffixIcon={<CaretDownOutlined className='catalog-sidebar-dropdown-icon' />}
              className='catalog-sidebar-dropdown-select w-100 mb-2'
              options={generateOptions<OptionValue>(optionValues, 'value')}
              key={_id}
              placeholder={name}
              value={optionsSelected[_id]}
              onChange={(value) => {
                setOptionsSelected?.(
                  _.omitBy(
                    {
                      ...optionsSelected,
                      [_id]: value
                    },
                    _.isNil
                  )
                )
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default OptionsSelect
