import {url} from '@libs/cloudinary'
import React, {useState, useCallback, useMemo, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Controlled as ControlledZoom} from 'react-medium-image-zoom'
import {useEvent} from 'react-use'
import 'react-medium-image-zoom/dist/styles.css'

interface ImageGalleryProps {
  images: {
    url: string
    hash: string
    _id: string
  }[]
  className?: string
  selectedindex?: number
  optimize?: boolean
  zoomMargin?: number
}

interface KeydownEvent extends Event {
  key?: 'ArrowLeft' | 'ArrowRight'
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  selectedindex = 0,
  optimize = true,
  className = '',
  zoomMargin = 0
}) => {
  const [imgPic, setImgPic] = useState(selectedindex)
  const [isZoomed, setIsZoomed] = useState(false)
  const [galleryControllerContainer, setGalleryControllerContainer] = useState<
    false | Element | DocumentFragment
  >(false)

  const nextImage = useCallback(() => {
    setImgPic(Math.min(imgPic + 1, images.length - 1))
  }, [imgPic, images.length])

  const prevImage = useCallback(() => {
    setImgPic(Math.max(imgPic - 1, 0))
  }, [imgPic])

  const handleKeyDown = useCallback(
    (e: KeydownEvent) => {
      if (!isZoomed) {
        return
      }

      if (e.key === 'ArrowLeft') {
        prevImage()
      } else if (e.key === 'ArrowRight') {
        nextImage()
      }
    },
    [isZoomed, prevImage, nextImage]
  )

  useMemo(() => {
    if (isZoomed) {
      setTimeout(() => {
        if (isZoomed) {
          const elems = document.querySelectorAll('[data-rmiz-overlay="true"]')
          if (elems.length) {
            setGalleryControllerContainer(elems[0])
          }
        }
      }, 100)
    } else {
      setGalleryControllerContainer(false)
    }
  }, [isZoomed])

  useEvent('keydown', handleKeyDown, document)

  useEffect(() => {
    setImgPic(selectedindex)
  }, [selectedindex, images])

  return (
    <div id='gallery' className={`${className} d-flex gallery text-center`}>
      {isZoomed &&
        galleryControllerContainer &&
        createPortal(
          <div>
            <div
              className={`gallery-controller gallery-next-${
                isZoomed && imgPic + 1 < images.length ? 'zoomed' : 'none'
              } cursor-pointer`}
              onClick={nextImage}>
              <i className='far fa-2x fa-arrow-alt-circle-right text-lighter' />
            </div>
            <div
              className={`gallery-controller gallery-prev-${
                isZoomed && imgPic - 1 >= 0 ? 'zoomed' : 'none'
              } cursor-pointer`}
              onClick={prevImage}>
              <i className='far fa-2x fa-arrow-alt-circle-left text-lighter' />
            </div>
          </div>,
          galleryControllerContainer
        )}

      <div className='gallery-zoom'>
        <ControlledZoom
          zoomZindex={5000}
          isZoomed={isZoomed}
          onZoomChange={setIsZoomed}
          zoomMargin={zoomMargin}
          overlayBgColorStart='rgba(0, 0, 0, 0.1)'
          overlayBgColorEnd='rgba(0, 0, 0, 0.9)'>
          <img
            src={url(
              images[imgPic]?.hash,
              optimize
                ? {
                    filter: 'f_auto,c_fit,h_2000,q_auto:good,w_2000,fl_lossy'
                  }
                : {}
            )}
            className={`img-fluid z-depth-1 gallery-main cursor-pointer`}
          />
        </ControlledZoom>
      </div>
      {images.length > 1 && (
        <div className='d-flex gallery-thumbnails px-lg-4'>
          {images.map((img, i) => (
            <img
              key={i}
              onClick={() => setImgPic(i)}
              className={`cursor-pointer img-fluid gallery-thumbnail ${
                imgPic == i ? 'selected' : ''
              }`}
              src={url(
                img.hash,
                optimize
                  ? {
                      filter: 'f_auto,c_fit,h_300,q_auto:good,w_300,fl_lossy'
                    }
                  : {}
              )}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default ImageGallery
