import {faBars, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Col, Row, Card} from 'antd'
import React from 'react'
import {DraggableAttributes} from '@dnd-kit/core'
import {SyntheticListenerMap} from '@dnd-kit/core/dist/hooks/utilities'
interface DragCardProps {
  style?: React.CSSProperties
  attributes?: DraggableAttributes
  listeners?: SyntheticListenerMap | undefined
  setNodeRef?: (node: HTMLElement | null) => void
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  handle?: boolean
  children: React.ReactNode
  className?: string
  disabled?: boolean
  remove?: boolean
  onRemove?: () => void
}

const DragCard: React.FC<DragCardProps> = ({
  attributes,
  style,
  listeners,
  setNodeRef,
  setActivatorNodeRef,
  handle,
  children,
  className,
  disabled,
  remove,
  onRemove
}) => {
  return (
    <Card
      size='small'
      ref={setNodeRef}
      style={style}
      className={className}
      {...(!handle && !disabled && listeners)}
      {...attributes}>
      <Row wrap={false} gutter={[16, 16]}>
        {!disabled && !!handle && (
          <Col flex='none'>
            <div ref={setActivatorNodeRef} {...listeners} className='cursor-grab'>
              <FontAwesomeIcon icon={faBars} />
            </div>
            {!!remove && (
              <div className='cursor-pointer'>
                <FontAwesomeIcon icon={faTrashAlt} color={'red'} onClick={onRemove} />
              </div>
            )}
          </Col>
        )}
        <Col flex='auto'>{children}</Col>
      </Row>
    </Card>
  )
}

export default DragCard
