import {Checkbox, Col, Row, Typography} from 'antd'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {CheckedState, Constants, LinkLevel, Progress, ProgressStats} from './tm.t'
import {CaretDownFilled, CaretRightFilled} from '@ant-design/icons'
import {Task} from '@shared/interfaces'
import TaskManagementTaskItem from './tm-task-item'
import TaskManagementHelper from './tm-helper'
import {invalidateGetChecklistQuery, useUpdateTasksStatusMutation} from '@queries/tasks'
import {useApp} from '@store/app'
import {useTasksListContext} from './tasks-list'
import {useQueryClient} from '@tanstack/react-query'

const {Text} = Typography

interface TaskManagementSubSectionItemProps extends Omit<LinkLevel, '_id'> {
  linkId: string
  setProgressStats: React.Dispatch<React.SetStateAction<ProgressStats>>
  uid: string
}

const TaskManagementSubSectionItem: React.FC<TaskManagementSubSectionItemProps> = ({
  linkId,
  name,
  setProgressStats,
  uid,
  tasks
}) => {
  const {globalChecked, setGlobalChecked} = useTasksListContext()
  const {t, toast, ability, stateManager} = useApp()
  const updateTasksStatusMutation = useUpdateTasksStatusMutation()
  const queryClient = useQueryClient()

  const [shown, setShown] = useState<boolean>(true)
  const [checked, setChecked] = useState<CheckedState>(
    globalChecked?.[uid] || TaskManagementHelper.resetCheckedState(tasks)
  )
  const CollapseIcon = useMemo(() => (shown ? CaretDownFilled : CaretRightFilled), [shown])
  const projectId = stateManager?.sub?.project && stateManager?.sub?.project._id

  const onToggleCheckbox = useCallback(
    (value: boolean, taskIds: string[]) => {
      updateTasksStatusMutation.mutateAsync(
        {
          tasks: taskIds,
          status: 'done',
          value,
          tabId: Constants.TAB_ID
        },
        {
          onSuccess() {
            invalidateGetChecklistQuery(queryClient, projectId)
          },
          onError() {
            toast.error('Unexpected error')
            setChecked(TaskManagementHelper.toggleSubTasks(tasks, !value))
          }
        }
      )
    },
    [projectId, queryClient, tasks, toast, updateTasksStatusMutation]
  )

  const onToggleLinkCheckbox = useCallback(
    (value: boolean, taskIds: string[]) => {
      setChecked(TaskManagementHelper.toggleSubTasks(tasks, value))
      onToggleCheckbox(value, taskIds)
    },
    [tasks, onToggleCheckbox]
  )

  const onToggleTaskCheckbox = useCallback(
    (value: boolean, taskId: string) => {
      setChecked((state) => TaskManagementHelper.onToggleTaskCheckboxSuccess(state, taskId, value))
      onToggleCheckbox(value, [taskId])
    },
    [onToggleCheckbox]
  )

  const updateProgressStats = useCallback(
    (progress: Progress) =>
      setProgressStats((state) => ({
        ...state,
        [linkId]: progress
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [linkId]
  )

  const linkNameStyle = useMemo(() => (checked.link ? 'tm-task-done bold' : 'bold'), [checked.link])

  useEffect(() => {
    setGlobalChecked?.((state) => ({
      ...state,
      [uid]: checked
    }))

    const {link: linkChecked, ...rest} = checked
    const tasksChecked = Object.values(rest)
    updateProgressStats({
      total: tasksChecked.length,
      done: linkChecked ? tasksChecked.length : tasksChecked.filter(Boolean).length
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, setGlobalChecked])

  useEffect(() => {
    setChecked(TaskManagementHelper.resetCheckedState(tasks))
  }, [tasks])

  const disabled = useMemo(() => {
    return ability?.cannot('crud.update', 'task')
  }, [ability])

  return (
    <div className='my-1'>
      <Row className='my-2'>
        <Col span={1} className='tm-collapse-icon'>
          <CollapseIcon
            className='cursor-pointer'
            onClick={() => {
              setShown(!shown)
            }}
          />
        </Col>
        <Col span={1} className='tm-link-checkbox'>
          <Checkbox
            disabled={disabled}
            onChange={(e) => {
              onToggleLinkCheckbox(
                e.target.checked,
                tasks.map(({_id}) => _id)
              )
            }}
            checked={checked.link}
          />
        </Col>
        <Col span={20}>
          <Text className={linkNameStyle}>{name || 'Global'}</Text>
        </Col>
      </Row>
      {shown &&
        tasks.map((task: Task) => (
          <TaskManagementTaskItem
            task={TaskManagementHelper.normalizeTask(task, t)}
            rawTask={task}
            done={checked[task._id]}
            onToggleTaskCheckbox={onToggleTaskCheckbox}
            key={task._id}
          />
        ))}
    </div>
  )
}

export default TaskManagementSubSectionItem
