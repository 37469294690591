import {CloseOutlined} from '@ant-design/icons'
import CustomTable from '@components/custom-table'
import LookupTableCell from '@components/lookup-component/lookup-table-cell'
import {PanelsKeys} from '@libs/panels/panels.t'
import {LookupItem} from '@shared/erp-api'
import {AppContext, withApp} from '@store/app'
import {Button, Col, Divider, Empty, Row, Space, TableColumnsType, Typography} from 'antd'
import {GetRowKey} from 'antd/es/table/interface'
import React, {useMemo} from 'react'
import {ResultViewType} from './productFinder'
import {ServiceDomain} from '@components/forms/fields/widgets/cart-widget/cart.t'

export interface ResultViewProps extends AppContext {
  resultType: ResultViewType
  headerTitle: string
  onSendError: () => void
  openService: () => void
  result: LookupItem[]
  onItemClicked?: (record: LookupItem | undefined) => void
  rowKey?: string | GetRowKey<LookupItem>
  onClose: (key: PanelsKeys) => void
  service?: ServiceDomain
}

export const PanelHeader: React.FC<
  React.PropsWithChildren<{
    title: string | null
    panelKey: PanelsKeys
    onClose: (key: PanelsKeys) => void
  }>
> = ({title, panelKey, children, onClose}) => (
  <div>
    <Row justify='space-between'>
      <Col>
        <Typography.Title level={4}>{title}</Typography.Title>
      </Col>
      <Col>
        <Row>
          <Space>
            {children && children}
            <Button icon={<CloseOutlined />} onClick={() => onClose(panelKey)} />
          </Space>
        </Row>
      </Col>
    </Row>
    <Divider style={{marginTop: 5, marginBottom: 5}} />
  </div>
)

const ResultView: React.FC<ResultViewProps> = ({
  t,
  onSendError,
  openService,
  result,
  onItemClicked,
  headerTitle,
  onClose,
  resultType,
  service,
  rowKey = '_id'
}: ResultViewProps) => {
  const columns: TableColumnsType<LookupItem> = useMemo(
    () => [
      {
        dataIndex: 'displayValues',
        render: (_: string[], record: LookupItem) => {
          return (
            <LookupTableCell lookupItem={record} compact={false} key={record._id} id={undefined} />
          )
        }
      }
    ],
    []
  )

  const errorView = useMemo(() => {
    if (result.length === 0) {
      const hasDummyPlace = () => {
        if (!service) return false
        const {arrival, stop, departure, location} = service
        return (
          arrival?.place?.dummy ||
          stop?.place?.dummy ||
          departure?.place?.dummy ||
          location?.place?.dummy
        )
      }

      if (resultType === ResultViewType.SUPPLIER_ARTICLE && hasDummyPlace())
        return (
          <Empty style={{padding: '40px'}} description={t('panel.productFinder.dummyPlaceError')}>
            <Button type='primary' onClick={openService}>
              {t('panel.productFinder.dummyPlaceButton')}
            </Button>
          </Empty>
        )

      return (
        <Empty style={{padding: '40px'}} description={t('panel.productFinder.empty')}>
          <Button type='primary' onClick={onSendError}>
            {t('panel.productFinder.report')}
          </Button>
        </Empty>
      )
    }

    return null
  }, [])

  return (
    <div style={{height: '100%'}}>
      <PanelHeader title={t(headerTitle)} panelKey={PanelsKeys.RESULT_VIEW} onClose={onClose} />
      {errorView}
      {!errorView && (
        <CustomTable
          rowKey={rowKey}
          compact={false}
          data={result}
          columns={columns}
          pageSize={result.length}
          total={result.length}
          isLoading={false}
          page={1}
          id={undefined}
          onItemClick={onItemClicked}
          showHeader={false}
        />
      )}
    </div>
  )
}

export default withApp(ResultView)
