import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {IconName, IconPrefix} from '@fortawesome/fontawesome-svg-core'

interface IconDisplayProps {
  type: IconPrefix
  iconName: IconName
  onClick: (iconName: IconName) => void
}

const IconDisplay: React.FC<IconDisplayProps> = ({type, iconName, onClick}) => (
  <FontAwesomeIcon
    size='2x'
    icon={[type, iconName]}
    onClick={() => onClick(iconName)}
    className='m-1 cursor-pointer'
  />
)

export default IconDisplay
