import React from 'react'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useFormikContext} from 'formik'
import {FormObject} from '@components/forms/form.t'
import {FieldDomain} from '@shared/interfaces'
import CodeEditor from '@components/misc/code-editor'

const DomainJson: React.FC<FieldComponentProps<Omit<FieldDomain, 'hidden' | 'ref'>>> = () => {
  const formProps = useFormikContext<FormObject>()

  const data = {...formProps.values}

  return (
    <CodeEditor
      name={'merge'}
      language={'json'}
      parsedValue={JSON.stringify(data, null, 2)}
      disabled
      onChange={() => {
        return
      }}
    />
  )
}

const DomainJsonWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return <DomainJson field={field} />
    }
  }
}

export default DomainJsonWidget
