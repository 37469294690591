import {FieldWrapper} from '@components/forms/fields/fields.t'
import React, {useEffect, useState} from 'react'
import * as Utils from '@libs/utils'
import {FormikContextType, useFormikContext} from 'formik'
import {FormObject} from '@components/forms/form.t'
import Exec from '@libs/exec'
import {FormDomain} from '@shared/interfaces'

/**
 * Get field value
 *
 * @param {string} value
 * @param {string} name
 * @param {FormikContextType<FormObject>} formProps
 * @returns
 */
const getValue = (value: string, name: string, formProps: FormikContextType<FormObject>) => {
  return Utils.atPath(
    formProps?.values || {},
    `${Utils.relativePath(name) ? `${Utils.relativePath(name)}.` : ''}${value}`
  )
}

/**
 * Is Field Hidden
 *
 * @param {FieldWrapper} field
 * @param {React.Dispatch<boolean>} setIsHidden
 * @param {FormDomain} form
 * @param {FormikContextType<FormObject>} formProps
 *
 * @returns {void}
 */
const isFieldHidden = (
  field: FieldWrapper,
  setIsHidden: React.Dispatch<boolean> | ((isHidden: boolean) => void),
  form: FormDomain,
  formProps: FormikContextType<FormObject>
): void => {
  const {hidden, name} = field.domain
  if (typeof hidden === 'undefined') setIsHidden(false)
  if (typeof hidden === 'boolean') setIsHidden(hidden)
  if (typeof hidden === 'string') {
    if (hidden) {
      const exec = async () => {
        try {
          const context: Record<string, FormObject> = {}
          // if (window.SM) context[window.SM.mainDomain] = window.SM.sub[window.SM.mainDomain]

          const value = await Exec(hidden, {
            // app,
            name,
            context,
            values: formProps.values,
            field: {name, value: Utils.atPath(formProps.values, name)},
            form: formProps,
            helper: {getValue: (value: string) => getValue(value, name, formProps)}
          })
          if (typeof value === 'boolean') setIsHidden(value)
        } catch (e) {
          console.warn('Hide Handler Function error', form, name, e)
        }
      }
      exec()
    } else setIsHidden(false)
  }
}

/**
 * Use Field Hidden
 *
 * @param {FieldWrapper} field
 * @param {FormDomain} form
 *
 * @returns {boolean}
 */
export const useFieldHidden = (field: FieldWrapper, form: FormDomain): boolean => {
  const formProps = useFormikContext<FormObject>()
  const [isHidden, setIsHidden] = useState(true)
  const {hidden, name} = field.domain

  useEffect(
    () => {
      isFieldHidden(field, setIsHidden, form, formProps)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formProps.values, hidden, name]
  )

  return isHidden
}

/**
 * Use Fields Hidden
 *
 * @param {FieldWrapper[]} fields
 * @param {FormDomain} form
 *
 * @returns {object}
 */
export const useFieldsHidden = (
  fields: FieldWrapper[],
  form: FormDomain
): {[key: string]: boolean} => {
  const [fieldsHidden, setFieldsHidden] = useState<{[key: string]: boolean}>({})
  const formProps = useFormikContext<FormObject>()

  useEffect(
    () => {
      fields.forEach((field) => {
        const setIsHidden = (isHidden: boolean) => {
          setFieldsHidden((state) => ({...state, [field.domain._id]: isHidden}))
        }
        isFieldHidden(field, setIsHidden, form, formProps)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formProps.values, fields]
  )

  return fieldsHidden
}
