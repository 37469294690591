import {ErpDomain} from '@shared/interfaces'
import urlJoin from 'url-join'

export interface ReplaceUrl {
  domain: string
  domainId?: string
  subDomain?: string
  subDomainId?: string

  title?: string
  state?: Record<string, unknown>
}

export const replaceUrl = ({
  domain,
  domainId,
  subDomain,
  subDomainId,
  title = '',
  state = {}
}: ReplaceUrl) => {
  if (!window.CRM) return

  const urlParts: string[] = ['domain']

  const url = new URL(window.location.href)
  const newUrl = new URL(`${url.protocol}//${url.host}/`)

  const {keyPlural: mainDomainPlural} = window.CRM.getDomain(domain) as ErpDomain
  urlParts.push(mainDomainPlural)

  if (domainId) {
    urlParts.push(domainId)
  }

  if (subDomain) {
    const {keyPlural: subDomainPlural} = (window.CRM.getDomain(subDomain) as ErpDomain) || {
      keyPlural: `${domain}s`
    }
    if (mainDomainPlural !== subDomainPlural) {
      urlParts.push(subDomainPlural)

      if (subDomainId) {
        urlParts.push(subDomainId)
      }
    }
  }

  newUrl.pathname = urlJoin(...urlParts)

  window.history.pushState(state, title, newUrl.toString())
}

export const decodeUrl = () => {
  if (!window.CRM) return

  const url = new URL(window.location.href)
  const {pathname} = url

  const [, ...urlParts] = pathname.split('/').filter(Boolean)

  const domain = urlParts[0]
  const domainId = urlParts[1]
  const subDomain = urlParts[2]
  const subDomainId = urlParts[3]

  const mainDomain = window.CRM.getDomain(domain) as ErpDomain
  const subDomainDomain = window.CRM.getDomain(subDomain) as ErpDomain

  return {
    domain: mainDomain,
    domainId,
    subDomain: subDomainDomain,
    subDomainId
  }
}
