import React, {useMemo, useState} from 'react'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import {useField} from 'formik'
import * as yup from 'yup'
import moment, {MomentInput} from 'moment-timezone'
import {atPath} from '@libs/utils'
import DatePicker from './DatePicker'
const {RangePicker} = DatePicker
import TimeDatePicker from './TimeDatePicker'
import _ from 'lodash'
import {DateTimeInputType} from '@shared/interfaces'
import {IntRange} from 'rc-picker/lib/interface'
import FormItem from '../form-item/form-item'

export interface DateInputComponentProps {
  _id: string
  key: string
  label: string
  name: string
  type: DateTimeInputType | string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  defaultValue?: string
  hourStep?: IntRange<1, 23> | undefined
  minuteStep?: IntRange<1, 59> | undefined
}

const formatDate = 'DD/MM/YYYY'
const formatDateTime = 'DD/MM/YYYY HH:mm'

const DateInputComponent: React.FC<FieldComponentProps<DateInputComponentProps>> = ({field}) => {
  const [formikField, __, helpers] = useField<Date | Date[] | null | null[]>({...field})

  const [wasCleared, setWasCleared] = useState(false)

  /**
   * defaultValue is HH:mm. This function will return moment current day with formated defaultValue
   * @returns Date | Date[] | null[] | null
   */
  const currentDateWithDefaultValue = (): Date | Date[] | null[] | null => {
    const defaultTime = moment(field.defaultValue, 'HH:mm')

    if (!defaultTime.isValid()) {
      console.warn('date-input error on defaultTime. Not valid', field.defaultValue)
      return null
    }

    const now = moment()
    now.set({
      hour: defaultTime.hour(),
      minute: defaultTime.minute()
    })

    return now.toDate()
  }

  const handleOnChange = (
    value: moment.Moment | null | [moment.Moment | null, moment.Moment | null]
  ) => {
    // suggest defaultValue on first clear
    if (!value && !wasCleared) {
      if (field.defaultValue) {
        const dateWithDefaultValue = currentDateWithDefaultValue()
        helpers.setValue(dateWithDefaultValue)
        setWasCleared(true)
        return
      }
    }
    if (value && Array.isArray(value)) {
      const formattedValue = value
        .map((v) => (v ? v.toDate() : undefined))
        .filter((v) => v !== undefined) as Date[]
      helpers.setValue(formattedValue)
    } else if (value) {
      helpers.setValue(value.toDate())
    } else {
      // Allow to clear the field
      if (
        field.type === DateTimeInputType['date-range'] ||
        field.type === DateTimeInputType['datetime-range']
      ) {
        helpers.setValue(null)
      } else {
        helpers.setValue(null)
      }
    }
  }

  const handleOnFocus = () => {
    // suggest defaultValue on focus, if there is no value or it was not cleared
    if (formikField.value || wasCleared) return
    const dateWithDefaultValue = currentDateWithDefaultValue()
    helpers.setValue(dateWithDefaultValue)
    setWasCleared(true)
    return
  }

  const formikFieldWithoutOnBlur = useMemo(() => {
    // NOTE(Mouadh HSOUMI): It's a workaround to avoid the field to be marked as touched when the user clicks
    // any button on the calendar, using the formikField.onBlur() method will trigger an unwanted behavior.

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {onBlur, ...formikFieldRest} = formikField
    return formikFieldRest
  }, [formikField])

  return (
    <FormItem field={field} style={{flex: 1}} className='w-100 mb-2'>
      {field.type === DateTimeInputType.date && (
        <DatePicker
          {...formikFieldWithoutOnBlur}
          value={formikField.value ? moment(formikField.value as MomentInput) : null} // retrocompatibility: v1 used ISO 8601 format
          onChange={handleOnChange}
          format={formatDate}
          className='w-100'
          disabled={field.disabled}
        />
      )}

      {[DateTimeInputType['date-range'], DateTimeInputType['datetime-range']].includes(
        field.type as DateTimeInputType
      ) &&
        (Array.isArray(formikField.value) || !formikField.value) && (
          // RangePicker takes a start and a end date
          <RangePicker
            {...formikFieldWithoutOnBlur}
            value={[
              formikField.value?.[0] ? moment(formikField.value[0]) : null,
              formikField.value?.[1] ? moment(formikField.value[1]) : null
            ]}
            disabled={field.disabled}
            onChange={handleOnChange}
            format={
              field.type === DateTimeInputType['datetime-range'] ? formatDateTime : formatDate
            }
            showTime={field.type === DateTimeInputType['datetime-range']}
            className='w-100'
          />
        )}

      {field.type === DateTimeInputType.datetime && (
        <TimeDatePicker
          {...formikFieldWithoutOnBlur}
          value={formikField.value ? moment(formikField.value as MomentInput) : null}
          format={formatDateTime}
          onChange={handleOnChange}
          disabled={field.disabled}
          hourStep={field.hourStep ?? 1}
          minuteStep={field.minuteStep ?? 15}
          onFocus={handleOnFocus}
        />
      )}

      {/* Hidden span to trigger formik rerender (otherwise the displayed value is not updated when removed) */}
      <span hidden>{formikField.value?.toString()}</span>
    </FormItem>
  )
}

const DateInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)

      if (data && value !== undefined && value !== null) {
        if (
          Array.isArray(value) &&
          (value[0] === null || value[0] === undefined) &&
          (value[1] === null || value[1] === undefined)
        ) {
          // must be 'null' and not [null, null] for the project filter output middleware
          return null
        }
        return value
      }
      if (
        field.type === DateTimeInputType['date-range'] ||
        field.type === DateTimeInputType['datetime-range']
      ) {
        return null
      }
      return null
    },
    validationSchema() {
      let schema

      // handle of date & datetime format
      const customDateSchema = yup.date().nullable()

      if (field.type === DateTimeInputType.date || field.type === DateTimeInputType.datetime) {
        schema = customDateSchema
      } else if (
        field.type === DateTimeInputType['date-range'] ||
        field.type === DateTimeInputType['datetime-range']
      ) {
        schema = yup.array().of(customDateSchema).length(2).nullable()
      } else {
        schema = customDateSchema
      }

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <DateInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default DateInput
