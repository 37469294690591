import {FormDomain} from '@shared/interfaces'
import React, {Dispatch} from 'react'
import {FieldWrapper} from '../fields/fields.t'
import {ValidationAction} from '../form.t'
import FormRowLayout from './form-row'
import {Col, Space} from 'antd'

interface FormLayoutProps {
  form: FormDomain
  fields: FieldWrapper[]
  dispatchValidation: Dispatch<ValidationAction>
}

const FormLayout: React.FC<FormLayoutProps> = ({form, fields, dispatchValidation}) => {
  if (!form.layout || !form.layout.rows || form.layout.rows.length == 0) {
    console.warn(`No layout defined for form`, form)
    return null
  }

  const {layout} = form

  return (
    <Col>
      <Space direction='vertical' className='w-100'>
        {layout.rows.map((row) => (
          <FormRowLayout
            key={row.id}
            form={form}
            row={row}
            fields={fields}
            dispatchValidation={dispatchValidation}
          />
        ))}
      </Space>
    </Col>
  )
}

export default FormLayout
