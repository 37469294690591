import {useErpDomainsQuery} from '@queries'
import {ErpDomain} from '@shared/interfaces'
import {useMemo} from 'react'

/**
 * Use domain react hook
 * Returns the domain object by domain key
 *
 * @function useDomainByKey - react hook
 *
 * @param {string | undefined} domainKey
 * @returns {ErpDomain | undefined}
 */
export const useDomainByKey = (domainKey?: string) => {
  // Fetch domains list
  const erpDomains = useErpDomainsQuery()

  // Lookup domain by key
  const domain: ErpDomain | undefined = useMemo<ErpDomain | undefined>(() => {
    if (erpDomains.isSuccess) {
      return erpDomains.data?.find((domain) => domain.key === domainKey)
    }
  }, [domainKey, erpDomains.data, erpDomains.isSuccess])

  return domain
}

/**
 * Use domain react hook
 * Returns the domain object by domain _id
 *
 * @function useDomainById - react hook
 *
 * @param {string | undefined} _id - domainId
 * @returns {ErpDomain | undefined}
 */
export const useDomainById = (_id?: string) => {
  // Fetch domains list
  const erpDomains = useErpDomainsQuery()

  // Lookup domain by key or _id
  const domain: ErpDomain | undefined = useMemo<ErpDomain | undefined>(() => {
    if (erpDomains.isSuccess) {
      return erpDomains.data?.find((domain) => domain._id === _id)
    }
  }, [_id, erpDomains.data, erpDomains.isSuccess])

  return domain
}

/**
 * Use domain react hook
 * Returns the domain object by domain key or _id
 *
 * @function useDomain - react hook
 *
 * @param {string | undefined} domainKey - (key or _id)
 * @returns {ErpDomain | undefined}
 */
export const useDomain = (domainKey?: string) => {
  // Fetch domains list
  const erpDomains = useErpDomainsQuery()

  // Lookup domain by key or _id
  const domain: ErpDomain | undefined = useMemo<ErpDomain | undefined>(() => {
    if (erpDomains.isSuccess) {
      return erpDomains.data?.find((domain) => domain.key === domainKey || domain._id === domainKey)
    }
  }, [domainKey, erpDomains.data, erpDomains.isSuccess])

  return domain
}
