import {loader} from '@monaco-editor/react'
import codeEditorTypes from 'src/utils/codeEditorTypes'

const initMonaco = async () => {
  // Error code to ignore
  const ignoreCodes: Array<number> = [1108, 2304]

  const monaco = await loader.init()

  monaco.languages.typescript.typescriptDefaults.addExtraLib(codeEditorTypes)
  monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
    diagnosticCodesToIgnore: ignoreCodes
  })

  monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
    target: monaco.languages.typescript.ScriptTarget.ESNext,
    module: monaco.languages.typescript.ModuleKind.ESNext
  })
}

export default initMonaco
