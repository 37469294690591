import {useFormikContext} from 'formik'
import {FieldComponentFactory, FieldComponentProps} from '../../fields.t'
import _ from 'lodash'
import {Col} from 'antd'
import {CartDomain} from './cart.t'
import CartHeader from './cart-header'
import CartContent from './cart-content'

interface CartWidgetComponentProps {
  name: string
  label: string
  disabled?: boolean
}

const CartWidgetComponent: React.FC<FieldComponentProps<CartWidgetComponentProps>> = () => {
  const formProps = useFormikContext<CartDomain>()

  const cart = {...formProps.values}

  return (
    <Col>
      <CartHeader cart={cart} />
      <CartContent cart={cart} />
    </Col>
  )
}

const CartWidget: FieldComponentFactory = (field) => {
  return {
    generateComponent() {
      return <CartWidgetComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default CartWidget
