import {APIService} from '@services/api'
import {AuthResponse} from '@shared/erp-api'
import {createContext, ReactNode, useContext} from 'react'

export class AuthService {
  constructor(private api: APIService, private baseUrl: string) {
    this.baseUrl = baseUrl.replace(/\/+$/, '')
  }

  public async login(email: string, password: string) {
    const {data} = await this.api.post<AuthResponse>(`${this.baseUrl}/auth/login`, {
      email,
      password
    })

    this.api.setToken(data.data.jwt)

    return data
  }

  public async logAs(email: string) {
    const body = {email}

    const {data} = await this.api.post<AuthResponse>(`${this.baseUrl}/auth/switch`, {
      ...body
    })

    this.api.setToken(data.data.jwt)

    return data
  }

  public async logout() {
    this.api.setToken(null)
  }
}

const AuthContext = createContext<AuthService | undefined>(undefined)

export const AuthProvider = (props: {authService: AuthService; children?: ReactNode}) => {
  return <AuthContext.Provider value={props.authService}>{props.children}</AuthContext.Provider>
}

export const useAuthService = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new Error('useAuthService must be used within a AuthProvider')
  }

  return context
}
